/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHeadCell
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHeadCell.tsx)
 */

// React & Next
import { useMemo } from 'react';

// 3rd
import { Box, Td, useColorMode } from '@chakra-ui/react';
import type { TableCellProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Header, MRT_RowData, MRT_TableInstance } from '../../types';

// App - Other
import { theme } from '@/config/theme';
import { parseFromValuesOrFunc } from '../../column.utils';
import { getCommonTableCellStyles, getTableTheme } from '../../style.utils';
import { TableHeadCellColumnActionsButton } from './actions/table-head-cell-column-actions-button';
import { TableHeadCellFilterContainer } from './filter/table-head-cell-filter-container';
import { TableHeadCellFilterLabel } from './filter/table-head-cell-filter-label';
import { TableHeadCellGrabHandle } from './dnd/table-head-cell-grab-handle';
import { TableHeadCellResizeHandle } from './resize/table-head-cell-resize-handle';
import { TableHeadCellSortLabel } from './sort/table-head-cell-sort-label';

interface Props<TData extends MRT_RowData> extends TableCellProps {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableHeadCell = <TData extends MRT_RowData>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getState,
    options: {
      columnFilterDisplayMode,
      columnResizeMode,
      enableColumnActions,
      enableColumnDragging,
      enableColumnOrdering,
      enableGrouping,
      enableMultiSort,
      layoutMode,
      tableHeadCellProps,
    },
    refs: { tableHeadCellRefs },
    setHoveredColumn,
  } = table;
  // const { columnSizingInfo, draggingColumn, grouping, hoveredColumn, showColumnFilters } =
  const { showColumnFilters, columnSizingInfo, draggingColumn, grouping, hoveredColumn } =
    getState();
  const { column } = header;
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const tableCellProps = {
    ...parseFromValuesOrFunc(tableHeadCellProps, { column, table }),
    ...parseFromValuesOrFunc(columnDef.tableHeadCellProps, {
      column,
      table,
    }),
    ...rest,
  };

  const { draggingBorderColor } = getTableTheme(table, colorModeContext);

  const showColumnActions =
    (enableColumnActions || columnDef.enableColumnActions) &&
    columnDef.enableColumnActions !== false;

  const showDragHandle =
    enableColumnDragging !== false &&
    columnDef.enableColumnDragging !== false &&
    (enableColumnDragging ||
      (enableColumnOrdering && columnDef.enableColumnOrdering !== false) ||
      (enableGrouping && columnDef.enableGrouping !== false && !grouping.includes(column.id)));

  const headerPL = useMemo(() => {
    let pl = 0;

    if (column.getCanSort()) pl += 1;
    if (showColumnActions) pl += 1.75;
    if (showDragHandle) pl += 1.5;

    return pl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showColumnActions, showDragHandle]);

  const draggingBorders = useMemo(() => {
    const showResizeBorder =
      columnSizingInfo.isResizingColumn === column.id &&
      columnResizeMode === 'onChange' &&
      !header.subHeaders.length;

    const borderStyle = showResizeBorder
      ? `2px solid ${draggingBorderColor} !important`
      : draggingColumn?.id === column.id
        ? `1px dashed ${theme.colors.gray[500]}`
        : hoveredColumn?.id === column.id
          ? `2px dashed ${draggingBorderColor}`
          : undefined;

    if (showResizeBorder) {
      return { borderRight: borderStyle };
    }
    const draggingBorders = borderStyle
      ? {
          borderLeft: borderStyle,
          borderRight: borderStyle,
          borderTop: borderStyle,
        }
      : undefined;

    return draggingBorders;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggingColumn, hoveredColumn, columnSizingInfo.isResizingColumn]);

  const handleDragEnter = () => {
    if (enableGrouping && hoveredColumn?.id === 'drop-zone') {
      setHoveredColumn(null);
    }
    if (enableColumnOrdering && draggingColumn && columnDefType !== 'group') {
      setHoveredColumn(columnDef.enableColumnOrdering !== false ? column : null);
    }
  };

  const headerElement =
    parseFromValuesOrFunc(columnDef.Header, {
      column,
      header,
      table,
    }) ?? columnDef.header;

  return (
    <Td
      align={columnDefType === 'group' ? 'center' : 'left'}
      colSpan={header.colSpan}
      onDragEnter={handleDragEnter}
      ref={(node: HTMLTableCellElement) => {
        if (node) {
          tableHeadCellRefs.current[column.id] = node;
        }
      }}
      {...tableCellProps}
      sx={{
        flexDirection: layoutMode?.startsWith('grid') ? 'column' : undefined,
        fontSize: '12px',
        fontWeight: '400',
        color: 'text.primary',
        overflow: 'visible', // Changed to auto from 'visible' as it caused the table to overflow --- visible needed to show menus
        p: columnDefType === 'display' ? '6px 16px' : '2px 16px',
        // pb: columnDefType === 'display' ? 0 : showColumnFilters ? '6.4px' : '9.6px',
        // pt: columnDefType === 'group' ? '4px' : '12px',
        userSelect: enableMultiSort && column.getCanSort() ? 'none' : undefined,
        // verticalAlign: 'top', // Changed to middle as it caused weird rendering when selection is enabled
        verticalAlign: showColumnFilters ? 'top' : 'middle',
        '&:first-of-type': {
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
        },
        '&:last-of-type': {
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        },
        zIndex:
          column.getIsResizing() || draggingColumn?.id === column.id
            ? 3
            : column.getIsPinned() && columnDefType !== 'group'
              ? 2
              : 1,
        ...getCommonTableCellStyles({
          column,
          header,
          table,
          tableCellProps,
          colorModeContext,
        }),
        ...draggingBorders,
      }}
    >
      {header.isPlaceholder
        ? null
        : tableCellProps.children ?? (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: tableCellProps?.align === 'right' ? 'row-reverse' : 'row',
                justifyContent:
                  columnDefType === 'group' || tableCellProps?.align === 'center'
                    ? 'center'
                    : column.getCanResize()
                      ? 'space-between'
                      : 'flex-start',
                position: 'relative',
                width: '100%',
              }}
            >
              <Box
                onClick={column.getToggleSortingHandler()}
                sx={{
                  alignItems: 'center',
                  cursor: column.getCanSort() && columnDefType !== 'group' ? 'pointer' : undefined,
                  display: 'flex',
                  gap: '4px', // Added for padding between col name and buttons
                  flexDirection: tableCellProps?.align === 'right' ? 'row-reverse' : 'row',
                  overflow: columnDefType === 'data' ? 'hidden' : undefined,
                  pl: tableCellProps?.align === 'center' ? `${headerPL}rem` : undefined,
                }}
              >
                <Box
                  sx={{
                    '&:hover': {
                      textOverflow: 'clip',
                    },
                    minWidth: `${Math.min(columnDef.header?.length ?? 0, 2)}ch`,
                    overflow: columnDefType === 'data' ? 'hidden' : undefined,
                    textOverflow: 'ellipsis',
                    whiteSpace: (columnDef.header?.length ?? 0) < 20 ? 'nowrap' : 'normal',
                    // mr: '6px',
                  }}
                  title={columnDefType === 'data' ? columnDef.header : undefined}
                >
                  {headerElement}
                </Box>

                {column.getCanFilter() && (
                  <TableHeadCellFilterLabel header={header} table={table} />
                )}

                {column.getCanSort() && <TableHeadCellSortLabel header={header} table={table} />}
              </Box>

              {columnDefType !== 'group' && (
                <Box sx={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
                  {showDragHandle && (
                    <TableHeadCellGrabHandle
                      column={column}
                      table={table}
                      tableHeadCellRef={{
                        current: tableHeadCellRefs.current[column.id],
                      }}
                    />
                  )}

                  {showColumnActions && (
                    <TableHeadCellColumnActionsButton header={header} table={table} />
                  )}
                </Box>
              )}

              {column.getCanResize() && <TableHeadCellResizeHandle header={header} table={table} />}
            </Box>
          )}

      {columnFilterDisplayMode === 'subheader' && column.getCanFilter() && (
        <TableHeadCellFilterContainer header={header} table={table} />
      )}
    </Td>
  );
};
