/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ExpandAllButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_ExpandAllButton.tsx#L10)
 */

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { IconButton } from '@/components/molecules/button';
import { parseFromValuesOrFunc } from '../column.utils';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  table: MRT_TableInstance<TData>;
}

export const ExpandAllButton = <TData extends MRT_RowData>({
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    getCanSomeRowsExpand,
    getIsAllRowsExpanded,
    getIsSomeRowsExpanded,
    getState,
    options: {
      icons: { KeyboardDoubleArrowDownIcon },
      localization,
      expandAllButtonProps,
      renderDetailPanel,
    },
    toggleAllRowsExpanded,
  } = table;
  const { isLoading } = getState();

  const _iconButtonProps = {
    ...parseFromValuesOrFunc(expandAllButtonProps, {
      table,
    }),
    ...iconButtonProps,
  };

  const isAllRowsExpanded = getIsAllRowsExpanded();

  return (
    <IconButton
      isDisabled={isLoading || (!renderDetailPanel && !getCanSomeRowsExpand())}
      aria-label={localization.expandAll}
      {..._iconButtonProps}
      variant="circular"
      size="sm"
      icon={
        <KeyboardDoubleArrowDownIcon
          transform={`rotate(${isAllRowsExpanded ? -180 : getIsSomeRowsExpanded() ? -90 : 0}deg)`}
          transition="transform 150ms"
          aria-label={localization.expandAll}
          // size="sm"
        />
      }
      onClick={() => toggleAllRowsExpanded(!isAllRowsExpanded)}
    />
  );
};
