/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHeadCellColumnActionsButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHeadCellColumnActionsButton.tsx#L17)
 */

// 3rd
import { Menu, MenuButton } from '@chakra-ui/react';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_Header, MRT_RowData, MRT_TableInstance } from '../../../types';

// App - Other
import { IconButton } from '@/components/molecules/button';
import { ColumnActionMenu } from '../../../menus/column-action-menu';
import { parseFromValuesOrFunc } from '../../../column.utils';

interface Props<TData extends MRT_RowData> extends Omit<Partial<IconButtonProps>, 'aria-label'> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableHeadCellColumnActionsButton = <TData extends MRT_RowData>({
  header,
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    options: {
      icons: { MoreVertIcon },
      localization,
      columnActionsButtonProps,
    },
  } = table;
  const { column } = header;
  const { columnDef } = column;

  const _iconButtonProps = {
    ...parseFromValuesOrFunc(columnActionsButtonProps, {
      column,
      table,
    }),
    ...parseFromValuesOrFunc(columnDef.columnActionsButtonProps, {
      column,
      table,
    }),
    ...iconButtonProps,
  };

  return (
    <Menu preventOverflow={true} computePositionOnMount={true}>
      <MenuButton
        as={IconButton}
        position="relative"
        boxSizing="border-box"
        backgroundColor="transparent"
        outline="0px"
        border="0px"
        margin="0px"
        px="0px"
        py="0px"
        borderRadius="0px"
        userSelect="none"
        verticalAlign="middle"
        appearance="none"
        textDecoration="none"
        color="inherit"
        cursor="pointer"
        display="inline-flex"
        justifyContent="flex-start"
        flexDirection="inherit"
        alignItems="center"
        minWidth="auto"
        flex="0 0 0%"
        opacity={0.3}
        {..._iconButtonProps}
        aria-label={localization.columnActions}
        _hover={{
          '& svg': {
            opacity: 0.6,
          },
        }}
        _active={{
          '& svg': {
            opacity: 1,
          },
        }}
        sx={{
          '& svg': {
            opacity: 1,
            color: 'rgb(0, 0, 0)',
          },
          transition: 'all 150ms ease-in-out',
        }}
        size="sm"
        icon={
          <MoreVertIcon
            style={{
              width: '14px',
              height: '14px',
            }}
            aria-label={localization.columnActions}
          />
        }
      />

      <ColumnActionMenu header={header} table={table} />
    </Menu>
  );
};
