export const sizes = {
  none: '0',
  0.5: '2px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '20px',
  6: '24px',
  7: '28px',
  8: '32px',
  9: '36px',
  10: '40px',
  11: '48px',
};

export const sizeTokens = {
  none: sizes.none,
  xxs: sizes[0.5],
  xs: sizes[1],
  sm: sizes[2],
  md: sizes[3],
  lg: sizes[4],
  xl: sizes[5],
  '2xl': sizes[6],
  '3xl': sizes[8],
  '4xl': sizes[9],
  '5xl': sizes[10],
  '6xl': sizes[11],
};
