/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_RowPinButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_RowPinButton.tsx#L18)
 */

// React & Next
import type { MouseEvent } from 'react';

// 3rd
import type { RowPinningPosition } from '@tanstack/react-table';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_Row, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { IconButton } from '@/components/molecules/button';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  pinningPosition: RowPinningPosition;
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}

export const RowPinButton = <TData extends MRT_RowData>({
  pinningPosition,
  row,
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    options: {
      icons: { CloseIcon, PushPinIcon },
      localization,
      rowPinningDisplayMode,
    },
  } = table;

  const isPinned = row.getIsPinned();

  const handleTogglePin = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    row.pin(isPinned ? false : pinningPosition);
  };

  return (
    <IconButton
      onClick={handleTogglePin}
      variant="old.outline"
      size="sm"
      {...iconButtonProps}
      aria-label={localization.pin}
      icon={
        isPinned ? (
          <CloseIcon size="3xs" aria-label={localization.pin} />
        ) : (
          <PushPinIcon
            aria-label={localization.pin}
            style={{
              transform: `rotate(${
                rowPinningDisplayMode === 'sticky' ? 90 : pinningPosition === 'top' ? 180 : 0
              }deg)`,
            }}
          />
        )
      }
      sx={{
        ...(iconButtonProps?.sx || {}),
      }}
    />
  );
};
