/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TablePagination
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/toolbar/MRT_TablePagination.tsx#L25)
 */

// 3rd
import { Flex, Select } from '@chakra-ui/react';
import type { SelectProps } from '@chakra-ui/react';

// App - Types
import type { MRT_RowData, MRT_TableInstance } from '../../types';
import type { PaginationProps } from './page-pagination';

// App - Other
import { Text } from '@/components/atoms/typography';
import { ButtonGroup, IconButton } from '@/components/molecules/button';
import { parseFromValuesOrFunc } from '../../column.utils';
import { PagePagination } from './page-pagination';

const DEFAULT_ROWS_PER_PAGE = [5, 10, 15, 20, 25, 30, 50, 100];

interface Props<TData extends MRT_RowData>
  extends Partial<
    PaginationProps &
      PaginationProps & {
        SelectProps?: Partial<SelectProps>;
        rowsPerPageOptions?: { label: string; value: number }[] | number[];
        showRowsPerPage?: boolean;
      }
  > {
  position?: 'bottom' | 'top';
  table: MRT_TableInstance<TData>;
}

export const TablePagination = <TData extends MRT_RowData>({
  position = 'bottom',
  table,
  ...paginationProps
}: Props<TData>) => {
  const {
    getPrePaginationRowModel,
    getState,
    options: {
      enableToolbarInternalActions,
      icons: { ChevronLeftIcon, ChevronRightIcon, FirstPageIcon, LastPageIcon },
      localization,
      paginationProps: paginationPropsFromTable,
      paginationDisplayMode,
      rowCount,
    },
    setPageIndex,
    setPageSize,
  } = table;
  const {
    pagination: { pageIndex = 0, pageSize = 10 },
    showGlobalFilter,
  } = getState();

  const _paginationProps = {
    ...parseFromValuesOrFunc(paginationPropsFromTable, {
      table,
    }),
    ...paginationProps,
  };

  const totalRowCount = rowCount ?? getPrePaginationRowModel().rows.length;
  const numberOfPages = Math.ceil(totalRowCount / pageSize);
  const showFirstLastPageButtons = numberOfPages > 2;
  const firstRowIndex = pageIndex * pageSize;
  const lastRowIndex = Math.min(pageIndex * pageSize + pageSize, totalRowCount);

  const {
    SelectProps,
    rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE,
    showFirstButton = showFirstLastPageButtons,
    showLastButton = showFirstLastPageButtons,
    showRowsPerPage = true,
    ..._restPaginationProps
  } = _paginationProps ?? {};

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '8px',
        justifyContent: { md: 'space-between', sm: 'center' },
        justifySelf: 'flex-end',
        mt:
          position === 'top' && enableToolbarInternalActions && !showGlobalFilter
            ? '48px'
            : undefined,
        position: 'relative',
        px: '8px',
        py: '12px',
        zIndex: 2,
      }}
    >
      {showRowsPerPage && (
        <Flex sx={{ alignItems: 'center', gap: '8px' }}>
          <Text variant="detail" sx={{ mb: 0, whiteSpace: 'nowrap' }}>
            {localization.rowsPerPage}
          </Text>

          <Select
            aria-label={localization.rowsPerPage}
            // rootProps={{ 'aria-label': localization.rowsPerPage, defaultValue: pageSize }}
            onChange={(event) => setPageSize(+event.target.value)}
            value={pageSize}
            size="sm"
            sx={{
              mb: 0,
              borderColor: '#D0D7DE',
              _hover: { borderColor: '#D0D7DE' },
              _active: { borderColor: '#D0D7DE' },
              _focus: { borderColor: '#D0D7DE' },
            }}
            // {...SelectProps}
          >
            {rowsPerPageOptions.map((option) => {
              const value = typeof option !== 'number' ? option.value : option;
              const label = typeof option !== 'number' ? option.label : `${option}`;

              return (
                SelectProps?.children ?? (
                  <option key={value} value={value}>
                    {label}
                  </option>
                )
              );
            })}
          </Select>
        </Flex>
      )}

      {paginationDisplayMode === 'pages' ? (
        <PagePagination
          totalCount={totalRowCount}
          onPaginate={(newPageIndex) => setPageIndex(newPageIndex - 1)}
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
          {..._restPaginationProps}
        />
      ) : paginationDisplayMode === 'default' ? (
        <ButtonGroup spacing={1} size="xs" variant="ghost">
          {showFirstButton && (
            <IconButton
              aria-label={localization.goToFirstPage}
              isDisabled={pageIndex <= 0}
              icon={<FirstPageIcon size="sm" aria-label={localization.goToFirstPage} />}
              onClick={() => setPageIndex(0)}
              variant="old.ghost"
              size="sm"
            />
          )}

          <IconButton
            aria-label={localization.goToPreviousPage}
            isDisabled={pageIndex <= 0}
            onClick={() => setPageIndex(pageIndex - 1)}
            icon={<ChevronLeftIcon size="sm" aria-label={localization.goToPreviousPage} />}
            variant="old.ghost"
            size="sm"
          />

          <Text variant="detail" textAlign="center" sx={{ m: 'auto 2px', minWidth: '8ch' }}>
            {`${
              lastRowIndex === 0 ? 0 : (firstRowIndex + 1).toLocaleString()
            }-${lastRowIndex.toLocaleString()} ${
              localization.of
            } ${totalRowCount.toLocaleString()}`}
          </Text>

          <IconButton
            aria-label={localization.goToNextPage}
            isDisabled={lastRowIndex >= totalRowCount}
            onClick={() => setPageIndex(pageIndex + 1)}
            icon={<ChevronRightIcon size="sm" aria-label={localization.goToNextPage} />}
            variant="old.ghost"
            size="sm"
          />

          {showLastButton && (
            <IconButton
              aria-label={localization.goToLastPage}
              isDisabled={lastRowIndex >= totalRowCount}
              onClick={() => setPageIndex(numberOfPages - 1)}
              icon={<LastPageIcon size="sm" aria-label={localization.goToLastPage} />}
              variant="old.ghost"
              size="sm"
            />
          )}
        </ButtonGroup>
      ) : null}
    </Flex>
  );
};
