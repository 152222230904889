// 3rd
import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { detail, label, paragraph } from '@/config/theme/components/text';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// #############
// CHECKBOX BASE
// #############

const baseStyle = definePartsStyle({
  control: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: 'xs',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'border.primary',
    // color: 'surface.primary',

    _checked: {
      bg: 'surface.brand.primary',
      borderColor: 'border.brand.primary',

      _hover: {
        bg: 'surface.brand.primary',
        borderColor: 'border.brand.primary',
      },

      _disabled: {
        color: 'surface.invert-low-tone',
        bg: 'surface.high-tone',
        borderColor: 'transparent',

        _hover: {
          bg: 'surface.high-tone',
        },
      },
    },

    _indeterminate: {
      bg: 'surface.brand.primary',
      borderColor: 'border.brand.primary',

      _hover: {
        bg: 'surface.brand.primary',
        borderColor: 'border.brand.primary',
      },

      _disabled: {
        color: 'surface.invert-low-tone',
        bg: 'surface.high-tone',
        borderColor: 'transparent',

        _hover: {
          bg: 'surface.high-tone',
        },
      },
    },

    _invalid: {},

    _disabled: {
      color: 'surface.invert-low-tone',
      bg: 'surface.high-tone',
      borderColor: 'transparent',

      _hover: {
        bg: 'surface.high-tone',
      },
    },
  },

  icon: {
    flexShrink: 0,
  },

  container: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  label: {
    color: 'text.secondary',
  },
});

// #####
// SIZES
// #####

const smSize = definePartsStyle({
  control: {
    w: 'md',
    h: 'md',
  },

  icon: {
    w: 'md',
    h: 'md',
  },

  container: {
    gap: 'xs',
  },

  label: {
    ...detail,
    color: 'text.secondary',
  },
});

const mdSize = definePartsStyle({
  control: {
    w: 'lg',
    h: 'lg',
  },

  icon: {
    w: 'lg',
    h: 'lg',
  },

  container: {
    gap: 'sm',
  },

  label: {
    ...label,
    color: 'text.secondary',
  },
});

const lgSize = definePartsStyle({
  control: {
    w: 'xl',
    h: 'xl',
  },

  icon: {
    w: 'xl',
    h: 'xl',
  },

  container: {
    gap: 'md',
  },

  label: {
    ...paragraph,
    color: 'text.secondary',
  },
});

// ########
// VARIANTS
// ########

const oldVariant = definePartsStyle({
  control: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: 'xs',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.secondary',
    color: 'surface.primary',

    _checked: {
      bg: '#2683FE',
      borderColor: '#2683FE',

      _hover: {
        bg: '#2683FE',
        borderColor: '#2683FE',
      },

      _disabled: {
        color: 'surface.invert-low-tone',
        bg: 'surface.high-tone',
        borderColor: 'transparent',

        _hover: {
          bg: 'surface.high-tone',
        },
      },
    },

    _indeterminate: {
      bg: '#2683FE',
      borderColor: '#2683FE',

      _hover: {
        bg: '#2683FE',
        borderColor: '#2683FE',
      },

      _disabled: {
        color: 'surface.invert-low-tone',
        bg: 'surface.high-tone',
        borderColor: 'transparent',

        _hover: {
          bg: 'surface.high-tone',
        },
      },
    },

    _invalid: {},

    _disabled: {
      color: 'surface.invert-low-tone',
      bg: 'surface.high-tone',
      borderColor: 'transparent',

      _hover: {
        bg: 'surface.high-tone',
      },
    },
  },

  icon: {
    flexShrink: 0,
  },

  container: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  label: {
    fontWeight: 400,
    color: '#57606A',
  },
});

const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { sm: smSize, md: mdSize, lg: lgSize },
  variants: { old: oldVariant },
});

export const Checkbox = checkboxTheme;
