/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: useMRT_Effects
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/hooks/useMRT_Effects.ts#L9)
 */

// React & Next
import { useEffect, useReducer, useRef } from 'react';

// App
import { getCanRankRows } from '../column.utils';
import { type MRT_RowData, type SortingState, type MRT_TableInstance } from '../types';

export const useTableEffects = <TData extends MRT_RowData>(table: MRT_TableInstance<TData>) => {
  const {
    getIsSomeRowsPinned,
    getState,
    options: { enablePagination, enableRowPinning, rowCount },
  } = table;
  const { globalFilter, isLoading, pagination, showSkeletons, sorting } = getState();

  const rerender = useReducer(() => ({}), {})[1];
  const isMounted = useRef(false);
  const initialBodyHeight = useRef<string>();
  const previousTop = useRef<number>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      initialBodyHeight.current = document.body.style.height;
    }
  }, []);

  useEffect(() => {
    if (isMounted && typeof window !== 'undefined') {
      document.body.style.height = initialBodyHeight.current as string;

      if (!previousTop.current) return;

      // restore scroll position
      window.scrollTo({
        behavior: 'instant',
        top: -1 * (previousTop.current as number),
      });
    }

    isMounted.current = true;
  }, []);

  // if page index is out of bounds, set it to the last page
  useEffect(() => {
    if (!enablePagination || isLoading || showSkeletons) return;

    const { pageIndex, pageSize } = pagination;
    const totalRowCount = rowCount ?? table.getPrePaginationRowModel().rows.length;
    const firstVisibleRowIndex = pageIndex * pageSize;

    if (firstVisibleRowIndex > totalRowCount) {
      table.setPageIndex(Math.floor(totalRowCount / pageSize));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowCount, table.getPrePaginationRowModel().rows.length]);

  // turn off sort when global filter is looking for ranked results
  const appliedSort = useRef<SortingState>(sorting);

  useEffect(() => {
    if (sorting.length) {
      appliedSort.current = sorting;
    }
  }, [sorting]);

  useEffect(() => {
    if (!getCanRankRows(table)) return;

    if (globalFilter) {
      table.setSorting([]);
    } else {
      table.setSorting(() => appliedSort.current || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    if (enableRowPinning && getIsSomeRowsPinned()) {
      setTimeout(() => {
        rerender();
      }, 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
