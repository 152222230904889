/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableFooter
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/footer/MRT_TableFooter.tsx#L14)
 */

// 3rd
import { type VirtualItem } from '@tanstack/react-virtual';
import { Tfoot, type TableFooterProps, useColorMode } from '@chakra-ui/react';

// App
import { theme } from '@/config/theme';
import { TableFooterRow } from './table-footer-row';
import { parseFromValuesOrFunc } from '../column.utils';
import { MRT_HeaderGroup, type MRT_RowData, type MRT_TableInstance } from '../types';

interface Props<TData extends MRT_RowData> extends TableFooterProps {
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}

export const TableFooter = <TData extends MRT_RowData>({
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...tableFooterProps
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getFooterGroups,
    options: { enableStickyFooter, layoutMode, tableFooterProps: tableTableFooterProps },
    refs: { tableFooterRef },
  } = table;

  const _tableFooterProps = {
    ...parseFromValuesOrFunc(tableTableFooterProps, {
      table,
    }),
    ...tableFooterProps,
  };

  const stickFooter = enableStickyFooter !== false;

  return (
    <Tfoot
      {..._tableFooterProps}
      ref={(ref: HTMLTableSectionElement) => {
        tableFooterRef.current = ref;

        // if (_tableFooterProps?.ref) {
        //   // @ts-ignore
        //   _tableFooterProps.ref.current = ref;
        // }
      }}
      sx={{
        bottom: stickFooter ? 0 : undefined,
        display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
        opacity: stickFooter ? 0.97 : undefined,
        outline: stickFooter
          ? colorModeContext.colorMode === 'light'
            ? `1px solid ${theme.colors.gray[300]}`
            : `1px solid ${theme.colors.gray[700]}`
          : undefined,
        position: stickFooter ? 'sticky' : undefined,
        zIndex: stickFooter ? 1 : undefined,
        ...(_tableFooterProps?.sx || {}),
      }}
    >
      {getFooterGroups().map((footerGroup) => (
        <TableFooterRow
          footerGroup={footerGroup as MRT_HeaderGroup<TData>}
          key={footerGroup.id}
          table={table}
          virtualColumns={virtualColumns}
          virtualPaddingLeft={virtualPaddingLeft}
          virtualPaddingRight={virtualPaddingRight}
        />
      ))}
    </Tfoot>
  );
};
