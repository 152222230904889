/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHeadCellResizeHandle
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHeadCellResizeHandle.tsx#L15)
 */

// 3rd
import { Box, Divider, type DividerProps } from '@chakra-ui/react';

// App
import { theme } from '@/config/theme';
import { type MRT_Header, type MRT_RowData, type MRT_TableInstance } from '../../../types';

interface Props<TData extends MRT_RowData> extends DividerProps {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableHeadCellResizeHandle = <TData extends MRT_RowData>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: { columnResizeMode },
    setColumnSizingInfo,
  } = table;
  const { column } = header;

  return (
    <Box
      className="Mui-TableHeadCell-ResizeHandle-Wrapper"
      onDoubleClick={() => {
        setColumnSizingInfo((old) => ({
          ...old,
          isResizingColumn: false,
        }));
        column.resetSize();
      }}
      onMouseDown={header.getResizeHandler()}
      onTouchStart={header.getResizeHandler()}
      style={{
        transform:
          column.getIsResizing() && columnResizeMode === 'onEnd'
            ? `translateX(${getState().columnSizingInfo.deltaOffset ?? 0}px)`
            : undefined,
      }}
      sx={{
        '&:active > hr': {
          backgroundColor: theme.colors.primary[500],
          opacity: header.subHeaders.length || columnResizeMode === 'onEnd' ? 1 : 0,
        },
        cursor: 'col-resize',
        mr: '-20px',
        position: 'absolute',
        px: '4px',
        right: column.columnDef.columnDefType === 'display' ? '4px' : '0',
      }}
    >
      <Divider
        className="Mui-TableHeadCell-ResizeHandle-Divider"
        // flexItem
        orientation="vertical"
        sx={{
          borderRadius: '2px',
          borderWidth: '2px',
          height: '24px',
          touchAction: 'none',
          transition: column.getIsResizing() ? undefined : 'all 150ms ease-in-out',
          userSelect: 'none',
          zIndex: 4,
          ...(rest?.sx || {}),
        }}
      />
    </Box>
  );
};
