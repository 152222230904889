/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHead
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHead.tsx#L15)
 */

// 3rd
import { type VirtualItem } from '@tanstack/react-virtual';
import { Thead, Tr, Th, type TableHeadProps } from '@chakra-ui/react';

// App
import { TableHeadRow } from './head-row/table-head-row';
import { parseFromValuesOrFunc } from '../column.utils';
import { ToolbarAlertBanner } from '../toolbar';
import { type MRT_RowData, type MRT_TableInstance } from '../types';

interface Props<TData extends MRT_RowData> extends TableHeadProps {
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}

export const TableHead = <TData extends MRT_RowData>({
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...tableHeadProps
}: Props<TData>) => {
  const {
    getHeaderGroups,
    getSelectedRowModel,
    getState,
    options: {
      enableStickyHeader,
      layoutMode,
      tableHeadProps: tableTableHeadProps,
      positionToolbarAlertBanner,
    },
    refs: { tableHeadRef },
  } = table;
  const { showAlertBanner } = getState();

  const _tableHeadProps = {
    ...parseFromValuesOrFunc(tableTableHeadProps, { table }),
    ...tableHeadProps,
  };

  const stickyHeader = enableStickyHeader;

  return (
    <Thead
      {..._tableHeadProps}
      ref={(ref: HTMLTableSectionElement) => {
        tableHeadRef.current = ref;
      }}
      sx={{
        display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
        opacity: 0.97,
        position: stickyHeader ? 'sticky' : 'relative',
        // top: stickyHeader && layoutMode?.startsWith('grid') ? 0 : undefined,
        top: stickyHeader ? 0 : undefined,
        zIndex: 3,
        ...(_tableHeadProps?.sx || {}),
      }}
    >
      {positionToolbarAlertBanner === 'head-overlay' &&
      (showAlertBanner || getSelectedRowModel().rows.length > 0) ? (
        <Tr
          style={{
            display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
          }}
        >
          <Th
            colSpan={table.getVisibleLeafColumns().length}
            style={{
              display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
              padding: 0,
            }}
          >
            <ToolbarAlertBanner table={table} />
          </Th>
        </Tr>
      ) : (
        getHeaderGroups().map((headerGroup) => (
          <TableHeadRow
            headerGroup={headerGroup}
            key={headerGroup.id}
            table={table}
            virtualColumns={virtualColumns}
            virtualPaddingLeft={virtualPaddingLeft}
            virtualPaddingRight={virtualPaddingRight}
          />
        ))
      )}
    </Thead>
  );
};
