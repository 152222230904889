/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableFooterCell
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/footer/MRT_TableFooterCell.tsx#L15)
 */

// 3rd
import { Td, type TableCellProps, useColorMode } from '@chakra-ui/react';

// App
import { parseFromValuesOrFunc } from '../column.utils';
import { getCommonTableCellStyles } from '../style.utils';
import { MRT_Column, type MRT_Header, type MRT_RowData, type MRT_TableInstance } from '../types';

interface Props<TData extends MRT_RowData> extends TableCellProps {
  footer: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableFooterCell = <TData extends MRT_RowData>({
  footer,
  table,
  ...tableCellProps
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    options: { tableFooterCellProps },
  } = table;
  const { column } = footer;
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const args = { column, table };
  const _tableCellProps = {
    ...parseFromValuesOrFunc(tableFooterCellProps, args),
    ...parseFromValuesOrFunc(columnDef.tableFooterCellProps, args),
    ...tableCellProps,
  };

  return (
    <Td
      align={columnDefType === 'group' ? 'center' : 'left'}
      colSpan={footer.colSpan}
      variant="head"
      {..._tableCellProps}
      sx={{
        // INSIGHT Display appears also in getCommonTableCellStyles() therefore the value will be overwritten. TS doesn't like it so I commented it out for now
        // display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
        fontWeight: 'bold',
        justifyContent: columnDefType === 'group' ? 'center' : undefined,
        p: '16px',
        verticalAlign: 'top',
        zIndex: column.getIsPinned() && columnDefType !== 'group' ? 2 : 1,
        ...getCommonTableCellStyles({
          column: column as MRT_Column<MRT_RowData>,
          table: table as MRT_TableInstance<MRT_RowData>,
          tableCellProps: _tableCellProps,
          colorModeContext,
        }),
        ...(_tableCellProps?.sx || {}),
      }}
    >
      {_tableCellProps.children ??
        (footer.isPlaceholder
          ? null
          : parseFromValuesOrFunc(columnDef.Footer, {
              column,
              footer,
              table,
            }) ??
            columnDef.footer ??
            null)}
    </Td>
  );
};
