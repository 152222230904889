export const radii = {
  // none: '0',
  // sm: '2px',
  // base: '4px',
  // md: '6px',
  // lg: '8px',
  // xl: '12px',
  // '2xl': '16px',
  // '3xl': '24px',
  // full: '9999px',
  none: '0',
  0.5: '2px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  8: '32px',
  full: '9999px',
};

export const radiiTokens = {
  none: radii.none,
  xs: radii['0.5'],
  sm: radii['1'],
  md: radii['2'],
  lg: radii['3'],
  xl: radii['4'],
  '2xl': radii['8'],
  full: radii.full,
};
