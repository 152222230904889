// App - Other
import { fonts } from './fonts';
import { fontSizes, fontSizeTokens } from './font-sizes';
import { fontWeights, fontWeightTokens } from './font-weights';
import { lineHeights, lineHeightTokens } from './line-heights';
import { colors, colorTokens } from './colors';
import { space, spaceTokens } from './spacing';
import { sizes, sizeTokens } from './sizes';
import { radii, radiiTokens } from './border-radius';
import { borders } from './borders';
import { zIndices } from './z-indices';
import { shadows, shadowTokens } from './shadows';

export const foundations = {
  borders,
  fonts,
  fontSizes,
  fontWeights,
  space,
  sizes,
  lineHeights,
  colors,
  radii,
  zIndices,
  shadows,

  semanticTokens: {
    radii: radiiTokens,
    fontSizes: fontSizeTokens,
    fontWeights: fontWeightTokens,
    lineHeights: lineHeightTokens,
    colors: colorTokens,
    space: spaceTokens,
    sizes: sizeTokens,
    shadows: shadowTokens,
  },
};
