/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableDetailPanel
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/body/MRT_TableDetailPanel.tsx#L22)
 */

// React & Next
import { useEffect, useState } from 'react';
import type { RefObject } from 'react';

// 3rd
import { Collapse, Tr, Td, useColorMode } from '@chakra-ui/react';
import type { TableCellProps } from '@chakra-ui/react';
import type { VirtualItem } from '@tanstack/react-virtual';

// App - Types
import type { MRT_Row, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { parseFromValuesOrFunc } from '../column.utils';
import { getTableTheme } from '../style.utils';

interface Props<TData extends MRT_RowData> extends TableCellProps {
  parentRowRef: RefObject<HTMLTableRowElement>;
  row: MRT_Row<TData>;
  rowIndex: number;
  table: MRT_TableInstance<TData>;
  virtualRow?: VirtualItem;
}

export const TableDetailPanel = <TData extends MRT_RowData>({
  parentRowRef,
  row,
  rowIndex,
  table,
  virtualRow,
  ...tableCellProps
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getState,
    getVisibleLeafColumns,
    options: { layoutMode, detailPanelProps, tableBodyRowProps, renderDetailPanel },
  } = table;
  const { isLoading, expanded } = getState();

  const tableRowProps = parseFromValuesOrFunc(tableBodyRowProps, {
    isDetailPanel: true,
    row,
    staticRowIndex: rowIndex,
    table,
  });

  const _tableCellProps = {
    ...parseFromValuesOrFunc(detailPanelProps, {
      row,
      table,
    }),
    ...tableCellProps,
  };

  const [isPanelAnimating, setIsPanelAnimating] = useState(false);
  const isExpanded =
    !!renderDetailPanel && (typeof expanded === 'boolean' ? expanded : !!expanded[row.id]);
  const shouldRender = isPanelAnimating || isExpanded;

  useEffect(() => {
    if (isPanelAnimating === isExpanded) return;

    setTimeout(() => {
      setIsPanelAnimating(isExpanded);
    }, 100);
  }, [isExpanded, isPanelAnimating]);

  if (!shouldRender) return null;

  return (
    <Tr
      className="Mui-TableBodyCell-DetailPanel"
      {...tableRowProps}
      sx={{
        // _hover: {
        //   boxShadow: 'table-row',
        // },
        display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
        position: virtualRow ? 'absolute' : undefined,
        top: virtualRow ? `${parentRowRef.current?.getBoundingClientRect()?.height}px` : undefined,
        transform: virtualRow ? `translateY(${virtualRow?.start}px)` : undefined,
        width: '100%',
        zIndex: virtualRow ? 2 : undefined,
        ...(tableRowProps?.sx || {}),
      }}
    >
      <Td
        className="Mui-TableBodyCell-DetailPanel"
        colSpan={getVisibleLeafColumns().length}
        {..._tableCellProps}
        sx={{
          borderBottomLeftRadius: '14px',
          borderBottomRightRadius: '14px',
          backgroundColor: virtualRow
            ? getTableTheme(table, colorModeContext).baseBackgroundColor
            : '#fff',
          borderBottom: !row.getIsExpanded() ? 'none' : undefined,
          display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
          py: isExpanded ? '24px' : '0px',
          px: isExpanded ? '20px' : '0px',
          transition: 'all 120ms ease-in-out',
          width: `${table.getTotalSize()}px`,
          ...(_tableCellProps?.sx || {}),
        }}
      >
        {renderDetailPanel && (
          <Collapse in={isPanelAnimating && isExpanded}>
            {!isLoading && renderDetailPanel!({ row, table })}
          </Collapse>
        )}
      </Td>
    </Tr>
  );
};
