// [X] import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// [X] import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// [X] import CancelIcon from '@mui/icons-material/Cancel';
// [X] import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// [X] import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// [X] import ClearAllIcon from '@mui/icons-material/ClearAll';
// [X] import CloseIcon from '@mui/icons-material/Close';
// [X] import DragHandleIcon from '@mui/icons-material/DragHandle';
// [X] import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
// [X] import EditIcon from '@mui/icons-material/Edit';
// [X] import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import FilterListOffIcon from '@mui/icons-material/FilterListOff';
// import FirstPageIcon from '@mui/icons-material/FirstPage';
// import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
// import LastPageIcon from '@mui/icons-material/LastPage';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import PushPinIcon from '@mui/icons-material/PushPin';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import SaveIcon from '@mui/icons-material/Save';
// import SearchIcon from '@mui/icons-material/Search';
// import SearchOffIcon from '@mui/icons-material/SearchOff';
// import SortIcon from '@mui/icons-material/Sort';
// import SyncAltIcon from '@mui/icons-material/SyncAlt';
// import ViewColumnIcon from '@mui/icons-material/ViewColumn';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// App - Other
import {
  ArrowDownIcon as ArrowDownwardIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  CloseIcon,
  EditIcon,
  CancelIcon,
  ArrowRightFilledIcon,
  ArrowLeftFilledIcon,
  ClearAllIcon,
  DragHandleIcon,
  GroupByIcon as DynamicFeedIcon,
  SortIcon as SortDefaultIcon,
  SearchIcon,
  FilterIcon as FilterListIcon,
  FirstPageIcon,
  LastPageIcon,
  PushPinIcon,
  SaveIcon,
  AddIcon,
  ExpandAllIcon as KeyboardDoubleArrowDownIcon,
  ChevronDownIcon as ExpandMoreIcon,
  DotsHorizontalIcon as MoreHorizIcon,
  DotsVerticalIcon as MoreVertIcon,
  ColumnsIcon as ViewColumnIcon,
} from '@/components/atoms/icon';

export interface TableIcons {
  ArrowDownwardIcon: typeof ArrowDownwardIcon;
  ArrowRightIcon: typeof ArrowRightFilledIcon;
  ArrowLeftIcon: typeof ArrowLeftFilledIcon;
  CancelIcon: typeof CancelIcon;
  ChevronLeftIcon: typeof ChevronLeftIcon;
  ChevronRightIcon: typeof ChevronRightIcon;
  ClearAllIcon: typeof ClearAllIcon;
  CloseIcon: typeof CloseIcon;
  DragHandleIcon: typeof DragHandleIcon;
  DynamicFeedIcon: typeof DynamicFeedIcon;
  EditIcon: typeof EditIcon;
  ExpandMoreIcon: typeof ExpandMoreIcon;
  FilterAltIcon: typeof FilterListIcon;
  FilterListIcon: typeof FilterListIcon;
  FilterListOffIcon: typeof FilterListIcon;
  FirstPageIcon: typeof FirstPageIcon;
  KeyboardDoubleArrowDownIcon: typeof KeyboardDoubleArrowDownIcon;
  LastPageIcon: typeof LastPageIcon;
  MoreHorizIcon: typeof MoreHorizIcon;
  MoreVertIcon: typeof MoreVertIcon;
  PushPinIcon: typeof PushPinIcon;
  RestartAltIcon: typeof AddIcon;
  SaveIcon: typeof SaveIcon;
  SearchIcon: typeof SearchIcon;
  SearchOffIcon: typeof SearchIcon;
  SortIcon: typeof AddIcon;
  SortDefaultIcon?: typeof SortDefaultIcon;
  ViewColumnIcon: typeof ViewColumnIcon;
  VisibilityOffIcon: typeof AddIcon;
}

export const DEFAULT_TABLE_ICONS: TableIcons = {
  ArrowDownwardIcon,
  ArrowRightIcon: ArrowRightFilledIcon,
  ArrowLeftIcon: ArrowLeftFilledIcon,
  CancelIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClearAllIcon,
  CloseIcon,
  DragHandleIcon,
  DynamicFeedIcon,
  EditIcon,
  ExpandMoreIcon,
  FilterAltIcon: FilterListIcon,
  FilterListIcon,
  FilterListOffIcon: FilterListIcon,
  FirstPageIcon,
  LastPageIcon,
  KeyboardDoubleArrowDownIcon,
  MoreHorizIcon,
  MoreVertIcon,
  PushPinIcon,
  RestartAltIcon: AddIcon,
  SaveIcon,
  SearchIcon,
  SearchOffIcon: SearchIcon,
  SortIcon: AddIcon,
  SortDefaultIcon,
  ViewColumnIcon,
  VisibilityOffIcon: AddIcon,
};
