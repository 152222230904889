/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_GlobalFilterTextField
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/inputs/MRT_GlobalFilterTextField.tsx#L23)
 */

// React & Next
import { useCallback, useEffect, useRef, useState } from 'react';
import type { ChangeEvent } from 'react';

// 3rd
import { SlideFade } from '@chakra-ui/react';

// App - Types
import type { InputProps } from '@/components/molecules/form';
import type { MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { debounce } from '@/utils/debounce';
import { IconButton } from '@/components/molecules/button';
import { Input } from '@/components/molecules/form';
import { FilterOptionMenu } from '../menus/filter-option-menu';
import { parseFromValuesOrFunc } from '../column.utils';

interface Props<TData extends MRT_RowData> extends InputProps {
  table: MRT_TableInstance<TData>;
}

export const GlobalSearchInput = <TData extends MRT_RowData>({
  table,
  ...inputProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      enableGlobalFilterModes,
      icons: { CloseIcon, SearchIcon },
      localization,
      manualFiltering,
      searchTextFieldProps,
    },
    refs: { searchInputRef },
    setGlobalFilter,
  } = table;
  const { globalFilter, showGlobalFilter } = getState();

  const _inputProps: InputProps = {
    ...parseFromValuesOrFunc(searchTextFieldProps, {
      table,
    }),
    ...inputProps,
  };

  const isMounted = useRef(false);
  const [searchValue, setSearchValue] = useState(globalFilter ?? '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebounced = useCallback(
    debounce(
      (event: ChangeEvent<HTMLInputElement>) => {
        setGlobalFilter(event.target.value ?? undefined);
      },
      manualFiltering ? 500 : 250
    ),
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    handleChangeDebounced(event);
  };

  const handleClear = () => {
    setSearchValue('');
    setGlobalFilter(undefined);
  };

  useEffect(() => {
    if (isMounted.current) {
      if (globalFilter === undefined) {
        handleClear();
      } else {
        setSearchValue(globalFilter);
      }
    }

    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  return (
    <SlideFade in={showGlobalFilter} offsetY="-1px" offsetX="50px">
      <Input
        variant="old.outline"
        size="sm"
        w="180px"
        zIndex={2}
        leftAddon={
          enableGlobalFilterModes ? (
            <FilterOptionMenu
              icon={SearchIcon({
                size: 'xs',
                color: '#57606A',
                'aria-label': localization.changeSearchMode,
              })}
              label={localization.changeSearchMode}
              onSelect={handleClear}
              table={table}
            />
          ) : (
            <SearchIcon
              style={{ width: '14px', height: '14px' }}
              color="text.high-tone"
              aria-label={localization.search}
            />
          )
        }
        rightAddon={
          searchValue?.length ? (
            <IconButton
              aria-label={localization.clearSearch}
              isDisabled={!searchValue?.length}
              onClick={handleClear}
              size="sm"
              variant="old.ghost"
              _hover={{ bg: 'transparent' }}
              icon={
                <CloseIcon
                  style={{ width: '8px', height: '8px' }}
                  color="text.primary"
                  aria-label={localization.clearSearch}
                />
              }
            />
          ) : null
        }
        onChange={handleChange}
        // placeholder={localization.search}
        value={searchValue ?? ''}
        {..._inputProps}
        ref={(inputRef: HTMLInputElement) => {
          searchInputRef.current = inputRef;
        }}
        sx={{
          mb: 0,
          ...(_inputProps?.sx || {}),
        }}
      />
    </SlideFade>
  );
};
