// 3rd
import { accordionAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { body, paragraph } from '@/config/theme/components/text';
import { title } from '@/config/theme/components/heading';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// ##############
// ACCORDION BASE
// ##############

const baseStyle = definePartsStyle({
  root: {},

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    bg: 'transparent',
    gap: 'sm',
  },

  button: {
    display: 'flex',
    alignItems: 'center',
  },

  panel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    bg: 'transparent',
    w: '100%',
  },

  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

// #####
// SIZES
// #####

const smSize = definePartsStyle({
  root: {},

  container: {},

  button: {
    py: 'xs',
    px: 'sm',
    gap: 'sm',
    ...body,
  },

  panel: {
    px: 'sm',
  },

  icon: {},
});

const mdSize = definePartsStyle({
  root: {},

  container: {},

  button: {
    py: 'sm',
    px: 'sm',
    gap: 'sm',
    ...paragraph,
  },

  panel: {
    px: 'sm',
  },

  icon: {},
});

const lgSize = definePartsStyle({
  root: {},

  container: {},

  button: {
    py: 'sm',
    px: 'sm',
    gap: 'xs',
    ...title,

    '& .chakra-accordion__icon-container > svg': {
      w: '16px',
      h: '16px',
    },
  },

  panel: {
    px: 'sm',
  },

  icon: {},
});

// ########
// VARIANTS
// ########

const titleBaseVariant = definePartsStyle({
  root: {},

  container: {
    borderTopWidth: 'none',
    borderBottomWidth: 'none',

    _last: {
      borderBottomWidth: 'none',
    },

    '&.with-title-icon': {
      '.chakra-accordion__panel': {
        ps: '28px',
      },
    },
  },

  button: {
    borderRadius: 'sm',
    bg: 'transparent',
    color: 'text.primary',

    // INSIGHT => below is to bright, falling back to chakra's default for now (black.50)
    // _hover: {
    //   bg: 'surface.secondary',
    // },

    '& .title__icon-container': {
      borderRadius: 'lg',
      py: 'xs',
      px: 'xs',
      gap: 'none',

      '& svg.chakra-icon': {
        color: 'text.primary',
        w: '16px',
        h: '16px',
      },
    },

    '& .chakra-accordion__icon-container': {
      borderRadius: 'lg',
      py: 'xs',
      px: 'xs',
      gap: 'none',
    },
  },

  panel: {
    py: 'none',
  },

  icon: {
    color: 'text.primary',
    w: '16px',
    h: '16px',
  },
});

const titleGhostVariant = definePartsStyle({
  root: {
    ...titleBaseVariant.root,
  },

  container: {
    ...titleBaseVariant.container,
  },

  button: {
    ...titleBaseVariant.button,

    px: 'none',

    _hover: {
      bg: 'inherit',
    },
  },

  panel: {
    ...titleBaseVariant.panel,
    px: 'none',
  },

  icon: {
    ...titleBaseVariant.icon,
  },
});

const oldVariant = definePartsStyle({
  root: {},

  container: {
    bg: 'surface.secondary',
    borderRadius: '4px',
    borderWidth: '0',
    borderBottomWidth: '0 !important',
    boxShadow: 'none',
    outline: 'none',
  },

  button: {
    p: '16px',
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 500,
    borderWidth: '0',
    boxShadow: 'none',
    outline: 'none',
  },

  panel: {
    borderWidth: '0',
    boxShadow: 'none',
    outline: 'none',
  },

  icon: {
    w: '16px',
    h: '16px',
  },
});

const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { sm: smSize, md: mdSize, lg: lgSize },
  variants: {
    old: oldVariant,
    title: titleBaseVariant,
    'title.ghost': titleGhostVariant,
  },
});

export const Accordion = accordionTheme;
