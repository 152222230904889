/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: useMRT_DisplayColumns
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/hooks/useMRT_DisplayColumns.tsx#L36)
 */

// React & Next
import { useMemo } from 'react';
import type { RefObject } from 'react';

// App - Types
import type {
  MRT_ColumnDef,
  ColumnOrderState,
  MRT_DefinedTableOptions,
  GroupingState,
  MRT_Row,
  MRT_RowData,
  MRT_TableOptions,
} from '../types';

// App - Other
import { TableBodyRowGrabHandle } from '../body';
import { TableBodyRowPinButton } from '../body/body-row/table-body-row-pin-button';
import { ExpandAllButton } from '../buttons/expand-all-button';
import { ExpandButton } from '../buttons/expand-button';
import { ToggleRowActionMenuButton } from '../buttons/toggle-row-action-menu-button';
import { showExpandColumn } from '../column.utils';
import { SelectCheckbox } from '../inputs/select-checkbox';
import { MRT_DefaultDisplayColumn } from './useTableOptions';

const blankColProps = {
  children: null,
  sx: {
    flex: '1 0 auto',
    minWidth: 0,
    p: 0,
    width: 0,
  },
};

interface Params<TData extends MRT_RowData> {
  columnOrder: ColumnOrderState;
  creatingRow: MRT_Row<TData> | null;
  grouping: GroupingState;
  tableOptions: MRT_DefinedTableOptions<TData>;
}

export const useTableDisplayColumns = <TData extends MRT_RowData>({
  columnOrder,
  creatingRow,
  grouping,
  tableOptions,
}: Params<TData>) => {
  return useMemo(
    () =>
      (
        [
          (tableOptions.state?.columnOrder ?? columnOrder).includes('row-pin') && {
            Cell: ({ row, table }) => <TableBodyRowPinButton row={row} table={table} />,
            header: tableOptions.localization.pin,
            size: 60,
            ...tableOptions.displayColumnDefOptions?.['row-pin'],
            columnDefType: 'display',
            id: 'row-pin',
          },
          (tableOptions.state?.columnOrder ?? columnOrder).includes('row-drag') && {
            Cell: ({ row, rowRef, table }) => (
              <TableBodyRowGrabHandle
                row={row}
                rowRef={rowRef as RefObject<HTMLTableRowElement>}
                table={table}
              />
            ),
            header: tableOptions.localization.move,
            size: 60,
            ...tableOptions.displayColumnDefOptions?.['row-drag'],
            columnDefType: 'display',
            id: 'row-drag',
          },
          ((tableOptions.state?.columnOrder ?? columnOrder).includes('row-actions') ||
            (creatingRow && tableOptions.createDisplayMode === 'row')) && {
            Cell: ({ cell, row, table }) => (
              <ToggleRowActionMenuButton cell={cell} row={row} table={table} float="right" />
            ),
            header: '',
            size: 70,
            ...tableOptions.displayColumnDefOptions?.['row-actions'],
            columnDefType: 'display',
            id: 'row-actions',
          },
          (tableOptions.state?.columnOrder ?? columnOrder).includes('row-expand') &&
            showExpandColumn(
              tableOptions as MRT_TableOptions<TData>,
              tableOptions.state?.grouping ?? grouping
            ) && {
              Cell: ({ row, table }) => <ExpandButton row={row} table={table} />,
              Header: tableOptions.enableExpandAll
                ? ({ table }) => <ExpandAllButton table={table} />
                : null,
              // header: tableOptions.localization.expand,
              header: '',
              size: 20,
              ...tableOptions.displayColumnDefOptions?.['row-expand'],
              columnDefType: 'display',
              id: 'row-expand',
            },
          (tableOptions.state?.columnOrder ?? columnOrder).includes('row-select') && {
            Cell: ({ row, table }) => <SelectCheckbox row={row} table={table} />,
            Header:
              tableOptions.enableSelectAll && tableOptions.enableMultiRowSelection
                ? ({ table }) => <SelectCheckbox selectAll table={table} />
                : null,
            header: tableOptions.localization.select,
            size: 20,
            ...tableOptions.displayColumnDefOptions?.['row-select'],
            columnDefType: 'display',
            id: 'row-select',
          },
          (tableOptions.state?.columnOrder ?? columnOrder).includes('row-numbers') && {
            Cell: ({ row }) => row.index + 1,
            Header: () => tableOptions.localization.rowNumber,
            header: tableOptions.localization.rowNumbers,
            size: 60,
            ...tableOptions.displayColumnDefOptions?.['row-numbers'],
            columnDefType: 'display',
            id: 'row-numbers',
          },
          (tableOptions.state?.columnOrder ?? columnOrder).includes('row-spacer') && {
            ...tableOptions.displayColumnDefOptions?.['row-spacer'],
            ...MRT_DefaultDisplayColumn,
            columnDefType: 'display',
            header: '',
            id: 'row-spacer',
            muiTableBodyCellProps: blankColProps,
            muiTableFooterCellProps: blankColProps,
            muiTableHeadCellProps: blankColProps,
          },
        ] as MRT_ColumnDef<TData>[]
      ).filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      columnOrder,
      grouping,
      tableOptions.displayColumnDefOptions,
      tableOptions.editDisplayMode,
      tableOptions.enableColumnDragging,
      tableOptions.enableColumnFilterModes,
      tableOptions.enableColumnOrdering,
      tableOptions.enableEditing,
      tableOptions.enableExpandAll,
      tableOptions.enableExpanding,
      tableOptions.enableGrouping,
      tableOptions.enableRowActions,
      tableOptions.enableRowDragging,
      tableOptions.enableRowNumbers,
      tableOptions.enableRowOrdering,
      tableOptions.enableRowSelection,
      tableOptions.enableSelectAll,
      tableOptions.localization,
      tableOptions.positionActionsColumn,
      tableOptions.renderDetailPanel,
      tableOptions.renderRowActionMenuItems,
      tableOptions.renderRowActions,
      tableOptions.state?.columnOrder,
      tableOptions.state?.grouping,
    ]
  );
};
