/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ToggleFiltersButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_ToggleFiltersButton.tsx#L9)
 */

// App - Types
import type { ButtonProps } from '@/components/molecules/button';
import type { MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { Button } from '@/components/molecules/button';

interface Props<TData extends MRT_RowData> extends Omit<ButtonProps, 'aria-label'> {
  table: MRT_TableInstance<TData>;
}

export const ToggleFiltersButton = <TData extends MRT_RowData>({
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { FilterListIcon, FilterListOffIcon },
      localization,
    },
    setShowColumnFilters,
  } = table;
  const { showColumnFilters } = getState();

  const handleToggleShowFilters = () => {
    setShowColumnFilters(!showColumnFilters);
  };

  return (
    <Button
      variant="old.ghost"
      size="sm"
      leftIcon={
        showColumnFilters ? (
          <FilterListOffIcon size="xs" aria-label={localization.showHideFilters} />
        ) : (
          <FilterListIcon size="xs" aria-label={localization.showHideFilters} />
        )
      }
      aria-label={localization.showHideFilters}
      onClick={handleToggleShowFilters}
      {...iconButtonProps}
    >
      {localization.filters}
    </Button>
  );
};
