/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_FilterOptionMenu
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/menus/MRT_FilterOptionMenu.tsx#L117)
 */

// React & Next
import { useMemo } from 'react';
import type { ReactElement, ReactNode } from 'react';

// 3rd
import {
  Menu,
  MenuList,
  MenuButton,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  Flex,
} from '@chakra-ui/react';
import type { MenuProps } from '@chakra-ui/react';

// App - Types
import type {
  MRT_Column,
  MRT_FilterOption,
  MRT_Header,
  MRT_InternalFilterOption,
  DataTableLocalization,
  MRT_RowData,
  MRT_TableInstance,
} from '../types';

// App - Other
import { Text } from '@/components/atoms/typography';
import { IconButton } from '@/components/molecules/button';

export const mrtFilterOptions = (
  localization: DataTableLocalization
): MRT_InternalFilterOption[] => [
  {
    divider: false,
    label: localization.filterFuzzy,
    option: 'fuzzy',
    symbol: '≈',
  },
  {
    divider: false,
    label: localization.filterContains,
    option: 'contains',
    symbol: '*',
  },
  {
    divider: false,
    label: localization.filterStartsWith,
    option: 'startsWith',
    symbol: 'a',
  },
  {
    divider: true,
    label: localization.filterEndsWith,
    option: 'endsWith',
    symbol: 'z',
  },
  {
    divider: false,
    label: localization.filterEquals,
    option: 'equals',
    symbol: '=',
  },
  {
    divider: true,
    label: localization.filterNotEquals,
    option: 'notEquals',
    symbol: '≠',
  },
  {
    divider: false,
    label: localization.filterBetween,
    option: 'between',
    symbol: '⇿',
  },
  {
    divider: true,
    label: localization.filterBetweenInclusive,
    option: 'betweenInclusive',
    symbol: '⬌',
  },
  {
    divider: false,
    label: localization.filterGreaterThan,
    option: 'greaterThan',
    symbol: '>',
  },
  {
    divider: false,
    label: localization.filterGreaterThanOrEqualTo,
    option: 'greaterThanOrEqualTo',
    symbol: '≥',
  },
  {
    divider: false,
    label: localization.filterLessThan,
    option: 'lessThan',
    symbol: '<',
  },
  {
    divider: true,
    label: localization.filterLessThanOrEqualTo,
    option: 'lessThanOrEqualTo',
    symbol: '≤',
  },
  {
    divider: false,
    label: localization.filterEmpty,
    option: 'empty',
    symbol: '∅',
  },
  {
    divider: false,
    label: localization.filterNotEmpty,
    option: 'notEmpty',
    symbol: '!∅',
  },
];

const rangeModes = ['between', 'betweenInclusive', 'inNumberRange'];
const emptyModes = ['empty', 'notEmpty'];
const arrModes = ['arrIncludesSome', 'arrIncludesAll', 'arrIncludes'];
const rangeVariants = ['range-slider', 'date-range', 'range'];

interface Props<TData extends MRT_RowData> extends Partial<MenuProps> {
  header?: MRT_Header<TData>;
  onSelect?: () => void;
  setFilterValue?: (filterValue: string | []) => void;
  table: MRT_TableInstance<TData>;
  icon: ReactElement;
  label: string;
  menuButton?: ReactNode;
}

export const FilterOptionMenu = <TData extends MRT_RowData>({
  header,
  onSelect,
  setFilterValue,
  table,
  icon,
  label,
  menuButton,
  ...menuProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      columnFilterModeOptions,
      globalFilterModeOptions,
      localization,
      renderColumnFilterModeMenuItems,
      renderGlobalFilterModeMenuItems,
    },
    setColumnFilterFns,
    setGlobalFilterFn,
  } = table;
  const { globalFilterFn } = getState();
  const { column } = header ?? {};
  const { columnDef } = column ?? {};
  const currentFilterValue = column?.getFilterValue();

  let allowedColumnFilterOptions = columnDef?.columnFilterModeOptions ?? columnFilterModeOptions;

  if (rangeVariants.includes(columnDef?.filterVariant as string)) {
    allowedColumnFilterOptions = [...rangeModes, ...(allowedColumnFilterOptions ?? [])].filter(
      (option) => rangeModes.includes(option)
    );
  }

  const internalFilterOptions = useMemo(
    () =>
      mrtFilterOptions(localization).filter((filterOption) =>
        columnDef
          ? allowedColumnFilterOptions === undefined ||
            allowedColumnFilterOptions?.includes(filterOption.option)
          : (!globalFilterModeOptions || globalFilterModeOptions.includes(filterOption.option)) &&
            ['contains', 'fuzzy', 'startsWith'].includes(filterOption.option)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSelectFilterMode = (option: MRT_FilterOption) => {
    const prevFilterMode = columnDef?._filterFn ?? '';

    if (!header || !column) {
      // global filter mode
      setGlobalFilterFn(option);
    } else if (option !== prevFilterMode) {
      // column filter mode
      setColumnFilterFns((prev: { [key: string]: MRT_FilterOption }) => ({
        ...prev,
        [header.id]: option,
      }));

      // reset filter value and/or perform new filter render
      if (emptyModes.includes(option)) {
        // will now be empty/notEmpty filter mode
        if (currentFilterValue !== ' ' && !emptyModes.includes(prevFilterMode)) {
          column.setFilterValue(' ');
        } else if (currentFilterValue) {
          column.setFilterValue(currentFilterValue); // perform new filter render
        }
      } else if (
        columnDef?.filterVariant === 'multi-select' ||
        arrModes.includes(option as string)
      ) {
        // will now be array filter mode
        if (
          currentFilterValue instanceof String ||
          (currentFilterValue as Array<unknown>)?.length
        ) {
          column.setFilterValue([]);
          setFilterValue?.([]);
        } else if (currentFilterValue) {
          column.setFilterValue(currentFilterValue); // perform new filter render
        }
      } else if (
        columnDef?.filterVariant?.includes('range') ||
        rangeModes.includes(option as MRT_FilterOption)
      ) {
        // will now be range filter mode
        if (
          !Array.isArray(currentFilterValue) ||
          (!(currentFilterValue as Array<unknown>)?.every((v) => v === '') &&
            !rangeModes.includes(prevFilterMode))
        ) {
          column.setFilterValue(['', '']);
          setFilterValue?.('');
        } else {
          column.setFilterValue(currentFilterValue); // perform new filter render
        }
      } else {
        // will now be single value filter mode
        if (Array.isArray(currentFilterValue)) {
          column.setFilterValue('');
          setFilterValue?.('');
        } else {
          column.setFilterValue(currentFilterValue); // perform new filter render
        }
      }
    }
    onSelect?.();
  };

  const filterOption = !!header && columnDef ? columnDef._filterFn : globalFilterFn;

  return (
    <Menu {...menuProps}>
      {menuButton ?? (
        <MenuButton as={IconButton} variant="old.ghost" size="sm" aria-label={label} icon={icon} />
      )}

      <MenuList>
        <MenuOptionGroup value={filterOption} type="radio">
          {(header && column && columnDef
            ? columnDef.renderColumnFilterModeMenuItems?.({
                column: column as MRT_Column<TData>,
                internalFilterOptions,
                onSelectFilterMode: handleSelectFilterMode,
                table,
              }) ??
              renderColumnFilterModeMenuItems?.({
                column: column as MRT_Column<TData>,
                internalFilterOptions,
                onSelectFilterMode: handleSelectFilterMode,
                table,
              })
            : renderGlobalFilterModeMenuItems?.({
                internalFilterOptions,
                onSelectFilterMode: handleSelectFilterMode,
                table,
              })) ??
            internalFilterOptions.flatMap(({ divider, label, option, symbol }, index) => [
              [
                <MenuItemOption
                  key={index}
                  onClick={() => handleSelectFilterMode(option as MRT_FilterOption)}
                  value={option}
                  sx={{
                    gap: '12px',
                    my: 0,
                    py: '6px',
                  }}
                >
                  <Flex alignItems="center" gap="12px">
                    <Text variant="detail">{symbol}</Text>

                    <Text variant="detail">{label}</Text>
                  </Flex>
                </MenuItemOption>,
              ],
              divider ? [<MenuDivider key={`${index}-divider`} />] : [],
            ])}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
