/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TopToolbar
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/toolbar/MRT_TopToolbar.tsx#L17)
 */

// 3rd
import { Box, useColorMode, useMediaQuery } from '@chakra-ui/react';

// App
import { LinearProgressBar } from '../common/linear-progress-bar';
import { TablePagination } from '../common/table-pagination';
import { ToolbarAlertBanner } from '../common/toolbar-alert-banner';
import { ToolbarDropZone } from '../common/toolbar-drop-zone';
import { ToolbarInternalButtons } from './toolbar-internal-buttons';
import { parseFromValuesOrFunc } from '../../column.utils';
import { GlobalSearchInput } from '../../inputs/global-search-input';
import { getCommonToolbarStyles } from '../../style.utils';
import { type MRT_RowData, type MRT_TableInstance } from '../../types';

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}

export const TopToolbar = <TData extends MRT_RowData>({ table }: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getState,
    options: {
      enableGlobalFilter,
      enablePagination,
      enableToolbarInternalActions,
      topToolbarProps,
      positionGlobalFilter,
      positionPagination,
      positionToolbarAlertBanner,
      positionToolbarDropZone,
      renderTopToolbarCustomActions,
    },
    refs: { topToolbarRef },
  } = table;
  const [isMobile] = useMediaQuery('(max-width:720px)');
  const { showGlobalFilter } = getState();

  const toolbarProps = parseFromValuesOrFunc(topToolbarProps, { table });

  const stackAlertBanner = isMobile || !!renderTopToolbarCustomActions || showGlobalFilter;

  return (
    <Box
      {...toolbarProps}
      ref={(ref: HTMLDivElement) => {
        topToolbarRef.current = ref;

        // if (toolbarProps?.ref) {
        //   // @ts-ignore
        //   toolbarProps.ref.current = ref;
        // }
      }}
      sx={{
        ...getCommonToolbarStyles({ table, colorModeContext }),
        position: 'relative',
        mb: '8px',
        borderRadius: '4px',
        // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        zIndex: 3,
        ...(toolbarProps?.sx || {}),
      }}
    >
      {positionToolbarAlertBanner === 'top' && (
        <ToolbarAlertBanner stackAlertBanner={stackAlertBanner} table={table} />
      )}

      {['both', 'top'].includes(positionToolbarDropZone ?? '') && <ToolbarDropZone table={table} />}

      <Box
        sx={{
          alignItems: 'center',
          h: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between',
          p: '16px 24px',
          // position: stackAlertBanner ? 'relative' : 'absolute',
          position: 'absolute', // With line above it makes it weird when the alert banner is showing and opening the search bar
          right: 0,
          top: 0,
          width: '100%',
        }}
      >
        {enableGlobalFilter && positionGlobalFilter === 'left' && (
          <GlobalSearchInput table={table} />
        )}

        {renderTopToolbarCustomActions?.({ table }) ?? <span />}

        {enableToolbarInternalActions ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap-reverse',
              gap: '8px',
              justifyContent: 'flex-end',
            }}
          >
            {enableGlobalFilter && positionGlobalFilter === 'right' && (
              <GlobalSearchInput table={table} />
            )}

            <ToolbarInternalButtons table={table} />
          </Box>
        ) : (
          enableGlobalFilter &&
          positionGlobalFilter === 'right' && <GlobalSearchInput table={table} />
        )}
      </Box>

      {enablePagination && ['both', 'top'].includes(positionPagination ?? '') && (
        <TablePagination position="top" table={table} />
      )}

      <LinearProgressBar isTopToolbar table={table} />
    </Box>
  );
};
