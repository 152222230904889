/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableBody
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/body/MRT_TableBody.tsx#L31)
 */

// React & Next
import { memo, useCallback, useMemo } from 'react';

// 3rd
import { useVirtualizer } from '@tanstack/react-virtual';
import type { Range, VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { Tbody, Tr, Td, useColorMode, Flex } from '@chakra-ui/react';
import { type TableBodyProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Row, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { EmptyStateIllustration } from '@/components/atoms/icon';
import { Heading } from '@/components/atoms/typography';
import { extraIndexRangeExtractor, getCanRankRows, parseFromValuesOrFunc } from '../column.utils';
import { getTableTheme } from '../style.utils';
import { rankGlobalFuzzy } from '../sortingFns';
import { TableBodyRow, MemoTableBodyRow } from './body-row/table-body-row';

interface Props<TData extends MRT_RowData> extends TableBodyProps {
  columnVirtualizer?: Virtualizer<HTMLDivElement, HTMLTableCellElement>;
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}

export const TableBody = <TData extends MRT_RowData>({
  columnVirtualizer,
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...tableBodyProps
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getBottomRows,
    getCenterRows,
    getIsSomeRowsPinned,
    getPrePaginationRowModel,
    getRowModel,
    getState,
    getTopRows,
    options: {
      createDisplayMode,
      enableGlobalFilterRankedResults,
      enablePagination,
      enableRowPinning,
      enableRowVirtualization,
      enableStickyFooter,
      enableStickyHeader,
      layoutMode,
      localization,
      manualExpanding,
      manualFiltering,
      manualGrouping,
      manualPagination,
      manualSorting,
      memoMode,
      tableBodyProps: tableTableBodyProps,
      renderEmptyRowsFallback,
      rowPinningDisplayMode,
      rowVirtualizerInstanceRef,
      rowVirtualizerOptions,
    },
    refs: { tableContainerRef, tableFooterRef, tableHeadRef, tableBoxRef },
  } = table;
  const {
    columnFilters,
    creatingRow,
    draggingRow,
    expanded,
    globalFilter,
    pagination,
    rowPinning,
    sorting,
  } = getState();

  const _tableBodyProps = {
    ...parseFromValuesOrFunc(tableTableBodyProps, { table }),
    ...tableBodyProps,
  };
  const rowVirtualizerProps = parseFromValuesOrFunc(rowVirtualizerOptions, {
    table,
  });

  const tableHeadHeight = (enableStickyHeader && tableHeadRef.current?.clientHeight) || 0;
  const tableFooterHeight = (enableStickyFooter && tableFooterRef.current?.clientHeight) || 0;

  const shouldRankRows = useMemo(
    () => getCanRankRows(table) && !Object.values(sorting).some(Boolean) && globalFilter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      enableGlobalFilterRankedResults,
      expanded,
      globalFilter,
      manualExpanding,
      manualFiltering,
      manualGrouping,
      manualSorting,
      sorting,
    ]
  );

  const pinnedRowIds = useMemo(
    () =>
      getRowModel()
        .rows.filter((row) => row.getIsPinned())
        .map((r) => r.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowPinning, table.getRowModel().rows]
  );

  const rows = useMemo(() => {
    let rows: MRT_Row<TData>[] = [];
    if (!shouldRankRows) {
      rows =
        !enableRowPinning || rowPinningDisplayMode?.includes('sticky')
          ? getRowModel().rows
          : getCenterRows();
    } else {
      rows = getPrePaginationRowModel().rows.sort((a, b) => rankGlobalFuzzy(a, b));
      if (enablePagination && !manualPagination) {
        const start = pagination.pageIndex * pagination.pageSize;
        rows = rows.slice(start, start + pagination.pageSize);
      }
    }
    if (enableRowPinning && rowPinningDisplayMode?.includes('sticky')) {
      rows = [
        ...getTopRows().filter((row) => !pinnedRowIds.includes(row.id)),
        ...rows,
        ...getBottomRows().filter((row) => !pinnedRowIds.includes(row.id)),
      ];
    }

    return rows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldRankRows,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    shouldRankRows ? getPrePaginationRowModel().rows : getRowModel().rows,
    pagination.pageIndex,
    pagination.pageSize,
    rowPinning,
  ]);

  const rowVirtualizer: Virtualizer<HTMLDivElement, HTMLTableRowElement> | undefined =
    enableRowVirtualization
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useVirtualizer({
          count: rows.length,
          estimateSize: () => 58,
          getScrollElement: () => tableContainerRef.current,
          measureElement:
            typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
              ? (element) => element?.getBoundingClientRect().height
              : undefined,
          overscan: 4,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          rangeExtractor: useCallback(
            (range: Range) => {
              return extraIndexRangeExtractor(range, draggingRow?.index ?? 0);
            },
            [draggingRow]
          ),
          ...rowVirtualizerProps,
        })
      : undefined;

  if (rowVirtualizerInstanceRef && rowVirtualizer) {
    rowVirtualizerInstanceRef.current = rowVirtualizer;
  }

  const virtualRows = rowVirtualizer ? rowVirtualizer.getVirtualItems() : undefined;

  const { baseBackgroundColor } = getTableTheme(table, colorModeContext);

  return (
    <>
      {!rowPinningDisplayMode?.includes('sticky') && getIsSomeRowsPinned('top') && (
        <Tbody
          {..._tableBodyProps}
          sx={{
            display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
            position: 'sticky',
            top: tableHeadHeight - 1,
            zIndex: 1,
            ...(_tableBodyProps?.sx || {}),
          }}
        >
          {getTopRows().map((row, rowIndex) => {
            const props = {
              columnVirtualizer,
              measureElement: rowVirtualizer?.measureElement,
              numRows: rows.length,
              row,
              rowIndex,
              table,
              virtualColumns,
              virtualPaddingLeft,
              virtualPaddingRight,
            };

            return memoMode === 'rows' ? (
              <MemoTableBodyRow key={row.id} {...props} />
            ) : (
              <TableBodyRow key={row.id} {...props} />
            );
          })}
        </Tbody>
      )}
      <Tbody
        {..._tableBodyProps}
        sx={{
          display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
          height: rowVirtualizer ? `${rowVirtualizer.getTotalSize()}px` : 'inherit',
          minHeight: !rows.length ? '100px' : undefined,
          position: 'relative',
          ...(_tableBodyProps?.sx || {}),
        }}
      >
        {creatingRow && createDisplayMode === 'row' && (
          <TableBodyRow row={creatingRow} rowIndex={-1} table={table} />
        )}
        {_tableBodyProps?.children ??
          (!rows.length ? (
            <Tr
              display={layoutMode?.startsWith('grid') ? 'grid' : undefined}
              bg={baseBackgroundColor}
            >
              <Td
                display={layoutMode?.startsWith('grid') ? 'grid' : undefined}
                colSpan={table.getVisibleLeafColumns().length}
              >
                {renderEmptyRowsFallback?.({ table }) ?? (
                  <Flex
                    direction="column"
                    justifyContent="center"
                    maxWidth={`min(100vw, ${tableBoxRef.current?.clientWidth ?? 360}px)`}
                  >
                    <EmptyStateIllustration w="auto" h="310px" py="32px" />

                    <Heading
                      variant="h3"
                      sx={{
                        maxWidth: `min(100vw, ${tableBoxRef.current?.clientWidth ?? 360}px)`,
                        textAlign: 'center !important',
                        width: '100%',
                        pb: '16px',
                      }}
                    >
                      {globalFilter || columnFilters.length
                        ? localization.noResultsFound
                        : localization.noRecordsToDisplay}
                    </Heading>
                  </Flex>
                )}
              </Td>
            </Tr>
          ) : (
            <>
              {(virtualRows ?? rows).map((rowOrVirtualRow, rowIndex) => {
                const row = rowVirtualizer
                  ? rows[rowOrVirtualRow.index]
                  : (rowOrVirtualRow as MRT_Row<TData>);
                const props = {
                  columnVirtualizer,
                  measureElement: rowVirtualizer?.measureElement,
                  numRows: rows.length,
                  pinnedRowIds,
                  row,
                  rowIndex: rowVirtualizer ? rowOrVirtualRow.index : rowIndex,
                  table,
                  virtualColumns,
                  virtualPaddingLeft,
                  virtualPaddingRight,
                  virtualRow: rowVirtualizer ? (rowOrVirtualRow as VirtualItem) : undefined,
                };
                return memoMode === 'rows' ? (
                  <MemoTableBodyRow key={row.id} {...props} />
                ) : (
                  <TableBodyRow key={row.id} {...props} />
                );
              })}
            </>
          ))}
      </Tbody>

      {!rowPinningDisplayMode?.includes('sticky') && getIsSomeRowsPinned('bottom') && (
        <Tbody
          {..._tableBodyProps}
          sx={{
            bottom: tableFooterHeight - 1,
            display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
            position: 'sticky',
            zIndex: 1,
            ...(_tableBodyProps?.sx || {}),
          }}
        >
          {getBottomRows().map((row, rowIndex) => {
            const props = {
              columnVirtualizer,
              measureElement: rowVirtualizer?.measureElement,
              numRows: rows.length,
              row,
              rowIndex,
              table,
              virtualColumns,
              virtualPaddingLeft,
              virtualPaddingRight,
            };

            return memoMode === 'rows' ? (
              <MemoTableBodyRow key={row.id} {...props} />
            ) : (
              <TableBodyRow key={row.id} {...props} />
            );
          })}
        </Tbody>
      )}
    </>
  );
};

export const MemoTableBody = memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
) as typeof TableBody;
