/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ShowHideColumnsMenu
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/menus/MRT_ShowHideColumnsMenu.tsx#L21)
 */

// React & Next
import { useMemo, useState } from 'react';

// 3rd
import { Box, Divider, MenuList } from '@chakra-ui/react';
import type { MenuListProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Column, MRT_RowData, MRT_TableInstance, MRT_TableOptions } from '../types';

// App - Other
import { Button } from '@/components/molecules/button';
import { getDefaultColumnOrderIds } from '../column.utils';
import { ShowHideColumnsMenuItems } from './show-hide-columns-menu-items';

interface Props<TData extends MRT_RowData> extends Partial<MenuListProps> {
  isSubMenu?: boolean;
  table: MRT_TableInstance<TData>;
}

export const ShowHideColumnsMenu = <TData extends MRT_RowData>({
  table,
  ...restMenuListProps
}: Props<TData>) => {
  const {
    getAllColumns,
    getAllLeafColumns,
    getCenterLeafColumns,
    getIsAllColumnsVisible,
    getIsSomeColumnsPinned,
    getIsSomeColumnsVisible,
    getLeftLeafColumns,
    getRightLeafColumns,
    getState,
    options: { enableColumnOrdering, enableColumnPinning, enableHiding, localization },
    toggleAllColumnsVisible,
  } = table;
  const { columnOrder, columnPinning } = getState();

  const hideAllColumns = () => {
    getAllLeafColumns()
      .filter((col) => col.columnDef.enableHiding !== false)
      .forEach((col) => col.toggleVisibility(false));
  };

  const allColumns = useMemo(() => {
    const columns = getAllColumns();
    if (columnOrder.length > 0 && !columns.some((col) => col.columnDef.columnDefType === 'group')) {
      return [
        ...getLeftLeafColumns(),
        ...Array.from(new Set(columnOrder)).map((colId) =>
          getCenterLeafColumns().find((col) => col?.id === colId)
        ),
        ...getRightLeafColumns(),
      ].filter(Boolean);
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnOrder,
    columnPinning,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getCenterLeafColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getLeftLeafColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getRightLeafColumns(),
  ]) as MRT_Column<TData>[];

  const [hoveredColumn, setHoveredColumn] = useState<MRT_Column<TData> | null>(null);

  return (
    <MenuList pb={0} {...restMenuListProps}>
      {allColumns.map((column, index) => (
        <ShowHideColumnsMenuItems
          allColumns={allColumns}
          column={column}
          hoveredColumn={hoveredColumn}
          key={`${index}-${column.id}`}
          setHoveredColumn={setHoveredColumn}
          table={table}
        />
      ))}

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: '8px',
          // pt: 0,
        }}
      >
        {enableHiding && (
          <Button
            isDisabled={!getIsSomeColumnsVisible()}
            onClick={hideAllColumns}
            size="sm"
            variant="old.ghost"
            aria-label={localization.hideAll}
          >
            {localization.hideAll}
          </Button>
        )}

        {enableColumnOrdering && (
          <Button
            onClick={() =>
              table.setColumnOrder(
                getDefaultColumnOrderIds(table.options as MRT_TableOptions<TData>)
              )
            }
            size="sm"
            variant="old.ghost"
            aria-label={localization.resetOrder}
          >
            {localization.resetOrder}
          </Button>
        )}

        {enableColumnPinning && (
          <Button
            disabled={!getIsSomeColumnsPinned()}
            onClick={() => table.resetColumnPinning(true)}
            size="sm"
            variant="old.ghost"
            aria-label={localization.unpinAll}
          >
            {localization.unpinAll}
          </Button>
        )}

        {enableHiding && (
          <Button
            disabled={getIsAllColumnsVisible()}
            onClick={() => toggleAllColumnsVisible(true)}
            size="sm"
            variant="old.ghost"
            aria-label={localization.showAll}
          >
            {localization.showAll}
          </Button>
        )}
      </Box>
    </MenuList>
  );
};
