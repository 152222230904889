// 3rd
import { z } from 'zod';

// App - Types
import {
  KnowledgeSourcesDto,
  castKnowledgeSourceDtoToKnowledgeSource,
} from '@/types/integration/dtos/knowledge-source';
import {
  ClassificationsDto,
  castClassificationDtoToClassification,
} from '@/types/knowledge-item/dtos/classification';
import type { ClassificationDto } from '@/types/knowledge-item/dtos/classification';
import { PrioritiesDto, castPriorityDtoToPriority } from '@/types/knowledge-item/dtos/priority';
import type { PriorityDto } from '@/types/knowledge-item/dtos/priority';
import { attemptToCastKnowledgeSourceToType } from '@/types/knowledge-item/type';
import { castTypeToKind } from '@/types/knowledge-item/kind';
import type { CollectionStat } from '../../types/collection';

// App - Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodCollectionStatDto = z.object({
  documentPriorityToCount: z.record(z.enum(PrioritiesDto), z.number().optional()).catch((e) => {
    console.error('DocumentPriorityToCount parsing errored! see:', e);

    return {};
  }),
  documentTypeToCount: z.record(z.enum(ClassificationsDto), z.number().optional()).catch((e) => {
    console.error('DocumentTypeToCount parsing errored! see:', e);

    return {};
  }),
  id: z.string(),
  collectionId: z.string(),
  collectionName: z.string(),
  latestActivity: z.string().optional(),
  knowledgeSourceType: z.enum(KnowledgeSourcesDto),
  totalDocumentCount: z.number(),
  prioritizedDocumentCount: z.number(),
});

export type CollectionStatDto = z.infer<typeof ZodCollectionStatDto>;

export const castCollectionStatDtoToCollectionStat = (dto: CollectionStatDto): CollectionStat => {
  const source = castKnowledgeSourceDtoToKnowledgeSource(dto.knowledgeSourceType);
  const kind = castTypeToKind(attemptToCastKnowledgeSourceToType(source));

  return {
    id: dto.id,
    collectionId: dto.collectionId,
    source,
    kind,
    latestActivity: dto.latestActivity ? normalizeDatesToISOString(dto.latestActivity) : undefined,
    name: dto.collectionName,
    totalItemsCount: dto.totalDocumentCount,
    prioritizedItemsCount: dto.prioritizedDocumentCount,
    prioritizedItemsPrioritiesToCount: Object.entries(dto.documentPriorityToCount).reduce(
      (acc, entry) => {
        const priorityDto = entry[0] as PriorityDto;
        const priority = castPriorityDtoToPriority(priorityDto);
        const totalCount = entry[1] as number;

        if (totalCount) acc[priority] = totalCount;

        return acc;
      },
      {} as CollectionStat['prioritizedItemsPrioritiesToCount']
    ),
    prioritizedItemsClassificationsToCount: Object.entries(dto.documentTypeToCount).reduce(
      (acc, entry) => {
        const classificationDto = entry[0] as ClassificationDto;
        const classification = castClassificationDtoToClassification(classificationDto);
        const totalCount = entry[1] as number;

        if (totalCount) acc[classification] = totalCount;

        return acc;
      },
      {} as CollectionStat['prioritizedItemsClassificationsToCount']
    ),
  };
};
