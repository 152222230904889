import { Accordion } from './accordion';
import { Alert } from './alert';
import { Avatar } from './avatar';
import { Badge } from './badge';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { Drawer } from './drawer';
import { FormComponents } from './form';
import { Heading } from './heading';
import { Input } from './input';
import { Link } from './link';
import { Modal } from './modal';
import { Popover } from './popover';
import { Progress } from './progress';
import { Select } from './select';
import { Stat } from './stat';
import { Tabs } from './tabs';
import { Tag } from './tag';
import { Text } from './text';
import { Textarea } from './textarea';

export const components = {
  Accordion,
  Alert,
  Avatar,
  Badge,
  Button,
  Checkbox,
  Drawer,
  ...FormComponents,
  Heading,
  Input,
  Link,
  Modal,
  Popover,
  Progress,
  Select,
  Stat,
  Tabs,
  Tag,
  Text,
  Textarea,
};
