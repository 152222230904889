/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ToolbarAlertBanner
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/toolbar/MRT_ToolbarAlertBanner.tsx#L17)
 */

// React & Next
import { Fragment } from 'react';

// 3rd
import { Alert, AlertTitle, Collapse, Flex, Stack } from '@chakra-ui/react';
import type { AlertProps } from '@chakra-ui/react';

// App - Types
import type { MRT_RowData, MRT_TableInstance } from '../../types';

// App - Other
import { Text } from '@/components/atoms/typography';
import { Tag } from '@/components/molecules/tag';
import { parseFromValuesOrFunc } from '../../column.utils';
import { SelectCheckbox } from '../../inputs';

interface Props<TData extends MRT_RowData> extends AlertProps {
  stackAlertBanner?: boolean;
  table: MRT_TableInstance<TData>;
}

export const ToolbarAlertBanner = <TData extends MRT_RowData>({
  stackAlertBanner,
  table,
  ...alertProps
}: Props<TData>) => {
  const {
    getPrePaginationRowModel,
    getSelectedRowModel,
    getState,
    options: {
      enableRowSelection,
      enableSelectAll,
      localization,
      toolbarAlertBannerTagProps,
      toolbarAlertBannerProps,
      positionToolbarAlertBanner,
      renderToolbarAlertBannerContent,
      rowCount,
    },
    refs: { tableBoxRef },
  } = table;
  const { grouping, showAlertBanner } = getState();

  const _alertProps = {
    ...parseFromValuesOrFunc(toolbarAlertBannerProps, {
      table,
    }),
    ...alertProps,
  };

  const tagProps = parseFromValuesOrFunc(toolbarAlertBannerTagProps, {
    table,
  });

  const selectedAlert =
    getSelectedRowModel().rows.length > 0
      ? localization.selectedCountOfRowCountRowsSelected
          ?.replace('{selectedCount}', getSelectedRowModel().rows.length.toString())
          ?.replace('{rowCount}', (rowCount ?? getPrePaginationRowModel().rows.length).toString())
      : null;

  const groupedAlert =
    grouping.length > 0 ? (
      <Flex alignItems="center" gap="6px">
        <Text variant="detail">{localization.groupedBy} </Text>

        {grouping.map((columnId, index) => (
          <Fragment key={`${index}-${columnId}`}>
            {index > 0 ? localization.thenBy : ''}

            <Tag
              variant="secondary"
              size="old-md"
              onClose={() => table.getColumn(columnId).toggleGrouping()}
              {...tagProps}
            >
              {table.getColumn(columnId).columnDef.header}
            </Tag>
          </Fragment>
        ))}
      </Flex>
    ) : null;

  return (
    <Collapse
      in={showAlertBanner || !!selectedAlert || !!groupedAlert}
      // delay={stackAlertBanner ? 200 : 0}
      transition={{ enter: { duration: 0.12 } }}
      endingHeight="100%"
      style={{ display: 'flex', position: 'relative' }}
    >
      <Alert
        {..._alertProps}
        sx={{
          borderRadius: '4px',
          fontSize: '12px',
          fontWeight: '400',
          left: 0,
          mb: stackAlertBanner ? 0 : positionToolbarAlertBanner === 'bottom' ? '-16px' : undefined,
          p: 0,
          // bg: alpha('#36BFBF', 0.4),
          bg: 'surface.primary',
          color: 'text.brand.primary',
          // position: 'relative', // TODO: IT USED TO BE RELATIVE BUT IN ORDER TO MAKE ALERT ON BOTTOM WORK I NEED TO POSITION THIS ABSOLUTE
          position: selectedAlert && groupedAlert ? 'relative' : 'absolute',
          height: '100%',
          right: 0,
          top: 0,
          width: '100%',
          zIndex: 1,
          ...(_alertProps?.sx || {}),
        }}
      >
        {renderToolbarAlertBannerContent?.({
          groupedAlert,
          selectedAlert,
          table,
        }) ?? (
          <>
            {_alertProps?.title && (
              <AlertTitle
                w="100%"
                maxWidth={`calc(${tableBoxRef.current?.clientWidth ?? 360}px - 16px)`}
              >
                {_alertProps.title}
              </AlertTitle>
            )}

            <Stack
              sx={{
                p: positionToolbarAlertBanner !== 'head-overlay' ? '8px 16px' : '8px 12px',
                flexDirection: 'row',
              }}
            >
              {_alertProps?.children}

              {_alertProps?.children && (selectedAlert || groupedAlert) && <br />}

              <Flex alignItems="center">
                {enableRowSelection &&
                  enableSelectAll &&
                  positionToolbarAlertBanner === 'head-overlay' && (
                    <SelectCheckbox selectAll table={table} />
                  )}{' '}
                {selectedAlert}
              </Flex>

              {selectedAlert && groupedAlert && <br />}

              {groupedAlert}
            </Stack>
          </>
        )}
      </Alert>
    </Collapse>
  );
};
