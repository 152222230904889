/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_BottomToolbar
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/toolbar/MRT_BottomToolbar.tsx#L16)
 */

// 3rd
import { Box, type BoxProps, useColorMode, useMediaQuery } from '@chakra-ui/react';

// App
import { LinearProgressBar } from '../common/linear-progress-bar';
import { TablePagination } from '../common/table-pagination';
import { ToolbarAlertBanner } from '../common/toolbar-alert-banner';
import { ToolbarDropZone } from '../common/toolbar-drop-zone';
import { parseFromValuesOrFunc } from '../../column.utils';
import { getCommonToolbarStyles } from '../../style.utils';
import { type MRT_RowData, type MRT_TableInstance } from '../../types';

interface Props<TData extends MRT_RowData> extends BoxProps {
  table: MRT_TableInstance<TData>;
}

export const BottomToolbar = <TData extends MRT_RowData>({ table, ...boxProps }: Props<TData>) => {
  const colorModeContext = useColorMode();
  // const { baseHeadRowColor } = getTableTheme(table, colorModeContext);
  const {
    options: {
      enablePagination,
      bottomToolbarProps,
      positionPagination,
      positionToolbarAlertBanner,
      positionToolbarDropZone,
      renderBottomToolbarCustomActions,
    },
    refs: { bottomToolbarRef },
  } = table;
  const [isMobile] = useMediaQuery('(max-width:720px)');

  const toolbarProps = {
    ...parseFromValuesOrFunc(bottomToolbarProps, { table }),
    ...boxProps,
  };

  const stackAlertBanner = isMobile || !!renderBottomToolbarCustomActions;

  return (
    <Box
      {...toolbarProps}
      ref={(node: HTMLDivElement) => {
        if (node) {
          bottomToolbarRef.current = node;

          // if (toolbarProps?.ref) {
          //   // @ts-ignore
          //   toolbarProps.ref.current = node;
          // }
        }
      }}
      sx={{
        ...getCommonToolbarStyles({ table, colorModeContext }),
        // boxShadow: `0 1px 2px -1px ${alpha(theme.colors.gray[700], 0.5)} inset`,
        left: 0,
        position: 'relative',
        right: 0,
        borderRadius: '4px',
        ...(toolbarProps?.sx || {}),
      }}
    >
      <LinearProgressBar isTopToolbar={false} table={table} />

      {positionToolbarAlertBanner === 'bottom' && (
        <ToolbarAlertBanner stackAlertBanner={stackAlertBanner} table={table} />
      )}

      {['both', 'bottom'].includes(positionToolbarDropZone ?? '') && (
        <ToolbarDropZone table={table} />
      )}

      <Box
        sx={{
          alignItems: 'center',
          h: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          px: '8px',
        }}
      >
        {renderBottomToolbarCustomActions ? renderBottomToolbarCustomActions({ table }) : <span />}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: stackAlertBanner ? 'relative' : 'absolute',
            right: 0,
            top: 0,
            ...(!stackAlertBanner ? { top: '50%', transform: 'translateY(-50%)' } : {}),
          }}
        >
          {enablePagination && ['both', 'bottom'].includes(positionPagination ?? '') && (
            <TablePagination position="bottom" table={table} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
