'use client';

// React & Next
import { useEffect, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

// App - Types
import { type ColumnFiltersState, type PaginationState, type SortingState } from '../types';

type UseTableUrlPersistence = {
  queryParamsPrefix?: string;
  initialColumnFilters?: ColumnFiltersState;
  initialSorting?: ColumnFiltersState;
  initialPagination?: PaginationState;
  initialGlobalFilter?: string;
};

export const useTableUrlPersistence = ({
  queryParamsPrefix = '',
  initialColumnFilters,
  initialSorting,
  initialPagination,
  initialGlobalFilter,
}: UseTableUrlPersistence = {}) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    JSON.parse(searchParams?.get(`${queryParamsPrefix}columnFilters`) || '""') ||
      initialColumnFilters ||
      []
  );
  const [globalFilter, setGlobalFilter] = useState(
    searchParams?.get(`${queryParamsPrefix}globalFilter`) || initialGlobalFilter || ''
  );
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(searchParams?.get(`${queryParamsPrefix}sorting`) || '""') || initialSorting || []
  );
  const [pagination, setPagination] = useState<PaginationState>(
    {
      pageIndex: parseInt(searchParams?.get(`${queryParamsPrefix}pageIndex`) || '0', 10),
      pageSize: parseInt(searchParams?.get(`${queryParamsPrefix}pageSize`) || '10', 10),
    } || initialPagination
  );

  useEffect(() => {
    const newSearchParams = new URLSearchParams(Array.from(searchParams?.entries() || []));

    newSearchParams.delete(`${queryParamsPrefix}columnFilters`);

    if (columnFilters.length)
      newSearchParams.set(`${queryParamsPrefix}columnFilters`, JSON.stringify(columnFilters));

    newSearchParams.delete(`${queryParamsPrefix}globalFilter`);

    if (globalFilter) newSearchParams.set(`${queryParamsPrefix}globalFilter`, globalFilter);

    newSearchParams.delete(`${queryParamsPrefix}sorting`);

    if (sorting.length) newSearchParams.set(`${queryParamsPrefix}sorting`, JSON.stringify(sorting));

    newSearchParams.delete(`${queryParamsPrefix}pageIndex`);
    newSearchParams.delete(`${queryParamsPrefix}pageSize`);
    newSearchParams.set(`${queryParamsPrefix}pageIndex`, pagination.pageIndex.toString());
    newSearchParams.set(`${queryParamsPrefix}pageSize`, pagination.pageSize.toString());

    const newSearchParamsStr = newSearchParams.toString();
    const query = newSearchParamsStr ? `?${newSearchParamsStr}` : '';

    router.replace(`${pathname}${query}`);
  }, [
    columnFilters,
    globalFilter,
    sorting,
    pagination,
    router,
    pathname,
    queryParamsPrefix,
    searchParams,
  ]);

  return {
    columnFilters,
    globalFilter,
    sorting,
    pagination,
    setColumnFilters,
    setGlobalFilter,
    setSorting,
    setPagination,
  };
};
