export const fontSizes = {
  // body: '12px',
  // 'light-heading': '12px',
  // heading: '12px',
  // 'state-title': '20px',
  // 'drawer-header': '20px',
  // 'page-header': '24px',
  //
  2: '8px',
  2.5: '10px',
  3: '12px',
  3.5: '14px',
  4: '16px',
  4.5: '18px',
  5: '20px',
  6: '24px',
  7: '28px',
  7.5: '30px',
  8: '32px',
  9: '36px',
  10: '40px',
  12: '48px',
};

export const fontSizeTokens = {
  xxs: fontSizes['2'],
  xs: fontSizes['2.5'],
  sm: fontSizes['3'],
  md: fontSizes['3.5'],
  lg: fontSizes['4'],
  xl: fontSizes['4.5'],
  '2xl': fontSizes['5'],
  '3xl': fontSizes['6'],
  '4xl': fontSizes['7.5'],
  '5xl': fontSizes['12'],
};
