/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableBodyCell
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/body/MRT_TableBodyCell.tsx#L39)
 */

// React & Next
import { memo, useEffect, useMemo, useState } from 'react';
import type { ReactNode, DragEvent, MouseEvent, RefObject } from 'react';

// 3rd
import { Td, Skeleton, useColorMode } from '@chakra-ui/react';
import type { TableCellProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Cell, MRT_Column, MRT_RowData, MRT_TableInstance } from '../../types';

// App - Other
import { theme } from '@/config/theme';
import { CopyButton } from '../../buttons/copy-button';
import { EditCellInput } from '../../inputs/edit-cell-input';
import { getIsFirstColumn, getIsLastColumn, parseFromValuesOrFunc } from '../../column.utils';
import { getCommonTableCellStyles, getTableTheme } from '../../style.utils';
import { TableBodyRowGrabHandle } from '../body-row/table-body-row-grab-handle';
import { TableBodyCellValue } from './table-body-cell-value';

interface Props<TData extends MRT_RowData> extends TableCellProps {
  cell: MRT_Cell<TData>;
  measureElement?: (element: HTMLTableCellElement) => void;
  numRows?: number;
  rowIndex: number;
  rowRef: RefObject<HTMLTableRowElement>;
  table: MRT_TableInstance<TData>;
  virtualIndex?: number;
}

export const TableBodyCell = <TData extends MRT_RowData>({
  cell,
  measureElement,
  numRows,
  rowIndex,
  rowRef,
  table,
  virtualIndex,
  ...rest
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getState,
    options: {
      columnResizeMode,
      createDisplayMode,
      editDisplayMode,
      enableClickToCopy,
      enableColumnOrdering,
      enableEditing,
      enableGrouping,
      enableRowNumbers,
      layoutMode,
      skeletonProps: tableSkeletonProps,
      tableBodyCellProps,
      rowNumberDisplayMode,
      renderDetailPanel,
    },
    refs: { editInputRefs },
    setEditingCell,
    setHoveredColumn,
  } = table;
  const {
    columnSizingInfo,
    creatingRow,
    draggingColumn,
    draggingRow,
    editingCell,
    editingRow,
    hoveredColumn,
    hoveredRow,
    isLoading,
    showSkeletons,
  } = getState();
  const { column, row } = cell;
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const args = { cell, column, row, table };
  const tableCellProps = {
    ...parseFromValuesOrFunc(tableBodyCellProps, args),
    ...parseFromValuesOrFunc(columnDef.tableBodyCellProps, args),
    ...rest,
  };
  const skeletonProps = parseFromValuesOrFunc(tableSkeletonProps, {
    cell,
    column,
    row,
    table,
  });

  const { draggingBorderColor } = getTableTheme(table, colorModeContext);

  const [skeletonWidth, setSkeletonWidth] = useState(100);

  useEffect(() => {
    if ((!isLoading && !showSkeletons) || skeletonWidth !== 100) return;

    const size = column.getSize();

    setSkeletonWidth(
      columnDefType === 'display'
        ? size / 2
        : Math.round(Math.random() * (size - size / 3) + size / 3)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, showSkeletons]);

  const draggingBorders = useMemo(() => {
    const isDraggingColumn = draggingColumn?.id === column.id;
    const isHoveredColumn = hoveredColumn?.id === column.id;
    const isDraggingRow = draggingRow?.id === row.id;
    const isHoveredRow = hoveredRow?.id === row.id;
    const isFirstColumn = getIsFirstColumn(column, table);
    const isLastColumn = getIsLastColumn(column, table);
    const isLastRow = numRows && rowIndex === numRows - 1;
    const showResizeBorder =
      columnSizingInfo.isResizingColumn === column.id && columnResizeMode === 'onChange';

    const borderStyle = showResizeBorder
      ? `2px solid ${draggingBorderColor} !important`
      : isDraggingColumn || isDraggingRow
        ? `1px dashed ${theme.colors.gray[500]} !important`
        : isHoveredColumn || isHoveredRow || columnSizingInfo.isResizingColumn === column.id
          ? `2px dashed ${draggingBorderColor} !important`
          : undefined;

    if (showResizeBorder) {
      return { borderRight: borderStyle };
    }

    return borderStyle
      ? {
          borderBottom: isDraggingRow || isHoveredRow || isLastRow ? borderStyle : undefined,
          borderLeft:
            isDraggingColumn ||
            isHoveredColumn ||
            ((isDraggingRow || isHoveredRow) && isFirstColumn)
              ? borderStyle
              : undefined,
          borderRight:
            isDraggingColumn || isHoveredColumn || ((isDraggingRow || isHoveredRow) && isLastColumn)
              ? borderStyle
              : undefined,
          borderTop: isDraggingRow || isHoveredRow ? borderStyle : undefined,
        }
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnSizingInfo.isResizingColumn,
    draggingColumn,
    draggingRow,
    hoveredColumn,
    hoveredRow,
    rowIndex,
  ]);

  const isEditable =
    parseFromValuesOrFunc(enableEditing, row) &&
    parseFromValuesOrFunc(columnDef.enableEditing, row) !== false;

  const isEditing =
    isEditable &&
    !['custom', 'modal'].includes(editDisplayMode as string) &&
    (editDisplayMode === 'table' || editingRow?.id === row.id || editingCell?.id === cell.id) &&
    !row.getIsGrouped();

  const isCreating = isEditable && createDisplayMode === 'row' && creatingRow?.id === row.id;

  const handleDoubleClick = (event: MouseEvent<HTMLTableCellElement>) => {
    tableCellProps?.onDoubleClick?.(event);
    if (isEditable && editDisplayMode === 'cell') {
      setEditingCell(cell);

      queueMicrotask(() => {
        const input = editInputRefs.current[column.id];

        if (input) {
          input.focus();
          (input as HTMLInputElement).select?.();
        }
      });
    }
  };

  const handleDragEnter = (e: DragEvent<HTMLTableCellElement>) => {
    tableCellProps?.onDragEnter?.(e);
    if (enableGrouping && hoveredColumn?.id === 'drop-zone') {
      setHoveredColumn(null);
    }
    if (enableColumnOrdering && draggingColumn) {
      setHoveredColumn(columnDef.enableColumnOrdering !== false ? column : null);
    }
  };

  const isExpanded = !!renderDetailPanel && row.getIsExpanded();

  return (
    <Td
      data-index={virtualIndex}
      ref={(node: HTMLTableCellElement) => {
        if (node) {
          measureElement?.(node);
        }
      }}
      {...tableCellProps}
      onDoubleClick={handleDoubleClick}
      onDragEnter={handleDragEnter}
      sx={{
        '&:first-of-type': {
          borderBottomLeftRadius: '4px !important',
          borderTopLeftRadius: isExpanded ? '14px !important' : '4px !important',
        },
        '&:last-of-type': {
          borderBottomRightRadius: '4px !important',
          borderTopRightRadius: isExpanded ? '14px !important' : '4px !important',
        },
        '&:hover': {
          outline:
            ['cell', 'table'].includes(editDisplayMode ?? '') && isEditable
              ? `1px solid ${theme.colors.gray[500]}`
              : undefined,
          outlineOffset: '-1px',
          textOverflow: 'clip',
        },
        alignItems: layoutMode?.startsWith('grid') ? 'center' : undefined,
        cursor: isEditable && editDisplayMode === 'cell' ? 'pointer' : 'inherit',
        justifyContent: layoutMode?.startsWith('grid') ? tableCellProps.align : undefined,
        overflow: 'hidden',
        p: columnDefType === 'display' ? '8px 16px' : '8px 16px',
        textOverflow: columnDefType !== 'display' ? 'ellipsis' : undefined,
        // [theme.direction === 'rtl' ? 'pr' : 'pl']:
        pl: column.id === 'row-expand' ? `${row.depth * 32 + 16}px` : undefined,
        whiteSpace: row.getIsPinned() || 'normal',
        zIndex: draggingColumn?.id === column.id ? 2 : column.getIsPinned() ? 1 : 0,
        ...getCommonTableCellStyles({
          column: column as MRT_Column<MRT_RowData>,
          table: table as MRT_TableInstance<MRT_RowData>,
          tableCellProps,
          colorModeContext,
        }),
        ...draggingBorders,
      }}
    >
      {tableCellProps.children ?? (
        <>
          {cell.getIsPlaceholder() ? (
            columnDef.PlaceholderCell?.({ cell, column, row, table }) ?? null
          ) : showSkeletons !== false && (isLoading || showSkeletons) ? (
            <Skeleton h="16px" w={skeletonWidth} {...skeletonProps} />
          ) : enableRowNumbers &&
            rowNumberDisplayMode === 'static' &&
            column.id === 'row-numbers' ? (
            rowIndex + 1
          ) : column.id === 'row-drag' ? (
            <TableBodyRowGrabHandle row={row} rowRef={rowRef} table={table} />
          ) : columnDefType === 'display' &&
            (column.id === 'row-select' || column.id === 'row-expand' || !row.getIsGrouped()) ? (
            columnDef.Cell?.({
              cell,
              column,
              renderedCellValue: cell.renderValue() as ReactNode,
              row,
              table,
            })
          ) : isCreating || isEditing ? (
            <EditCellInput cell={cell} table={table} />
          ) : (enableClickToCopy || columnDef.enableClickToCopy) &&
            columnDef.enableClickToCopy !== false ? (
            <CopyButton cell={cell} table={table}>
              <TableBodyCellValue cell={cell} table={table} />
            </CopyButton>
          ) : (
            <TableBodyCellValue cell={cell} table={table} />
          )}

          {cell.getIsGrouped() && !columnDef.GroupedCell && <> ({row.subRows?.length})</>}
        </>
      )}
    </Td>
  );
};

export const MemoTableBodyCell = memo(
  TableBodyCell,
  (prev, next) => next.cell === prev.cell
) as typeof TableBodyCell;
