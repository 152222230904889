// 3rd
import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { alpha } from '@/utils/color';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  alertAnatomy.keys
);

const brandVariant = definePartsStyle({
  container: {
    background: alpha('#4141fa', 0.2),
  },
});

export const alertTheme = defineMultiStyleConfig({
  variants: { brand: brandVariant },
});

export const Alert = alertTheme;
