// 3rd
import { avatarAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { label, small, tiny } from '@/config/theme/components/text';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// ###########
// AVATAR BASE
// ###########

const baseStyle = definePartsStyle({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',

    _disabled: {
      cursor: 'not-allowed',
      opacity: 0.6,
    },
  },

  excessLabel: {
    bg: 'surface.low-tone',
  },

  badge: {
    bg: 'surface.success',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.primary',
    right: '2px',
    bottom: '2px',
  },
});

// #####
// SIZES
// #####

const xsSize = definePartsStyle({
  container: {
    h: 'lg',
    w: 'lg',
  },

  group: {
    gap: 'md',
  },

  label: {
    ...tiny,
  },

  excessLabel: {
    ...small,
    h: '2xl',
    w: '2xl',
  },

  badge: {
    h: 'sm',
    w: 'sm',
  },
});

const smSize = definePartsStyle({
  container: {
    h: 'xl',
    w: 'xl',
  },

  group: {
    gap: 'md',
  },

  label: {
    ...small,
  },

  excessLabel: {
    ...small,
    h: '2xl',
    w: '2xl',
  },

  badge: {
    h: 'sm',
    w: 'sm',
  },
});

const mdSize = definePartsStyle({
  container: {
    h: '2xl',
    w: '2xl',
  },

  group: {
    gap: 'sm',
  },

  label: {
    ...small,
  },

  excessLabel: {
    ...small,
    h: '2xl',
    w: '2xl',
  },

  badge: {
    h: 'sm',
    w: 'sm',
  },
});

const lgSize = definePartsStyle({
  container: {
    h: '3xl',
    w: '3xl',
  },

  group: {
    gap: 'xs',
  },

  label: {
    ...label,
  },

  excessLabel: {
    ...label,
    h: '3xl',
    w: '3xl',
  },

  badge: {
    h: 'md',
    w: 'md',
  },
});

// ########
// VARIANTS
// ########

const initialsVariant = definePartsStyle({
  container: {
    bg: 'surface.gradient-1',
  },

  label: {
    color: 'text.invert-primary',
    ms: '1px',
  },
});

const iconVariant = definePartsStyle({
  container: {
    bg: 'surface.low-tone',
  },
});

const imageVariant = definePartsStyle({});

const avatarTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { xs: xsSize, sm: smSize, md: mdSize, lg: lgSize },
  variants: {
    initials: initialsVariant,
    icon: iconVariant,
    image: imageVariant,
  },
});

export const Avatar = avatarTheme;
