// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// App - Other
import { label } from '../text';

const baseStyle = defineStyle({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'xs',
  },

  requiredIndicator: {},

  helperText: {
    ...label,
    color: 'text.high-tone',
    m: 'none',
  },
});

export const formTheme = defineStyleConfig({
  baseStyle,
});

export const Form = formTheme;
