// 3rd
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { Collection } from '../types/collection';
import { castCollectionDtoToCollection, ZodCollectionDto } from './dtos/collection';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { COLLECTIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  knowledgeCollections: z.array(ZodCollectionDto).default([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetCollectionsResponseDto };
export type { ResponseDto as GetCollectionsResponseDto };

// #######
// Request
// #######

export const getCollections = async (): Promise<Collection[]> => {
  try {
    const res = await apiClient.get(`/Collection/ListCollections`);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.knowledgeCollections.map(castCollectionDtoToCollection);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCollections = {
  enabled?: boolean;
};

export const useCollections = ({ enabled = true }: UseCollections = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: COLLECTIONS_QUERY_KEYS.collections(),
    queryFn: async () => await getCollections(),
    enabled: enabled,
  });

  return {
    collections: data,
    isFetchingCollections: isLoading || isFetching,
    didFetchingCollectionsErrored: isLoadingError,
  };
};
