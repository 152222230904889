/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ToolbarInternalButtons
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/toolbar/MRT_ToolbarInternalButtons.tsx#L14)
 */

// 3rd
import { Box } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

// App - Types
import type { MRT_RowData, MRT_TableInstance } from '../../types';

// App - Other
import { ShowHideColumnsButton } from '../../buttons/show-hide-columns-button';
import { ToggleFiltersButton } from '../../buttons/toggle-filters-button';
import { ToggleGlobalSearchButton } from '../../buttons/toggle-global-search-button';

interface Props<TData extends MRT_RowData> extends BoxProps {
  table: MRT_TableInstance<TData>;
}

export const ToolbarInternalButtons = <TData extends MRT_RowData>({
  table,
  ...boxProps
}: Props<TData>) => {
  const {
    options: {
      columnHidingDisplay,
      columnFilterDisplayMode,
      enableColumnFilters,
      enableColumnOrdering,
      enableColumnPinning,
      enableFilters,
      enableGlobalFilter,
      enableHiding,
      initialState,
      renderToolbarInternalActions,
    },
  } = table;

  return (
    <Box
      {...boxProps}
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '6px',
        zIndex: 3,
        ...(boxProps?.sx || {}),
      }}
    >
      {renderToolbarInternalActions?.({
        table,
      }) ?? (
        <>
          {enableFilters && enableGlobalFilter && !initialState?.showGlobalFilter && (
            <ToggleGlobalSearchButton table={table} />
          )}

          {((enableHiding && columnHidingDisplay) ||
            enableColumnOrdering ||
            enableColumnPinning) && <ShowHideColumnsButton table={table} />}

          {enableFilters && enableColumnFilters && columnFilterDisplayMode !== 'popover' && (
            <ToggleFiltersButton table={table} />
          )}
        </>
      )}
    </Box>
  );
};
