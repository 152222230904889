/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MaterialReactTable
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/MaterialReactTable.tsx#L24)
 */

// App
import { useTable } from './useTable';
import { TableBox } from './table/table-box';
import { type MRT_RowData, type MRT_TableInstance, type MRT_TableOptions, type Xor } from './types';

type TableInstanceProp<TData extends MRT_RowData> = {
  table: MRT_TableInstance<TData>;
};

type Props<TData extends MRT_RowData> = Xor<TableInstanceProp<TData>, MRT_TableOptions<TData>>;

const isTableInstanceProp = <TData extends MRT_RowData>(
  props: Props<TData>
): props is TableInstanceProp<TData> => (props as TableInstanceProp<TData>).table !== undefined;

export const DataTable = <TData extends MRT_RowData>(props: Props<TData>) => {
  let table: MRT_TableInstance<TData>;

  if (isTableInstanceProp(props)) {
    table = props.table;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    table = useTable(props as MRT_TableOptions<TData>);
  }

  return <TableBox table={table} />;
};
