/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableContainer
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/table/MRT_TableContainer.tsx#L18)
 */

// React & Next
import { useEffect, useLayoutEffect, useState } from 'react';

// 3rd
import { TableContainer as ChakraTableContainer, type TableContainerProps } from '@chakra-ui/react';

// App
import { Table } from './table';
import { TableLoadingOverlay } from './table-loading-overlay';
import { EditRowModal } from '../modals';
import { type MRT_RowData, type MRT_TableInstance } from '../types';
import { parseFromValuesOrFunc } from '../column.utils';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

interface Props<TData extends MRT_RowData> extends TableContainerProps {
  table: MRT_TableInstance<TData>;
}

export const TableContainer = <TData extends MRT_RowData>({
  table,
  ...tableContainerProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      createDisplayMode,
      editDisplayMode,
      enableStickyHeader,
      tableContainerProps: tableTableContainerProps,
    },
    refs: { bottomToolbarRef, tableContainerRef, topToolbarRef },
  } = table;
  const { creatingRow, editingRow, isLoading, showLoadingOverlay } = getState();

  const loading = showLoadingOverlay !== false && (isLoading || showLoadingOverlay);

  const [totalToolbarHeight, setTotalToolbarHeight] = useState(0);

  const _tableContainerProps = {
    ...parseFromValuesOrFunc(tableTableContainerProps, {
      table,
    }),
    ...tableContainerProps,
  };

  useIsomorphicLayoutEffect(() => {
    const topToolbarHeight =
      typeof document !== 'undefined' ? topToolbarRef.current?.offsetHeight ?? 0 : 0;

    const bottomToolbarHeight =
      typeof document !== 'undefined' ? bottomToolbarRef?.current?.offsetHeight ?? 0 : 0;

    setTotalToolbarHeight(topToolbarHeight + bottomToolbarHeight);
  });

  const createModalOpen = createDisplayMode === 'modal' && creatingRow;
  const editModalOpen = editDisplayMode === 'modal' && editingRow;

  return (
    <ChakraTableContainer
      aria-busy={loading}
      aria-describedby={loading ? 'progress' : undefined}
      variant="unstyled"
      {..._tableContainerProps}
      ref={(node: HTMLDivElement) => {
        if (node) {
          tableContainerRef.current = node;

          // if (_tableContainerProps?.ref) {
          //   _tableContainerProps.ref.current = node;
          // }
        }
      }}
      style={{
        ..._tableContainerProps?.style,
      }}
      sx={{
        maxHeight: enableStickyHeader
          ? `clamp(350px, calc(100vh - ${totalToolbarHeight}px), 9999px)`
          : undefined,
        maxWidth: '100%',
        overflow: 'auto',
        position: 'relative',
        ...(_tableContainerProps?.sx || {}),
      }}
    >
      {loading ? <TableLoadingOverlay table={table} /> : null}

      <Table table={table} />

      {(createModalOpen || editModalOpen) && <EditRowModal open table={table} />}
    </ChakraTableContainer>
  );
};
