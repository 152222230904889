/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHeadCellFilterContainer
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHeadCellFilterContainer.tsx#L17)
 */

// 3rd
import { Collapse, type CollapseProps } from '@chakra-ui/react';

// App
import { FilterCheckbox } from '../../../inputs/filter-checkbox';
import { MRT_FilterRangeFields } from '../../../inputs/MRT_FilterRangeFields';
import { MRT_FilterRangeSlider } from '../../../inputs/MRT_FilterRangeSlider';
import { FilterInput } from '../../../inputs/filter-input';
import { type MRT_Header, type MRT_RowData, type MRT_TableInstance } from '../../../types';

interface Props<TData extends MRT_RowData> extends CollapseProps {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableHeadCellFilterContainer = <TData extends MRT_RowData>({
  header,
  table,
  ...collapseProps
}: Props<TData>) => {
  const {
    getState,
    options: { columnFilterDisplayMode },
  } = table;
  const { showColumnFilters } = getState();
  const { column } = header;
  const { columnDef } = column;

  return (
    <Collapse
      in={showColumnFilters || columnFilterDisplayMode === 'popover'}
      unmountOnExit
      {...collapseProps}
    >
      {columnDef.filterVariant === 'checkbox' ? (
        <FilterCheckbox column={column} table={table} />
      ) : columnDef.filterVariant === 'range-slider' ? (
        <MRT_FilterRangeSlider header={header} table={table} />
      ) : columnDef.filterVariant?.includes('range') ||
        ['between', 'betweenInclusive', 'inNumberRange'].includes(columnDef._filterFn) ? (
        <MRT_FilterRangeFields header={header} table={table} />
      ) : (
        <FilterInput header={header} table={table} />
      )}
    </Collapse>
  );
};
