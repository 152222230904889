// App - Other
import {
  DotsHorizontalIcon,
  FirstPageIcon,
  LastPageIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@/components/atoms/icon';
import { ButtonGroup, Button, IconButton } from '@/components/molecules/button';
import { IconBox } from '@/components/molecules/icon-box';
import { DOTS, usePagination } from './usePagination';

export type PaginationProps = {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  siblingCount?: number;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  onPaginate: (newPageNumber: number) => void;
  className?: string;
};

export const PagePagination = ({
  totalCount,
  currentPage,
  pageSize,
  onPaginate,
  siblingCount = 1,
  showFirstButton = true,
  showLastButton = true,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPaginate(currentPage + 1);
  };

  const onPrevious = () => {
    onPaginate(currentPage - 1);
  };

  return (
    <ButtonGroup size="xs" colorScheme="brand" gap="2px">
      {showFirstButton && (
        <IconButton
          aria-label="Go to first page"
          isDisabled={currentPage <= 1}
          onClick={() => onPaginate(0)}
          variant="old.ghost"
          size="sm"
          icon={<FirstPageIcon aria-label="Go to first page" />}
        />
      )}

      <IconButton
        aria-label="Previous page"
        isDisabled={currentPage <= 1}
        onClick={onPrevious}
        variant="old.ghost"
        size="sm"
        icon={<ChevronLeftIcon aria-label="Previous page" />}
      />

      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <IconBox key={`${DOTS}-${index}`}>
              <DotsHorizontalIcon aria-label="Pages in range" />
            </IconBox>
          );
        }

        const _pageNumber = pageNumber as number;

        return (
          <Button
            key={_pageNumber}
            isDisabled={currentPage === _pageNumber}
            onClick={() => onPaginate(_pageNumber)}
            variant="old.ghost"
            size="sm"
            aria-label={`Go to page: ${_pageNumber}`}
          >
            {_pageNumber}
          </Button>
        );
      })}

      <IconButton
        aria-label="Next page"
        isDisabled={currentPage >= (paginationRange[paginationRange.length - 1] as number)}
        onClick={onNext}
        variant="old.ghost"
        size="sm"
        icon={<ChevronRightIcon aria-label="Next page" />}
      />

      {showLastButton && (
        <IconButton
          aria-label="Go to last page"
          isDisabled={currentPage >= (paginationRange[paginationRange.length - 1] as number)}
          onClick={() => onPaginate(paginationRange[paginationRange.length - 1] as number)}
          variant="old.ghost"
          size="sm"
          icon={<LastPageIcon aria-label="Go to last page" />}
        />
      )}
    </ButtonGroup>
  );
};
