export const lineHeights = {
  px: '1px',
  3: '12px',
  3.5: '14px',
  4: '16px',
  4.5: '18px',
  5: '20px',
  5.5: '22px',
  6: '24px',
  7: '28px',
  7.5: '30px',
  8: '32px',
  9: '36px',
  10: '40px',
  12: '48px',
  15: '60px',
};

export const lineHeightTokens = {
  none: lineHeights.px,
  minimal: lineHeights['3'],
  shorter: lineHeights['4.5'],
  short: lineHeights['5'],
  base: lineHeights['5.5'],
  elevated: lineHeights['6'],
  tall: lineHeights['7.5'],
  taller: lineHeights['9'],
  summit: lineHeights['15'],
};
