/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ShowHideColumnsMenuItems
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/menus/MRT_ShowHideColumnsMenuItems.tsx)
 */

// React & Next
import { useRef, useState } from 'react';
import type { Dispatch, DragEvent, SetStateAction } from 'react';

// 3rd
import {
  Box,
  MenuItem,
  useColorMode,
  FormControl,
  FormLabel,
  Checkbox,
  Badge,
} from '@chakra-ui/react';
import type { MenuItemProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Column, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { theme } from '@/config/theme';
import { Text } from '@/components/atoms/typography';
import { ColumnPinningButtons } from '../buttons/column-pinning-buttons';
import { GrabHandleButton } from '../buttons/grab-handle-button';
import { reorderColumn } from '../column.utils';
import { getTableTheme } from '../style.utils';

interface Props<TData extends MRT_RowData> extends MenuItemProps {
  allColumns: MRT_Column<TData>[];
  column: MRT_Column<TData>;
  hoveredColumn: MRT_Column<TData> | null;
  setHoveredColumn: Dispatch<SetStateAction<MRT_Column<TData> | null>>;
  table: MRT_TableInstance<TData>;
}

export const ShowHideColumnsMenuItems = <TData extends MRT_RowData>({
  allColumns,
  column,
  hoveredColumn,
  setHoveredColumn,
  table,
  ...menuItemProps
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getState,
    options: { enableColumnOrdering, enableColumnPinning, enableHiding },
    setColumnOrder,
  } = table;
  const { columnOrder } = getState();
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const switchChecked =
    (columnDefType !== 'group' && column.getIsVisible()) ||
    (columnDefType === 'group' && column.getLeafColumns().some((col) => col.getIsVisible()));

  const handleToggleColumnHidden = (column: MRT_Column<TData>) => {
    if (columnDefType === 'group') {
      column?.columns?.forEach?.((childColumn: MRT_Column<TData>) => {
        childColumn.toggleVisibility(!switchChecked);
      });
    } else {
      column.toggleVisibility();
    }
  };

  const menuItemRef = useRef<HTMLElement>(null);

  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e: DragEvent<HTMLButtonElement>) => {
    setIsDragging(true);
    e.dataTransfer.setDragImage(menuItemRef.current as HTMLElement, 0, 0);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setHoveredColumn(null);

    if (hoveredColumn) {
      setColumnOrder(reorderColumn(column, hoveredColumn, columnOrder));
    }
  };

  const handleDragEnter = () => {
    if (!isDragging && columnDef.enableColumnOrdering !== false) {
      setHoveredColumn(column);
    }
  };

  if (!columnDef.header) return null;

  return (
    <>
      <MenuItem
        onDragEnter={handleDragEnter}
        ref={menuItemRef}
        {...menuItemProps}
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          my: 0,
          opacity: isDragging ? 0.5 : 1,
          outline: isDragging
            ? `2px dashed ${theme.colors.gray[500]}`
            : hoveredColumn?.id === column.id
              ? `2px dashed ${getTableTheme(table, colorModeContext).draggingBorderColor}`
              : 'none',
          outlineOffset: '-2px',
          pl: `${(column.depth + 0.5) * 16}px`,
          py: '6px',
          ...(menuItemProps?.sx || {}),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '8px',
            w: '100%',
          }}
        >
          {columnDefType !== 'group' &&
            enableColumnOrdering &&
            !allColumns.some((col) => col.columnDef.columnDefType === 'group') &&
            (columnDef.enableColumnOrdering !== false ? (
              <GrabHandleButton
                location="table-menu"
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                table={table}
              />
            ) : (
              <Box sx={{ width: '28px' }} />
            ))}

          {enableColumnPinning &&
            (column.getCanPin() ? (
              <ColumnPinningButtons column={column} table={table} />
            ) : (
              <Box sx={{ width: '70px', minWidth: '70px' }} />
            ))}

          {enableHiding ? (
            <FormControl
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={4}
              // justifyContent="space-between"
              w="100%"
              sx={{ mb: 0, opacity: columnDefType !== 'display' ? 1 : 0.5 }}
            >
              <Checkbox
                id={columnDef.header}
                colorScheme="brand"
                isChecked={switchChecked}
                isDisabled={!column.getCanHide()}
                onChange={() => handleToggleColumnHidden(column)}
              />

              <FormLabel htmlFor={columnDef.header} mb="0" fontSize="14px">
                <Badge colorScheme="brand">{columnDef.header}</Badge>
              </FormLabel>
            </FormControl>
          ) : (
            <Text sx={{ alignSelf: 'center' }}>{columnDef.header}</Text>
          )}
        </Box>
      </MenuItem>

      {column.columns?.map((c: MRT_Column<TData>, i) => (
        <ShowHideColumnsMenuItems
          allColumns={allColumns}
          column={c}
          hoveredColumn={hoveredColumn}
          key={`${i}-${c.id}`}
          setHoveredColumn={setHoveredColumn}
          table={table}
        />
      ))}
    </>
  );
};
