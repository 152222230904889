// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// App - Other
import { small, label } from './text';

// #########
// CHIP BASE
// #########

const baseStyle = defineStyle({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 'xs',
  transition: 'all 120ms ease-in-out',
  w: 'fit-content',
  textTransform: 'none',
});

// #####
// SIZES
// #####

const smSize = defineStyle({
  py: 'xxs',
  px: 'xs',
  borderRadius: 'md',
  ...small,

  '&.with-left-icon': {
    '& svg.chakra-icon': {
      me: 'xxs',
    },
  },

  '&.with-right-icon': {
    '& svg.chakra-icon': {
      ms: 'xxs',
    },
  },

  '&.with-left-icon, &.with-right-icon': {
    '& svg.chakra-icon': {
      w: '10px',
      h: '10px',
    },
  },
});

const mdSize = defineStyle({
  py: 'xxs',
  px: 'sm',
  borderRadius: 'lg',
  ...label,

  '&.with-left-icon': {
    '& svg.chakra-icon': {
      me: 'xs',
    },
  },

  '&.with-right-icon': {
    '& svg.chakra-icon': {
      ms: 'xs',
    },
  },

  '&.with-left-icon, &.with-right-icon': {
    '& svg.chakra-icon': {
      w: '14px',
      h: '14px',
    },
  },
});

// ########
// VARIANTS
// ########

const solidPrimaryVariant = defineStyle({
  bg: 'surface.brand.invert-primary-low-tone',
  color: 'text.brand.primary-high-tone',

  '& .chakra-icon': {
    color: 'surface.brand.primary',
  },

  _hover: {
    bg: 'surface.brand.invert-primary-mid-tone',
    color: 'text.brand.primary-mid-tone',

    '& .chakra-icon': {
      color: 'surface.brand.primary-low-contrast',
    },
  },
});

const solidSecondaryVariant = defineStyle({
  bg: 'surface.brand.secondary-low-tone',
  color: 'text.brand.secondary-high-tone',

  '& .chakra-icon': {
    color: 'surface.brand.secondary',
  },

  _hover: {
    bg: 'surface.brand.secondary-mid-tone',
    color: 'text.brand.secondary-mid-tone',

    '& .chakra-icon': {
      color: 'surface.brand.secondary-low-contrast',
    },
  },
});

const solidErrorVariant = defineStyle({
  bg: 'surface.error-low-tone',
  color: 'text.error',

  '& .chakra-icon': {
    color: 'surface.error',
  },

  _hover: {
    bg: 'surface.error-mid-tone',
    color: 'text.error-low-contrast',

    '& .chakra-icon': {
      color: 'surface.error-low-contrast',
    },
  },
});

const solidDefaultVariant = defineStyle({
  bg: 'surface.secondary',
  color: 'text.secondary',

  _hover: {
    bg: 'surface.high-tone',
  },
});

const dashedPrimaryVariant = defineStyle({
  borderWidth: '1px',
  borderStyle: 'dashed',
  bg: 'transparent',
  borderColor: 'border.brand.primary',
  color: 'text.brand.primary-high-tone',

  '& .chakra-icon': {
    color: 'surface.brand.primary',
  },

  _hover: {
    borderColor: 'border.brand.primary-low-contrast',
    color: 'text.brand.primary-mid-tone',

    '& .chakra-icon': {
      color: 'surface.brand.primary-low-contrast',
    },
  },
});

const dashedSecondaryVariant = defineStyle({
  borderWidth: '1px',
  borderStyle: 'dashed',
  bg: 'transparent',
  borderColor: 'border.brand.secondary-mid-tone',
  color: 'text.brand.secondary-high-tone',

  '& .chakra-icon': {
    color: 'surface.brand.secondary',
  },

  _hover: {
    borderColor: 'border.brand.secondary',
    color: 'text.brand.secondary-mid-tone',

    '& .chakra-icon': {
      color: 'surface.brand.secondary-low-contrast',
    },
  },
});

const dashedEmptyVariant = defineStyle({
  borderWidth: '1px',
  borderStyle: 'dashed',
  bg: 'transparent',
  borderColor: 'border.high-tone',
  color: 'text.mid-tone',

  '& .chakra-icon': {
    color: 'surface.mid-contrast',
  },

  _hover: {
    borderColor: 'border.mid-tone',
    color: 'text.high-tone',

    '& .chakra-icon': {
      color: 'surface.invert-low-tone',
    },
  },
});

const dashedDefaultVariant = defineStyle({
  borderWidth: '1px',
  borderStyle: 'dashed',
  bg: 'transparent',
  borderColor: 'border.high-tone',
  color: 'text.mid-tone',

  _hover: {
    borderColor: 'border.mid-tone',
    color: 'text.high-tone',
  },
});

const badgeTheme = defineStyleConfig({
  baseStyle,
  sizes: { sm: smSize, md: mdSize },
  variants: {
    solid: solidDefaultVariant,
    'solid.primary': solidPrimaryVariant,
    'solid.secondary': solidSecondaryVariant,
    'solid.error': solidErrorVariant,
    dashed: dashedDefaultVariant,
    'dashed.primary': dashedPrimaryVariant,
    'dashed.secondary': dashedSecondaryVariant,
    'dashed.empty': dashedEmptyVariant,
  },
});

export const Badge = badgeTheme;
