// 3rd
import { Box } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Header, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { FilterInput } from './filter-input';

interface Props<TData extends MRT_RowData> extends BoxProps {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const MRT_FilterRangeFields = <TData extends MRT_RowData>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  return (
    <Box
      {...rest}
      // sx={(theme) => ({
      //   display: 'grid',
      //   gap: '16px',
      //   gridTemplateColumns: '1fr 1fr',
      //   ...(parseFromValuesOrFunc(rest?.sx, theme) as any),
      // })}
    >
      <FilterInput header={header} rangeFilterIndex={0} table={table} />

      <FilterInput header={header} rangeFilterIndex={1} table={table} />
    </Box>
  );
};
