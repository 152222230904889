/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ColumnActionMenu
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/menus/MRT_ColumnActionMenu.tsx#L34)
 */

// 3rd
import { Box, MenuList, MenuItem, MenuButton, Flex } from '@chakra-ui/react';
import type { MenuProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Header, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { FilterOptionMenu } from './filter-option-menu';

export const commonMenuItemStyles = {
  alignItems: 'center',
  justifyContent: 'space-between',
  my: 0,
  py: '6px',
};

export const commonListItemStyles = {
  alignItems: 'center',
  display: 'flex',
};

interface Props<TData extends MRT_RowData> extends Partial<MenuProps> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const ColumnActionMenu = <TData extends MRT_RowData>({
  header,
  table, // ...rest
}: Props<TData>) => {
  const {
    getState,
    options: {
      columnFilterDisplayMode,
      // columnFilterModeOptions,
      enableColumnFilterModes,
      enableColumnFilters,
      enableColumnPinning,
      enableColumnResizing,
      enableGrouping,
      enableHiding,
      enableSorting,
      enableSortingRemoval,
      icons: {
        ArrowRightIcon,
        ClearAllIcon,
        DynamicFeedIcon,
        FilterListIcon,
        FilterListOffIcon,
        PushPinIcon,
        RestartAltIcon,
        SortIcon,
        ViewColumnIcon,
        VisibilityOffIcon,
      },
      localization,
      renderColumnActionsMenuItems,
    },
    refs: { filterInputRefs },
    setColumnOrder,
    setColumnSizingInfo,
    setShowColumnFilters,
    toggleAllColumnsVisible,
  } = table;
  const { column } = header;
  const { columnDef } = column;
  const { columnSizing, columnVisibility, showColumnFilters } = getState();
  const columnFilterValue = column.getFilterValue();

  const handleClearSort = () => {
    column.clearSorting();
  };

  const handleSortAsc = () => {
    column.toggleSorting(false);
  };

  const handleSortDesc = () => {
    column.toggleSorting(true);
  };

  const handleResetColumnSize = () => {
    setColumnSizingInfo((old) => ({ ...old, isResizingColumn: false }));
    column.resetSize();
  };

  const handleHideColumn = () => {
    column.toggleVisibility(false);
  };

  const handlePinColumn = (pinDirection: 'left' | 'right' | false) => {
    column.pin(pinDirection);
  };

  const handleGroupByColumn = () => {
    column.toggleGrouping();
    setColumnOrder((old) => ['row-expand', ...old]);
  };

  const handleClearFilter = () => {
    column.setFilterValue('');
  };

  const handleFilterByColumn = () => {
    setShowColumnFilters(true);
    queueMicrotask(() => filterInputRefs.current[`${column.id}-0`]?.focus());
  };

  const handleShowAllColumns = () => {
    toggleAllColumnsVisible(true);
  };

  // const isSelectFilter = !!columnDef.filterSelectOptions;

  // const allowedColumnFilterOptions = columnDef?.columnFilterModeOptions ?? columnFilterModeOptions;

  // const showFilterModeSubMenu =
  //   enableColumnFilterModes &&
  //   columnDef.enableColumnFilterModes !== false &&
  //   !isSelectFilter &&
  //   (allowedColumnFilterOptions === undefined || !!allowedColumnFilterOptions?.length);

  const internalColumnMenuItems = [
    ...(enableSorting && column.getCanSort()
      ? [
          enableSortingRemoval !== false && (
            <MenuItem
              isDisabled={!column.getIsSorted()}
              key={0}
              icon={<ClearAllIcon size="sm" aria-label={localization.clearSort} />}
              onClick={handleClearSort}
              sx={commonMenuItemStyles}
            >
              <Box sx={commonListItemStyles}>{localization.clearSort}</Box>
            </MenuItem>
          ),
          <MenuItem
            isDisabled={column.getIsSorted() === 'asc'}
            key={1}
            onClick={handleSortAsc}
            icon={
              <SortIcon
                aria-label={localization.sortByColumnAsc?.replace(
                  '{column}',
                  String(columnDef.header)
                )}
                size="xs"
                mx="2px"
                style={{ transform: 'rotate(180deg) scaleX(-1)' }}
              />
            }
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>
              {localization.sortByColumnAsc?.replace('{column}', String(columnDef.header))}
            </Box>
          </MenuItem>,
          <MenuItem
            isDisabled={column.getIsSorted() === 'desc'}
            // divider={enableColumnFilters || enableGrouping || enableHiding}
            icon={
              <SortIcon
                aria-label={localization.sortByColumnDesc?.replace(
                  '{column}',
                  String(columnDef.header)
                )}
                size="xs"
                mx="2px"
                style={{ transform: 'rotate(180deg) scaleX(-1)' }}
              />
            }
            key={2}
            onClick={handleSortDesc}
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>
              {localization.sortByColumnDesc?.replace('{column}', String(columnDef.header))}
            </Box>
          </MenuItem>,
        ]
      : []),
    ...(enableColumnFilters && column.getCanFilter()
      ? [
          <MenuItem
            isDisabled={
              !columnFilterValue ||
              (Array.isArray(columnFilterValue) &&
                !columnFilterValue.filter((value) => value).length)
            }
            key={3}
            onClick={handleClearFilter}
            icon={<FilterListOffIcon aria-label={localization.clearFilter} size="xs" mx="2px" />}
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>{localization.clearFilter}</Box>
          </MenuItem>,
          columnFilterDisplayMode === 'subheader' && (
            <FilterOptionMenu
              icon={
                <ArrowRightIcon
                  size="2xs"
                  mx="4px"
                  aria-label={localization.filterByColumn?.replace(
                    '{column}',
                    String(columnDef.header)
                  )}
                />
              }
              key={4}
              label={localization.filterByColumn?.replace('{column}', String(columnDef.header))}
              onSelect={handleFilterByColumn}
              table={table}
              menuButton={
                <MenuButton w="100%">
                  <MenuItem
                    icon={
                      <FilterListIcon
                        aria-label={localization.filterByColumn?.replace(
                          '{column}',
                          String(columnDef.header)
                        )}
                        size="xs"
                        mx="2px"
                      />
                    }
                    isDisabled={showColumnFilters && !enableColumnFilterModes}
                  >
                    <Flex alignItems="center" justifyContent="space-between">
                      <Box sx={commonListItemStyles}>
                        {localization.filterByColumn?.replace('{column}', String(columnDef.header))}
                      </Box>

                      <ArrowRightIcon
                        size="2xs"
                        mx="4px"
                        aria-label={localization.filterByColumn?.replace(
                          '{column}',
                          String(columnDef.header)
                        )}
                      />
                    </Flex>
                  </MenuItem>
                </MenuButton>
              }
            />
            // <MenuItem
            //   isDisabled={showColumnFilters && !enableColumnFilterModes}
            //   // divider={enableGrouping || enableHiding}
            //   key={4}
            //   onClick={showColumnFilters ? handleOpenFilterModeMenu : handleFilterByColumn}
            //   icon={<FilterListIcon />}
            //   sx={commonMenuItemStyles}
            // >
            //   <Box sx={commonListItemStyles}>
            //     {localization.filterByColumn?.replace('{column}', String(columnDef.header))}
            //   </Box>
            //   {showFilterModeSubMenu && (
            //     <IconButton
            //       // aria-label={localization.filterByColumnMode}
            //       aria-label={'localization.filterByColumnMode'}
            //       onClick={handleOpenFilterModeMenu}
            //       onMouseEnter={handleOpenFilterModeMenu}
            //       size="sm"
            //       sx={{ p: 0 }}
            //     >
            //       <ArrowRightIcon />
            //     </IconButton>
            //   )}
            // </MenuItem>
          ),
          // showFilterModeSubMenu && (
          //   // TODO move this inside the input like you did in => global-filter-input.tsx
          //   <FilterOptionMenu
          //     // anchorEl={filterMenuAnchorEl}
          //     header={header}
          //     key={5}
          //     onSelect={handleFilterByColumn}
          //     // setAnchorEl={setFilterMenuAnchorEl}
          //     table={table}
          //   />
          // ),
        ].filter(Boolean)
      : []),
    ...(enableGrouping && column.getCanGroup()
      ? [
          <MenuItem
            // divider={enableColumnPinning}
            key={6}
            icon={
              <DynamicFeedIcon
                aria-label={localization[
                  column.getIsGrouped() ? 'ungroupByColumn' : 'groupByColumn'
                ]?.replace('{column}', String(columnDef.header))}
              />
            }
            onClick={handleGroupByColumn}
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>
              {localization[column.getIsGrouped() ? 'ungroupByColumn' : 'groupByColumn']?.replace(
                '{column}',
                String(columnDef.header)
              )}
            </Box>
          </MenuItem>,
        ]
      : []),
    ...(enableColumnPinning && column.getCanPin()
      ? [
          <MenuItem
            isDisabled={column.getIsPinned() === 'left' || !column.getCanPin()}
            key={7}
            onClick={() => handlePinColumn('left')}
            icon={
              <PushPinIcon
                aria-label={localization.pinToLeft}
                style={{ transform: 'rotate(90deg)' }}
              />
            }
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>{localization.pinToLeft}</Box>
          </MenuItem>,
          <MenuItem
            isDisabled={column.getIsPinned() === 'right' || !column.getCanPin()}
            key={8}
            icon={
              <PushPinIcon
                aria-label={localization.pinToRight}
                style={{ transform: 'rotate(-90deg)' }}
              />
            }
            onClick={() => handlePinColumn('right')}
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>{localization.pinToRight}</Box>
          </MenuItem>,
          <MenuItem
            isDisabled={!column.getIsPinned()}
            // divider={enableHiding}
            key={9}
            icon={<PushPinIcon aria-label={localization.unpin} />}
            onClick={() => handlePinColumn(false)}
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>{localization.unpin}</Box>
          </MenuItem>,
        ]
      : []),
    ...(enableColumnResizing && column.getCanResize()
      ? [
          <MenuItem
            isDisabled={!columnSizing[column.id]}
            key={10}
            onClick={handleResetColumnSize}
            icon={<RestartAltIcon aria-label={localization.resetColumnSize} />}
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>{localization.resetColumnSize}</Box>
          </MenuItem>,
        ]
      : []),
    ...(enableHiding
      ? [
          <MenuItem
            isDisabled={!column.getCanHide()}
            key={11}
            onClick={handleHideColumn}
            icon={
              <VisibilityOffIcon
                aria-label={localization.hideColumn?.replace('{column}', String(columnDef.header))}
                size="xs"
                mx="2px"
              />
            }
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>
              {localization.hideColumn?.replace('{column}', String(columnDef.header))}
            </Box>
          </MenuItem>,
          <MenuItem
            isDisabled={!Object.values(columnVisibility).filter((visible) => !visible).length}
            key={12}
            onClick={handleShowAllColumns}
            icon={
              <ViewColumnIcon
                size="sm"
                aria-label={localization.showAllColumns?.replace(
                  '{column}',
                  String(columnDef.header)
                )}
              />
            }
            sx={commonMenuItemStyles}
          >
            <Box sx={commonListItemStyles}>
              {localization.showAllColumns?.replace('{column}', String(columnDef.header))}
            </Box>
          </MenuItem>,
        ]
      : []),
  ].filter(Boolean);

  return (
    <MenuList>
      {columnDef.renderColumnActionsMenuItems?.({
        column,
        internalColumnMenuItems,
        table,
      }) ??
        renderColumnActionsMenuItems?.({
          column,
          internalColumnMenuItems,
          table,
        }) ??
        internalColumnMenuItems}
    </MenuList>
  );
};
