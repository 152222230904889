// 3rd
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CollectionStat } from '../types/collection';
import {
  castCollectionStatDtoToCollectionStat,
  ZodCollectionStatDto,
} from './dtos/collection-stat';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { COLLECTIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  collectionsStats: z.array(ZodCollectionStatDto).default([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetCollectionsStatsResponseDto };
export type { ResponseDto as GetCollectionsStatsResponseDto };

// #######
// Request
// #######

export const getCollectionsStats = async (): Promise<CollectionStat[]> => {
  try {
    const res = await apiClient.get(`/Collection/ListCollectionStats`);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.collectionsStats.map(castCollectionStatDtoToCollectionStat);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCollectionsStats = {
  enabled?: boolean;
};

export const useCollectionsStats = ({ enabled = true }: UseCollectionsStats = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: COLLECTIONS_QUERY_KEYS.collectionsStats(),
    queryFn: async () => await getCollectionsStats(),
    enabled: enabled,
  });

  return {
    collectionsStats: data,
    isFetchingCollectionsStats: isLoading || isFetching,
    didFetchingCollectionsStatsErrored: isLoadingError,
    isCollectionsStatsAvailable: !isLoading && !isFetching && !isLoadingError,
  };
};
