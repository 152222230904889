/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableLoadingOverlay
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/table/MRT_TableLoadingOverlay.tsx#L14)
 */

// 3rd
import { Box, CircularProgress, type CircularProgressProps, useColorMode } from '@chakra-ui/react';

// App
import { alpha } from '@/utils/color';
import { type MRT_RowData, type MRT_TableInstance } from '../types';
import { parseFromValuesOrFunc } from '../column.utils';
import { getTableTheme } from '../style.utils';

interface Props<TData extends MRT_RowData> extends CircularProgressProps {
  table: MRT_TableInstance<TData>;
}

export const TableLoadingOverlay = <TData extends MRT_RowData>({
  table,
  ...circularProgressProps
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    options: { localization, circularProgressProps: tableCircularProgressProps },
  } = table;

  const _circularProgressProps = {
    ...parseFromValuesOrFunc(tableCircularProgressProps, { table }),
    ...circularProgressProps,
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: alpha(getTableTheme(table, colorModeContext).baseBackgroundColor, 0.5),
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        maxHeight: '100vh',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 2,
      }}
    >
      <CircularProgress
        aria-label={localization.noRecordsToDisplay}
        id="progress"
        isIndeterminate
        {..._circularProgressProps}
      />
    </Box>
  );
};
