// React & Next
import { useMemo } from 'react';

// 3rd
import { Skeleton, forwardRef } from '@chakra-ui/react';
import type { ComponentWithAs, As, StyleProps } from '@chakra-ui/react';

// App - Types
import type { Collection, CollectionStat } from '../../types/collection';

// App - Other
import Locale from '@/locale/en.json';
import { Select } from '@/components/molecules/form';
import { translateKnowledgeSource } from '@/components/translators/integration/knowledge-source';

const locale = Locale.features.collections['collections-select-collection'];

type CollectionsSelectCollectionComponent = ComponentWithAs<
  As,
  CollectionsSelectCollectionProps
> & {
  Loading: typeof Loading;
};

type CollectionsSelectCollectionProps = StyleProps & {
  collections: Collection[] | CollectionStat[];
  includeDefaultOption?: boolean | string;
  placeholder?: string;
  selected?: Array<Collection | CollectionStat>;
  onChange: (collections?: Array<Collection | CollectionStat>) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  treatGoogleDriveAsOneDrive?: boolean;
  controlShouldRenderValue?: boolean;
};

export const CollectionsSelectCollection = forwardRef(
  (
    {
      collections,
      includeDefaultOption,
      placeholder,
      selected,
      onChange,
      onBlur,
      isDisabled,
      treatGoogleDriveAsOneDrive,
      controlShouldRenderValue = true,
      ...props
    }: CollectionsSelectCollectionProps,
    ref
  ) => {
    const defaultOption = useMemo(
      () => ({
        value: '',
        label:
          typeof includeDefaultOption === 'string'
            ? includeDefaultOption
            : locale['Select collection'],
      }),
      [includeDefaultOption]
    );

    const options = useMemo(() => {
      const collectionOptions = collections
        .map((collection) => {
          return {
            value: collection.id,
            label: `(${translateKnowledgeSource(collection.source, treatGoogleDriveAsOneDrive)}) ${collection.name}`,
          };
        })
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
          })
        );

      if (!!includeDefaultOption && collectionOptions.length > 1)
        return [defaultOption, ...collectionOptions];

      return collectionOptions;
    }, [collections, defaultOption, includeDefaultOption, treatGoogleDriveAsOneDrive]);

    const selectedOptions = useMemo(() => {
      return (selected || []).map((collection) => {
        // const sourceIcon = createElement(translateKnowledgeSourceToIcon(collection.source), {
        //   size: 'xs',
        //   'aria-label': locale['Collection’s source'],
        // });

        return {
          value: collection.id,
          label: `(${translateKnowledgeSource(collection.source, treatGoogleDriveAsOneDrive)}) ${collection.name}`,
        };
      });
    }, [selected, treatGoogleDriveAsOneDrive]);

    return (
      <Select
        useBasicStyles
        variant="outline"
        size="sm"
        isMulti
        isDisabled={isDisabled}
        options={options}
        value={selectedOptions}
        selectedOptionColor="surface.brand.primary"
        placeholder={placeholder}
        onBlur={onBlur}
        controlShouldRenderValue={controlShouldRenderValue}
        onChange={(options) => {
          if (!options || !options.length) {
            return onChange([]);
          }

          const selectedCollections = options.flatMap((option) => {
            const collection = collections.find((c) => c.id === option.value);

            return collection ? [collection] : [];
          });

          return onChange(selectedCollections);
        }}
        ref={ref}
        closeMenuOnSelect={true}
        hideSelectedOptions={true}
        isClearable={false}
        chakraStyles={{
          container: (styles) => ({ ...styles, ...props }),
          menuList: (styles) => ({ ...styles, ...props }),
        }}
      />
    );
  }
) as CollectionsSelectCollectionComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

CollectionsSelectCollection.Loading = Loading;
