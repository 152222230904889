/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ToggleRowActionMenuButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_ToggleRowActionMenuButton.tsx#L31)
 */

// React & Next
import type { MouseEvent } from 'react';

// 3rd
import { Menu, MenuButton } from '@chakra-ui/react';

// App - Types
import type { MRT_Cell, MRT_Row, MRT_RowData, MRT_TableInstance } from '../types';
import type { IconButtonProps } from '@/components/molecules/button';

// App - Other
import { IconButton } from '@/components/molecules/button';
import { parseFromValuesOrFunc } from '../column.utils';
import { RowActionMenu } from '../menus/row-action-menu';
import { EditActionButtons } from './edit-action-buttons';

const commonIconButtonStyles = {
  // '&:hover': {
  //   opacity: 1,
  // },
  // height: '16px',
  ml: '10px',
  // opacity: 0.5,
  transition: 'opacity 150ms',
  // width: '16px',
};

interface Props<TData extends MRT_RowData> extends Omit<Partial<IconButtonProps>, 'aria-label'> {
  cell: MRT_Cell<TData>;
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}

export const ToggleRowActionMenuButton = <TData extends MRT_RowData>({
  cell,
  row,
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      createDisplayMode,
      editDisplayMode,
      enableEditing,
      icons: { EditIcon, MoreHorizIcon },
      localization,
      renderRowActionMenuItems,
      renderRowActions,
    },
    setEditingRow,
  } = table;

  const { creatingRow, editingRow } = getState();

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;

  const showEditActionButtons =
    (isCreating && createDisplayMode === 'row') || (isEditing && editDisplayMode === 'row');

  const handleStartEditMode = (event: MouseEvent) => {
    event.stopPropagation();
    setEditingRow({ ...row });
  };

  return (
    <>
      {renderRowActions && !showEditActionButtons ? (
        renderRowActions({ cell, row, table })
      ) : showEditActionButtons ? (
        <EditActionButtons row={row} table={table} />
      ) : !renderRowActionMenuItems &&
        parseFromValuesOrFunc(enableEditing, row) &&
        ['modal', 'row'].includes(editDisplayMode!) ? (
        <IconButton
          onClick={handleStartEditMode}
          sx={commonIconButtonStyles}
          {...iconButtonProps}
          aria-label={localization.edit}
          icon={<EditIcon aria-label={localization.edit} />}
          variant="old.outline"
          size="sm"
        />
      ) : renderRowActionMenuItems ? (
        // INSIGHT - Notice that we need computePositionOnMount={true} otherwise we might get scroll on table if the menu has many items
        <Menu preventOverflow={true} placement="left" computePositionOnMount={true}>
          <MenuButton
            as={IconButton}
            variant="old.ghost"
            size="md"
            // sx={commonIconButtonStyles}
            sx={{
              ml: '5px',
            }}
            {...iconButtonProps}
            aria-label={localization.rowActions}
            onClick={(e) => e.stopPropagation()}
            icon={<MoreHorizIcon color="text.high-tone" aria-label={localization.rowActions} />}
          />

          <RowActionMenu handleEdit={handleStartEditMode} row={row} table={table} />
        </Menu>
      ) : null}
    </>
  );
};
