/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ExpandButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_ExpandButton.tsx#L16)
 */

// React & Next
import type { MouseEvent } from 'react';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_Row, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { IconButton } from '@/components/molecules/button';
import { parseFromValuesOrFunc } from '../column.utils';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}

export const ExpandButton = <TData extends MRT_RowData>({ row, table }: Props<TData>) => {
  const {
    options: {
      icons: { ExpandMoreIcon },
      localization,
      expandButtonProps,
      renderDetailPanel,
    },
  } = table;

  const _iconButtonProps = parseFromValuesOrFunc(expandButtonProps, {
    row,
    table,
  });

  const canExpand = row.getCanExpand();
  const isExpanded = row.getIsExpanded();
  const detailPanel = !!renderDetailPanel?.({ row, table });

  const handleToggleExpand = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    row.toggleExpanded();
    _iconButtonProps?.onClick?.(event);
  };

  // INSIGHT line below is my addition
  if (!canExpand && !detailPanel) return null;

  return (
    <IconButton
      aria-label={localization.expand}
      isDisabled={!canExpand && !renderDetailPanel}
      {..._iconButtonProps}
      variant="circular"
      size="sm"
      icon={
        <ExpandMoreIcon
          transform={`rotate(${!canExpand && !renderDetailPanel ? -90 : isExpanded ? -180 : 0}deg)`}
          transition="transform 150ms"
          aria-label={localization.expand}
        />
      }
      onClick={handleToggleExpand}
      sx={{
        opacity: !canExpand && !renderDetailPanel ? 0.3 : 1,
        ...(_iconButtonProps?.sx || {}),
      }}
    />
  );
};
