/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_GrabHandleButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_GrabHandleButton.tsx)
 */

// React & Next
import type { DragEventHandler } from 'react';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { IconButton } from '@/components/molecules/button';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  location?: 'column' | 'row' | 'table-menu';
  onDragEnd: DragEventHandler<HTMLButtonElement>;
  onDragStart: DragEventHandler<HTMLButtonElement>;
  table: MRT_TableInstance<TData>;
}

export const GrabHandleButton = <TData extends MRT_RowData>({
  location,
  onDragEnd,
  onDragStart,
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    options: {
      icons: { DragHandleIcon },
      localization,
    },
  } = table;

  return (
    <IconButton
      aria-label={iconButtonProps.title ?? localization.move}
      draggable="true"
      size="sm"
      variant={location === 'column' ? 'ghost' : 'outline'}
      {...iconButtonProps}
      onClick={(e) => {
        e.stopPropagation();
        iconButtonProps?.onClick?.(e);
      }}
      icon={
        <DragHandleIcon
          aria-label={iconButtonProps.title ?? localization.move}
          color="text.high-tone"
        />
      }
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      sx={{
        _active: {
          cursor: 'grabbing',
        },
        _hover: {
          backgroundColor: 'transparent',
          opacity: 0.5,
        },
        cursor: 'grab',
        m: '0 -1.6px',
        opacity: location === 'column' ? 1 : 1,
        fontSize: location === 'column' ? '16px' : 'inherit',
        // p: '2px',
        transition: 'all 150ms ease-in-out',
        ...(iconButtonProps?.sx || {}),
      }}
    />
  );
};
