/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHeadRow
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHeadRow.tsx#L22)
 */

// 3rd
import { type VirtualItem } from '@tanstack/react-virtual';
import { Tr, Th, type TableRowProps, useColorMode } from '@chakra-ui/react';

// App
import { TableHeadCell } from '../head-cell/table-head-cell';
import { getTableTheme } from '../../style.utils';
import { parseFromValuesOrFunc } from '../../column.utils';
import {
  type MRT_Header,
  type MRT_HeaderGroup,
  type MRT_RowData,
  type MRT_TableInstance,
} from '../../types';

interface Props<TData extends MRT_RowData> extends TableRowProps {
  headerGroup: MRT_HeaderGroup<TData>;
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}

export const TableHeadRow = <TData extends MRT_RowData>({
  headerGroup,
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...rest
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    options: { layoutMode, tableHeadRowProps },
  } = table;

  const tableRowProps = {
    ...parseFromValuesOrFunc(tableHeadRowProps, {
      headerGroup,
      table,
    }),
    ...rest,
  };

  return (
    <Tr
      {...tableRowProps}
      sx={{
        backgroundColor: getTableTheme(table, colorModeContext).baseHeadRowColor,
        // padding: '0 12px',
        borderBottom: `2px solid ${getTableTheme(table, colorModeContext).baseHeadRowColor}`,
        alignItems: 'center',
        display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
        top: 0,
        ...(tableRowProps?.sx || {}),
      }}
    >
      {virtualPaddingLeft ? <Th style={{ display: 'flex', width: virtualPaddingLeft }} /> : null}

      {(virtualColumns ?? headerGroup.headers).map((headerOrVirtualHeader) => {
        const header = virtualColumns
          ? headerGroup.headers[headerOrVirtualHeader.index]
          : (headerOrVirtualHeader as MRT_Header<TData>);

        return header ? <TableHeadCell header={header} key={header.id} table={table} /> : null;
      })}

      {virtualPaddingRight ? <Th style={{ display: 'flex', width: virtualPaddingRight }} /> : null}
    </Tr>
  );
};
