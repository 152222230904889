export const fontWeights = {
  // body: 400,
  // heading: 500,
  // 'light-heading': 400,
  // 'state-title': 500,
  // 'drawer-header': 500,
  // 'page-header': 500,
  //
  // hairline: { value: 100 },
  // thin: { value: 200 },
  // light: { value: 300 },
  // normal: { value: 400 },
  // medium: { value: 500 },
  // semibold: { value: 600 },
  // bold: { value: 700 },
  // extrabold: { value: 800 },
  // black: { value: 900 },
  //
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

export const fontWeightTokens = {
  hairline: fontWeights.hairline,
  thin: fontWeights.thin,
  light: fontWeights.light,
  normal: fontWeights.normal,
  medium: fontWeights.medium,
  semibold: fontWeights.semibold,
  bold: fontWeights.bold,
  extrabold: fontWeights.extrabold,
  black: fontWeights.black,
};
