/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableFooterRow
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/footer/MRT_TableFooterRow.tsx#L21)
 */

// 3rd
import { type VirtualItem } from '@tanstack/react-virtual';
import { Tr, useColorMode, type TableRowProps } from '@chakra-ui/react';

// App
import { TableFooterCell } from './table-footer-cell';
import { parseFromValuesOrFunc } from '../column.utils';
import { getTableTheme } from '../style.utils';
import {
  type MRT_Header,
  type MRT_HeaderGroup,
  type MRT_RowData,
  type MRT_TableInstance,
} from '../types';

interface Props<TData extends MRT_RowData> extends TableRowProps {
  footerGroup: MRT_HeaderGroup<TData>;
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}

export const TableFooterRow = <TData extends MRT_RowData>({
  footerGroup,
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  ...tableRowProps
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    options: { layoutMode, tableFooterRowProps },
  } = table;

  // if no content in row, skip row
  if (
    !footerGroup.headers?.some(
      (header) =>
        (typeof header.column.columnDef.footer === 'string' && !!header.column.columnDef.footer) ||
        header.column.columnDef.Footer
    )
  ) {
    return null;
  }

  const _tableRowProps = {
    ...parseFromValuesOrFunc(tableFooterRowProps, {
      footerGroup,
      table,
    }),
    ...tableRowProps,
  };

  const { baseHeadRowColor } = getTableTheme(table, colorModeContext);

  return (
    <Tr
      {..._tableRowProps}
      sx={{
        backgroundColor: getTableTheme(table, colorModeContext).baseBackgroundColor,
        borderBottom: `2px solid ${baseHeadRowColor}`,
        display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
        width: '100%',
        ...(_tableRowProps?.sx || {}),
      }}
    >
      {virtualPaddingLeft ? <th style={{ display: 'flex', width: virtualPaddingLeft }} /> : null}

      {(virtualColumns ?? footerGroup.headers).map((footerOrVirtualFooter) => {
        const footer = virtualColumns
          ? footerGroup.headers[footerOrVirtualFooter.index]
          : (footerOrVirtualFooter as MRT_Header<TData>);

        return footer ? <TableFooterCell footer={footer} key={footer.id} table={table} /> : null;
      })}

      {virtualPaddingRight ? <th style={{ display: 'flex', width: virtualPaddingRight }} /> : null}
    </Tr>
  );
};
