/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_EditActionButtons
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_EditActionButtons.tsx#L19)
 */

// 3rd
import { Box } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';
import type { DeepKeys } from '@tanstack/react-table';

// App - Types
import type { LiteralUnion, MRT_Row, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { Button, IconButton } from '@/components/molecules/button';

interface Props<TData extends MRT_RowData> extends BoxProps {
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
  variant?: 'icon' | 'text';
}

export const EditActionButtons = <TData extends MRT_RowData>({
  row,
  table,
  variant = 'icon',
  ...boxProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { CancelIcon, SaveIcon },
      localization,
      onCreatingRowCancel,
      onCreatingRowSave,
      onEditingRowCancel,
      onEditingRowSave,
    },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingRow,
  } = table;
  const { creatingRow, editingRow, isSaving } = getState();

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;

  const handleCancel = () => {
    if (isCreating) {
      onCreatingRowCancel?.({ row, table });
      setCreatingRow(null);
    } else if (isEditing) {
      onEditingRowCancel?.({ row, table });
      setEditingRow(null);
    }

    row._valuesCache = {} as Record<LiteralUnion<string & DeepKeys<TData>>, unknown>; // reset values cache
  };

  const handleSubmitRow = () => {
    // look for auto-filled input values
    Object.values(editInputRefs?.current)
      .filter((inputRef) => row.id === inputRef?.name?.split('_')?.[0])
      ?.forEach((input) => {
        if (input.value !== undefined && Object.hasOwn(row?._valuesCache as object, input.name)) {
          (row._valuesCache as { [key: string]: unknown })[input.name] = input.value;
        }
      });
    if (isCreating)
      onCreatingRowSave?.({
        exitCreatingMode: () => setCreatingRow(null),
        row,
        table,
        values: row._valuesCache,
      });
    else if (isEditing) {
      onEditingRowSave?.({
        exitEditingMode: () => setEditingRow(null),
        row,
        table,
        values: row?._valuesCache,
      });
    }
  };

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      sx={{
        display: 'flex',
        gap: '12px',
        ...(boxProps?.sx || {}),
      }}
    >
      {variant === 'icon' ? (
        <>
          <IconButton
            aria-label={localization.cancel}
            onClick={handleCancel}
            icon={<CancelIcon aria-label={localization.cancel} />}
            variant="old.ghost"
            size="sm"
          />

          <IconButton
            aria-label={localization.save}
            onClick={handleSubmitRow}
            isLoading={isSaving}
            icon={<SaveIcon aria-label={localization.save} />}
            variant="old.solid"
            size="sm"
          />
        </>
      ) : (
        <>
          <Button
            onClick={handleCancel}
            sx={{ minWidth: '100px' }}
            aria-label={localization.cancel}
            variant="old.ghost"
          >
            {localization.cancel}
          </Button>

          <Button
            onClick={handleSubmitRow}
            sx={{ minWidth: '100px' }}
            variant="old.solid"
            aria-label={localization.save}
            isLoading={isSaving}
          >
            {localization.save}
          </Button>
        </>
      )}
    </Box>
  );
};
