/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_EditRowModal
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/modals/MRT_EditRowModal.tsx#L20)
 */

// React & Next
import { useRef } from 'react';

// 3rd
import { type DeepKeys } from '@tanstack/react-table';
import {
  Stack,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  type AlertDialogProps,
} from '@chakra-ui/react';

// App
import { EditActionButtons } from '../buttons/edit-action-buttons';
import { EditCellInput } from '../inputs/edit-cell-input';
import { parseFromValuesOrFunc } from '../column.utils';
import { LiteralUnion, type MRT_Row, type MRT_RowData, type MRT_TableInstance } from '../types';

interface Props<TData extends MRT_RowData> extends Partial<AlertDialogProps> {
  open: boolean;
  table: MRT_TableInstance<TData>;
}

export const EditRowModal = <TData extends MRT_RowData>({
  open,
  table,
  ...alertDialogProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      localization,
      createRowModalProps,
      editRowDialogProps,
      onCreatingRowCancel,
      onEditingRowCancel,
      renderCreateRowDialogContent,
      renderEditRowDialogContent,
    },
    setCreatingRow,
    setEditingRow,
  } = table;
  const { creatingRow, editingRow } = getState();
  const row = (creatingRow ?? editingRow) as MRT_Row<TData>;
  const cancelRef = useRef<HTMLButtonElement>(null);

  const _alertDialogProps = {
    ...parseFromValuesOrFunc(editRowDialogProps, { row, table }),
    ...(creatingRow && parseFromValuesOrFunc(createRowModalProps, { row, table })),
    ...alertDialogProps,
  };

  const internalEditComponents = row
    .getAllCells()
    .filter((cell) => cell.column.columnDef.columnDefType === 'data')
    .map((cell) => <EditCellInput cell={cell} key={cell.id} table={table} />);

  return (
    <AlertDialog
      onClose={() => {
        if (creatingRow) {
          onCreatingRowCancel?.({ row, table });
          setCreatingRow(null);
        } else {
          onEditingRowCancel?.({ row, table });
          setEditingRow(null);
        }

        row._valuesCache = {} as Record<LiteralUnion<string & DeepKeys<TData>>, unknown>; // reset values cache
        _alertDialogProps.onClose?.();
      }}
      leastDestructiveRef={cancelRef}
      isOpen={open}
      {..._alertDialogProps}
    >
      {((creatingRow &&
        renderCreateRowDialogContent?.({
          internalEditComponents,
          row,
          table,
        })) ||
        renderEditRowDialogContent?.({
          internalEditComponents,
          row,
          table,
        })) ?? (
        <AlertDialogContent w="100%" maxWidth="xs">
          <AlertDialogHeader sx={{ textAlign: 'center' }}>{localization.edit}</AlertDialogHeader>

          <AlertDialogCloseButton ref={cancelRef} />

          <AlertDialogBody>
            <form onSubmit={(e) => e.preventDefault()}>
              <Stack
                sx={{
                  gap: '32px',
                  paddingTop: '16px',
                  width: '100%',
                }}
              >
                {internalEditComponents}
              </Stack>
            </form>
          </AlertDialogBody>

          <AlertDialogFooter sx={{ p: '20px' }}>
            <EditActionButtons row={row} table={table} variant="text" />
          </AlertDialogFooter>
        </AlertDialogContent>
      )}
    </AlertDialog>
  );
};
