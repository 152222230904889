/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: useMRT_TableInstance
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/hooks/useMRT_TableInstance.ts#L41)
 */

// React
import { useMemo, useRef, useState, MutableRefObject } from 'react';

// 3rd
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

// App
import {
  createRow,
  getAllLeafColumnDefs,
  getColumnId,
  getDefaultColumnFilterFn,
  getDefaultColumnOrderIds,
  prepareColumns,
} from '../column.utils';
import {
  type MRT_Cell,
  type MRT_Column,
  type MRT_ColumnDef,
  type ColumnFilterFnsState,
  type ColumnOrderState,
  type MRT_DefinedTableOptions,
  type MRT_FilterOption,
  type GroupingState,
  type MRT_Row,
  type MRT_RowData,
  type MRT_TableInstance,
  type MRT_TableOptions,
  type MRT_TableState,
  type Updater,
} from '../types';
import { useTableDisplayColumns } from './useTableDisplayColumns';
import { useTableEffects } from './useTableEffects';

export const useTableInstance: <TData extends MRT_RowData>(
  tableOptions: MRT_DefinedTableOptions<TData>
) => MRT_TableInstance<TData> = <TData extends MRT_RowData>(
  tableOptions: MRT_DefinedTableOptions<TData>
) => {
  const bottomToolbarRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
  const editInputRefs = useRef<Record<string, HTMLInputElement>>({}) as MutableRefObject<
    Record<string, HTMLInputElement>
  >;
  const filterInputRefs = useRef<Record<string, HTMLInputElement>>({}) as MutableRefObject<
    Record<string, HTMLInputElement>
  >;
  const searchInputRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const tableContainerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
  const tableHeadCellRefs = useRef<Record<string, HTMLTableCellElement>>({}) as MutableRefObject<
    Record<string, HTMLTableCellElement>
  >;
  const tableBoxRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
  const topToolbarRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
  const tableHeadRef = useRef<HTMLTableSectionElement>(
    null
  ) as MutableRefObject<HTMLTableSectionElement>;
  const tableFooterRef = useRef<HTMLTableSectionElement>(
    null
  ) as MutableRefObject<HTMLTableSectionElement>;

  const initialState: Partial<MRT_TableState<TData>> = useMemo(
    () => {
      const initState = tableOptions.initialState ?? {};

      initState.columnOrder =
        initState.columnOrder ?? getDefaultColumnOrderIds(tableOptions as MRT_TableOptions<TData>);
      initState.globalFilterFn = tableOptions.globalFilterFn ?? 'fuzzy';

      return initState;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [creatingRow, _setCreatingRow] = useState<MRT_Row<TData> | null>(
    initialState.creatingRow ?? null
  );
  const [columnFilterFns, setColumnFilterFns] = useState<ColumnFilterFnsState>(() => {
    return Object.assign(
      {},
      ...getAllLeafColumnDefs(tableOptions.columns as MRT_ColumnDef<TData>[]).map((col) => ({
        [getColumnId(col)]:
          col.filterFn instanceof Function
            ? col.filterFn.name ?? 'custom'
            : col.filterFn ??
              initialState?.columnFilterFns?.[getColumnId(col)] ??
              getDefaultColumnFilterFn(col),
      }))
    );
  });
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(initialState.columnOrder ?? []);
  const [draggingColumn, setDraggingColumn] = useState<MRT_Column<TData> | null>(
    initialState.draggingColumn ?? null
  );
  const [draggingRow, setDraggingRow] = useState<MRT_Row<TData> | null>(
    initialState.draggingRow ?? null
  );
  const [editingCell, setEditingCell] = useState<MRT_Cell<TData> | null>(
    initialState.editingCell ?? null
  );
  const [editingRow, setEditingRow] = useState<MRT_Row<TData> | null>(
    initialState.editingRow ?? null
  );
  const [globalFilterFn, setGlobalFilterFn] = useState<MRT_FilterOption>(
    initialState.globalFilterFn ?? 'fuzzy'
  );
  const [grouping, setGrouping] = useState<GroupingState>(initialState.grouping ?? []);
  const [hoveredColumn, setHoveredColumn] = useState<Partial<MRT_Column<TData>> | null>(
    initialState.hoveredColumn ?? null
  );
  const [hoveredRow, setHoveredRow] = useState<Partial<MRT_Row<TData>> | null>(
    initialState.hoveredRow ?? null
  );
  const [showAlertBanner, setShowAlertBanner] = useState<boolean>(
    tableOptions.initialState?.showAlertBanner ?? false
  );
  const [showColumnFilters, setShowColumnFilters] = useState<boolean>(
    initialState?.showColumnFilters ?? false
  );
  const [showGlobalFilter, setShowGlobalFilter] = useState<boolean>(
    initialState?.showGlobalFilter ?? false
  );
  const [showToolbarDropZone, setShowToolbarDropZone] = useState<boolean>(
    initialState?.showToolbarDropZone ?? false
  );

  const displayColumns = useTableDisplayColumns({
    columnOrder,
    creatingRow,
    grouping,
    tableOptions,
  });

  const columnDefs = useMemo(
    () =>
      prepareColumns({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        aggregationFns: tableOptions.aggregationFns as any,
        columnDefs: [...displayColumns, ...tableOptions.columns] as MRT_ColumnDef<TData>[],
        columnFilterFns: tableOptions.state?.columnFilterFns ?? columnFilterFns,
        defaultDisplayColumn: tableOptions.defaultDisplayColumn ?? {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filterFns: tableOptions.filterFns as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortingFns: tableOptions.sortingFns as any,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnFilterFns, displayColumns, tableOptions.columns, tableOptions.state?.columnFilterFns]
  );

  const data: TData[] = useMemo(
    () =>
      (tableOptions.state?.isLoading || tableOptions.state?.showSkeletons) &&
      !tableOptions.data.length
        ? [
            ...Array(
              tableOptions.state?.pagination?.pageSize || initialState?.pagination?.pageSize || 10
            ).fill(null),
          ].map(() => {
            return Object.assign(
              {},
              ...getAllLeafColumnDefs(tableOptions.columns).map((col) => ({
                [getColumnId(col)]: null,
              }))
            );
          })
        : tableOptions.data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableOptions.data, tableOptions.state?.isLoading, tableOptions.state?.showSkeletons]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel:
      tableOptions.enableExpanding || tableOptions.enableGrouping
        ? getExpandedRowModel()
        : undefined,
    getFacetedMinMaxValues: tableOptions.enableFacetedValues ? getFacetedMinMaxValues() : undefined,
    getFacetedRowModel: tableOptions.enableFacetedValues ? getFacetedRowModel() : undefined,
    getFacetedUniqueValues: tableOptions.enableFacetedValues ? getFacetedUniqueValues() : undefined,
    getFilteredRowModel:
      tableOptions.enableColumnFilters ||
      tableOptions.enableGlobalFilter ||
      tableOptions.enableFilters
        ? getFilteredRowModel()
        : undefined,
    getGroupedRowModel: tableOptions.enableGrouping ? getGroupedRowModel() : undefined,
    getPaginationRowModel: tableOptions.enablePagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: tableOptions.enableSorting ? getSortedRowModel() : undefined,
    getSubRows: (row) => row?.subRows,
    onColumnOrderChange: setColumnOrder,
    onGroupingChange: setGrouping,
    ...tableOptions,
    columns: columnDefs as ColumnDef<TData>[],
    data,
    globalFilterFn: tableOptions.filterFns?.[globalFilterFn ?? 'fuzzy'],
    initialState,
    state: {
      columnFilterFns,
      columnOrder,
      creatingRow,
      draggingColumn,
      draggingRow,
      editingCell,
      editingRow,
      globalFilterFn,
      grouping,
      hoveredColumn,
      hoveredRow,
      showAlertBanner,
      showColumnFilters,
      showGlobalFilter,
      showToolbarDropZone,
      ...tableOptions.state,
    },
  }) as MRT_TableInstance<TData>;

  table.refs = {
    bottomToolbarRef,
    editInputRefs,
    filterInputRefs,
    searchInputRef,
    tableContainerRef,
    tableFooterRef,
    tableHeadCellRefs,
    tableHeadRef,
    tableBoxRef,
    topToolbarRef,
  };

  const setCreatingRow = (row: Updater<MRT_Row<TData> | null | true>) => {
    let _row = row;
    if (row === true) {
      _row = createRow(table);
    }
    tableOptions?.onCreatingRowChange?.(_row as MRT_Row<TData> | null) ??
      _setCreatingRow(_row as MRT_Row<TData> | null);
  };

  table.setCreatingRow = setCreatingRow;
  table.setColumnFilterFns = tableOptions.onColumnFilterFnsChange ?? setColumnFilterFns;
  table.setDraggingColumn = tableOptions.onDraggingColumnChange ?? setDraggingColumn;
  table.setDraggingRow = tableOptions.onDraggingRowChange ?? setDraggingRow;
  table.setEditingCell = tableOptions.onEditingCellChange ?? setEditingCell;
  table.setEditingRow = tableOptions.onEditingRowChange ?? setEditingRow;
  table.setGlobalFilterFn = tableOptions.onGlobalFilterFnChange ?? setGlobalFilterFn;
  table.setHoveredColumn = tableOptions.onHoveredColumnChange ?? setHoveredColumn;
  table.setHoveredRow = tableOptions.onHoveredRowChange ?? setHoveredRow;
  table.setShowAlertBanner = tableOptions.onShowAlertBannerChange ?? setShowAlertBanner;
  table.setShowColumnFilters = tableOptions.onShowColumnFiltersChange ?? setShowColumnFilters;
  table.setShowGlobalFilter = tableOptions.onShowGlobalFilterChange ?? setShowGlobalFilter;
  table.setShowToolbarDropZone = tableOptions.onShowToolbarDropZoneChange ?? setShowToolbarDropZone;

  useTableEffects(table);

  return table;
};
