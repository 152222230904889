/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_SelectCheckbox
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/inputs/MRT_SelectCheckbox.tsx#L19)
 */

// 3rd
import { Radio } from '@chakra-ui/react';
import type { RadioProps } from '@chakra-ui/react';

// App - Types
import type { CheckboxProps } from '@/components/molecules/form';
import type { MRT_Row, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { Checkbox } from '@/components/molecules/form';
import { parseFromValuesOrFunc } from '../column.utils';

interface Props<TData extends MRT_RowData> extends CheckboxProps {
  row?: MRT_Row<TData>;
  selectAll?: boolean;
  table: MRT_TableInstance<TData>;
}

export const SelectCheckbox = <TData extends MRT_RowData>({
  row,
  selectAll,
  table,
  ...checkboxProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      enableMultiRowSelection,
      enableRowPinning,
      localization,
      selectAllCheckboxProps,
      selectCheckboxProps,
      rowPinningDisplayMode,
      selectAllMode,
    },
  } = table;
  const { isLoading } = getState();
  const _checkboxProps = {
    ...(!row
      ? parseFromValuesOrFunc(selectAllCheckboxProps, { table })
      : parseFromValuesOrFunc(selectCheckboxProps, { row, table })),
    ...checkboxProps,
  };

  const allRowsSelected = selectAll
    ? selectAllMode === 'page'
      ? table.getIsAllPageRowsSelected()
      : table.getIsAllRowsSelected()
    : undefined;

  const commonProps = {
    isChecked: selectAll ? allRowsSelected : row?.getIsSelected(),
    isDisabled: isLoading || (row && !row.getCanSelect()),
    inputProps: {
      'aria-label': selectAll ? localization.toggleSelectAll : localization.toggleSelectRow,
    },
    onChange: (event) => {
      event.stopPropagation();

      row
        ? row.getToggleSelectedHandler()(event)
        : selectAllMode === 'all'
          ? table.getToggleAllRowsSelectedHandler()(event)
          : table.getToggleAllPageRowsSelectedHandler()(event);

      if (enableRowPinning && rowPinningDisplayMode?.includes('select')) {
        if (row) {
          row.pin(
            !row.getIsPinned() && event.target.checked
              ? rowPinningDisplayMode?.includes('bottom')
                ? 'bottom'
                : 'top'
              : false
          );
        } else {
          table.setRowPinning({ bottom: [], top: [] });
        }
      }
    },
    size: 'md',
    ..._checkboxProps,
    sx: {
      zIndex: 0,
      ...(_checkboxProps?.sx || {}),
    },
    title: undefined,
  } as CheckboxProps | RadioProps;

  return enableMultiRowSelection === false ? (
    // <Box display="inline-flex" flexDirection="row" alignItems="center">
    <Radio mt="3px" {...(commonProps as RadioProps)} />
  ) : (
    // </Box>
    // <Box display="inline-flex" flexDirection="row" alignItems="center" mt="3px">
    <Checkbox
      // variant="old"
      mt="3px"
      isIndeterminate={
        selectAll ? table.getIsSomeRowsSelected() && !allRowsSelected : row?.getIsSomeSelected()
      }
      {...(commonProps as CheckboxProps)}
    />
    // </Box>
  );
};
