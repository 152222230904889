/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_FilterTextField
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/inputs/MRT_FilterTextField.tsx#L38)
 */

// React & Next
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ChangeEvent, MouseEvent } from 'react';

// 3rd
import { Box, Select } from '@chakra-ui/react';
import type { SelectProps } from '@chakra-ui/react';

// App - Types
import type { InputProps } from '@/components/molecules/form';
import type { MRT_Header, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { debounce } from '@/utils/debounce';
import { IconButton } from '@/components/molecules/button';
import { Field, Input, Checkbox } from '@/components/molecules/form';
import { FilterOptionMenu } from '../menus/filter-option-menu';
import { getValueAndLabel, parseFromValuesOrFunc } from '../column.utils';

interface Props<TData extends MRT_RowData> extends InputProps {
  header: MRT_Header<TData>;
  rangeFilterIndex?: number;
  table: MRT_TableInstance<TData>;
}

export const FilterInput = <TData extends MRT_RowData>({
  header,
  rangeFilterIndex,
  table,
  ...rest
}: Props<TData>) => {
  const {
    options: {
      columnFilterModeOptions,
      enableColumnFilterModes,
      icons: { CloseIcon, FilterListIcon },
      localization,
      manualFiltering,
      // muiFilterAutocompleteProps,
      // muiFilterDatePickerProps,
      filterInputProps,
    },
    refs: { filterInputRefs },
    // setColumnFilterFns,
  } = table;
  const { column } = header;
  const { columnDef } = column;
  const { filterVariant } = columnDef;

  const _inputProps: InputProps = {
    ...parseFromValuesOrFunc(filterInputProps, { column, table }),
    ...parseFromValuesOrFunc(columnDef.filterInputProps, {
      column,
      table,
    }),
    ...rest,
  };

  // const autocompleteProps = {
  //   ...parseFromValuesOrFunc(muiFilterAutocompleteProps, { column, table }),
  //   ...parseFromValuesOrFunc(columnDef.muiFilterAutocompleteProps, {
  //     column,
  //     table,
  //   }),
  // };

  // const datePickerProps: DatePickerProps<any> = {
  //   ...parseFromValuesOrFunc(muiFilterDatePickerProps, { column, table }),
  //   ...parseFromValuesOrFunc(columnDef.muiFilterDatePickerProps, {
  //     column,
  //     table,
  //   }),
  // };

  const isDateFilter = filterVariant?.startsWith('date');
  const isAutocompleteFilter = filterVariant === 'autocomplete';
  const isRangeFilter = filterVariant?.includes('range') || rangeFilterIndex !== undefined;
  const isSelectFilter = filterVariant === 'select';
  const isMultiSelectFilter = filterVariant === 'multi-select';
  const isTextboxFilter =
    ['autocomplete', 'text'].includes(filterVariant!) || (!isSelectFilter && !isMultiSelectFilter);
  const currentFilterOption = columnDef._filterFn;
  const filterChipLabel = ['empty', 'notEmpty'].includes(currentFilterOption)
    ? (localization as unknown as { [key: string]: string })[
        `filter${currentFilterOption?.charAt?.(0)?.toUpperCase() + currentFilterOption?.slice(1)}`
      ]
    : '';
  const filterPlaceholder = !isRangeFilter
    ? _inputProps?.placeholder ??
      localization.filterByColumn?.replace('{column}', String(columnDef.header))
    : rangeFilterIndex === 0
      ? localization.min
      : rangeFilterIndex === 1
        ? localization.max
        : '';
  const allowedColumnFilterOptions = columnDef?.columnFilterModeOptions ?? columnFilterModeOptions;
  const showChangeModeButton =
    enableColumnFilterModes &&
    columnDef.enableColumnFilterModes !== false &&
    !rangeFilterIndex &&
    (allowedColumnFilterOptions === undefined || !!allowedColumnFilterOptions?.length);

  const facetedUniqueValues = column.getFacetedUniqueValues();

  const [filterValue, setFilterValue] = useState<string | string[]>(() =>
    isMultiSelectFilter
      ? (column.getFilterValue() as string[]) || []
      : isRangeFilter
        ? (column.getFilterValue() as [string, string])?.[rangeFilterIndex as number] || ''
        : (column.getFilterValue() as string) ?? ''
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebounced = useCallback(
    debounce(
      (newValue: string) => {
        if (isRangeFilter) {
          column.setFilterValue((old: Array<Date | null | number | string>) => {
            const newFilterValues = old ?? ['', ''];
            newFilterValues[rangeFilterIndex as number] = newValue ?? undefined;
            return newFilterValues;
          });
        } else {
          column.setFilterValue(newValue ?? undefined);
        }
      },
      isTextboxFilter ? (manualFiltering ? 400 : 200) : 1
    ),
    []
  );

  const handleChange = (newValue: string) => {
    setFilterValue(newValue ?? '');
    handleChangeDebounced(newValue);
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newValue =
      _inputProps.type === 'date'
        ? (event.target as HTMLInputElement).valueAsDate
        : _inputProps.type === 'number'
          ? (event.target as HTMLInputElement).valueAsNumber
          : event.target.value;

    handleChange(newValue as string);

    _inputProps?.onChange?.(event as ChangeEvent<HTMLInputElement>);
  };

  const handleClear = () => {
    if (isMultiSelectFilter) {
      setFilterValue([]);
      column.setFilterValue([]);
    } else if (isRangeFilter) {
      setFilterValue('');
      column.setFilterValue((old: [string | undefined, string | undefined]) => {
        const newFilterValues = (Array.isArray(old) && old) || ['', ''];
        newFilterValues[rangeFilterIndex as number] = undefined;
        return newFilterValues;
      });
    } else {
      setFilterValue('');
      column.setFilterValue(undefined);
    }
  };

  // const handleClearEmptyFilterChip = () => {
  //   setFilterValue('');
  //   column.setFilterValue(undefined);
  //   setColumnFilterFns((prev) => ({
  //     ...prev,
  //     [header.id]: allowedColumnFilterOptions?.[0] ?? 'fuzzy',
  //   }));
  // };

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      const filterValue = column.getFilterValue();

      if (filterValue === undefined) {
        handleClear();
      } else if (isRangeFilter && rangeFilterIndex !== undefined) {
        setFilterValue((filterValue as [string, string])[rangeFilterIndex]);
      } else {
        setFilterValue(filterValue as string);
      }
    }

    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column.getFilterValue()]);

  const dropdownOptions = useMemo(
    () =>
      columnDef.filterSelectOptions ??
      ((isSelectFilter || isMultiSelectFilter || isAutocompleteFilter) && facetedUniqueValues
        ? Array.from(facetedUniqueValues.keys())
            .filter((value) => value !== null && value !== undefined)
            .sort((a, b) => a.localeCompare(b))
        : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnDef.filterSelectOptions, facetedUniqueValues, isMultiSelectFilter, isSelectFilter]
  );

  if (columnDef.Filter) {
    return <>{columnDef.Filter?.({ column, header, rangeFilterIndex, table })}</>;
  }

  // const endAdornment =
  //   !isAutocompleteFilter && !isDateFilter && !filterChipLabel ? (
  //     <InputAdornment
  //       position="end"
  //       sx={{ mr: isSelectFilter || isMultiSelectFilter ? '20px' : undefined }}
  //     >
  //       <Tooltip placement="right" label={localization.clearFilter ?? ''}>
  //         <span>
  //           <IconButton
  //             aria-label={localization.clearFilter}
  //             disabled={!filterValue?.toString()?.length}
  //             onClick={handleClear}
  //             size="small"
  //             sx={{
  //               height: '32px',
  //               transform: 'scale(0.9)',
  //               width: '32px',
  //             }}
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //         </span>
  //       </Tooltip>
  //     </InputAdornment>
  //   ) : null;

  // const startAdornment = showChangeModeButton ? (
  //   <InputAdornment position="start">
  //     <Tooltip label={localization.changeFilterMode}>
  //       <span>
  //         <IconButton
  //           aria-label={localization.changeFilterMode}
  //           onClick={handleFilterMenuOpen}
  //           size="small"
  //           sx={{ height: '28px', width: '28px' }}
  //         >
  //           <FilterListIcon />
  //         </IconButton>
  //       </span>
  //     </Tooltip>
  //     {filterChipLabel && <Chip label={filterChipLabel} onDelete={handleClearEmptyFilterChip} />}
  //   </InputAdornment>
  // ) : null;

  const commonTextFieldProps: InputProps = {
    // FormHelperTextProps: {
    //   sx: {
    //     fontSize: '12px',
    //     lineHeight: '12.8px',
    //     whiteSpace: 'nowrap',
    //   },
    // },
    // InputProps: endAdornment //hack because mui looks for presense of endAdornment key instead of undefined
    //   ? { endAdornment, startAdornment }
    //   : { startAdornment },
    w: '100%',
    // helperText: showChangeModeButton ? (
    //   <label>
    //     {localization.filterMode.replace(
    //       '{filterType}',
    //       // @ts-ignore
    //       localization[
    //         `filter${currentFilterOption?.charAt(0)?.toUpperCase() + currentFilterOption?.slice(1)}`
    //       ]
    //     )}
    //   </label>
    // ) : null,
    'aria-label': filterPlaceholder,
    autoComplete: 'new-password', // disable autocomplete and autofill
    isDisabled: !!filterChipLabel,
    sx: {
      textOverflow: 'ellipsis',
      width: filterChipLabel ? 0 : undefined,
    },
    title: filterPlaceholder,
    m: 'none',
    onClick: (e: MouseEvent<HTMLInputElement>) => e.stopPropagation(),
    placeholder:
      filterChipLabel || isSelectFilter || isMultiSelectFilter ? undefined : filterPlaceholder,
    ..._inputProps,
  };

  return (
    <>
      {isDateFilter ? (
        // <DatePicker
        //   onChange={(newDate) => {
        //     handleChange(newDate);
        //   }}
        //   value={filterValue || null}
        //   {...datePickerProps}
        //   slotProps={{
        //     field: {
        //       clearable: true,
        //       onClear: () => handleClear(),
        //       ...datePickerProps?.slotProps?.field,
        //     },
        //     textField: {
        //       ...commonTextFieldProps,
        //       ...datePickerProps?.slotProps?.textField,
        //     },
        //   }}
        // />
        <div>Date Picker should be here</div>
      ) : isAutocompleteFilter ? (
        // <Autocomplete
        //   freeSolo
        //   getOptionLabel={(option) => getValueAndLabel(option).label}
        //   onChange={(_e, newValue) => handleChange(getValueAndLabel(newValue).value)}
        //   options={dropdownOptions?.map((option) => getValueAndLabel(option)) ?? []}
        //   {...autocompleteProps}
        //   renderInput={(builtinTextFieldProps) => (
        //     <TextField
        //       {...builtinTextFieldProps}
        //       {...commonTextFieldProps}
        //       InputProps={{
        //         ...builtinTextFieldProps.InputProps,
        //         startAdornment: commonTextFieldProps?.InputProps?.startAdornment,
        //       }}
        //       inputProps={{
        //         ...builtinTextFieldProps.inputProps,
        //         ...commonTextFieldProps?.inputProps,
        //       }}
        //       onChange={handleTextFieldChange}
        //     />
        //   )}
        //   value={filterValue}
        // />
        <div>Auto Complete should be here</div>
      ) : (
        // <FormControl w="100%" m="none">
        <Field
          label=""
          htmlFor={filterVariant || 'filter-input'}
          helpText={
            showChangeModeButton
              ? localization.filterMode.replace(
                  '{filterType}',
                  (localization as unknown as { [key: string]: string })[
                    `filter${
                      currentFilterOption?.charAt(0)?.toUpperCase() + currentFilterOption?.slice(1)
                    }`
                  ]
                )
              : undefined
          }
          w="100%"
          m="none"
        >
          {isSelectFilter || isMultiSelectFilter ? (
            //   <Select
            //             w="100%"
            //             m="none"
            //             onChange={(e) => header.column.setFilterValue(e.target.value || undefined)}
            //             placeholder="Filter"
            //             value={(header.column.getFilterValue() as string) ?? ''}
            //             variant="standard"
            //           >
            //             <option value={undefined}>All</option>
            //             <option value="Male">Male</option>
            //             <option value="Female">Female</option>
            //             <option value="Other">Other</option>
            //           </Select>
            <Select
              onChange={handleTextFieldChange}
              {...(commonTextFieldProps as SelectProps)}
              ref={(ref) => {
                if (ref) filterInputRefs.current[`${column.id}-${rangeFilterIndex ?? 0}`] = ref;
              }}
              id={filterVariant || 'filter-input'}
              // w="100%"
              // m="none"
              // variant="flushed"
              value={filterValue ?? ''}
              sx={{
                minWidth: isDateFilter
                  ? '160px'
                  : enableColumnFilterModes && rangeFilterIndex === 0
                    ? '110px'
                    : isRangeFilter
                      ? '100px'
                      : !filterChipLabel
                        ? '120px'
                        : 'auto',
                // p: 0,
                // width: 'calc(100% + 4px)',
                ...(_inputProps?.sx || {}),
              }}
            >
              {[
                <option
                  disabled
                  hidden
                  style={{
                    borderBottomWidth: '1px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'gray.200',
                  }}
                  key="p"
                  value=""
                >
                  <Box sx={{ opacity: 0.5 }}>{filterPlaceholder}</Box>
                </option>,
                ...[
                  _inputProps.children ??
                    dropdownOptions?.map((option, index) => {
                      const { label, value } = getValueAndLabel(option);

                      return (
                        <option
                          key={`${index}-${value}`}
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '8px',
                            margin: 0,
                          }}
                          value={value}
                        >
                          {isMultiSelectFilter && (
                            <Checkbox
                              checked={((column.getFilterValue() ?? []) as string[]).includes(
                                value
                              )}
                              sx={{ mr: '8px' }}
                            />
                          )}
                          {label}{' '}
                          {!columnDef.filterSelectOptions && `(${facetedUniqueValues.get(value)})`}
                        </option>
                      );
                    }),
                ],
              ]}
            </Select>
          ) : (
            <Input
              onChange={handleTextFieldChange}
              {...commonTextFieldProps}
              ref={(ref: HTMLInputElement) => {
                if (ref) filterInputRefs.current[`${column.id}-${rangeFilterIndex ?? 0}`] = ref;
              }}
              value={filterValue ?? ''}
              id={filterVariant || 'filter-input'}
              variant="old.flushed"
              size="sm"
              sx={{
                minWidth: isDateFilter
                  ? '160px'
                  : enableColumnFilterModes && rangeFilterIndex === 0
                    ? '110px'
                    : isRangeFilter
                      ? '100px'
                      : !filterChipLabel
                        ? '120px'
                        : 'auto',
                ps: 0,
                width: 'calc(100% + 4px)',
                ...(_inputProps?.sx || {}),
              }}
              rightAddon={
                filterValue?.toString()?.length ? (
                  <IconButton
                    aria-label={localization.clearFilter}
                    isDisabled={!filterValue?.toString()?.length}
                    onClick={handleClear}
                    variant="old.ghost"
                    size="sm"
                    icon={
                      <CloseIcon
                        size="4xs"
                        color="text.primary"
                        aria-label={localization.clearFilter}
                      />
                    }
                    _hover={{ bg: 'transparent' }}
                  />
                ) : undefined
              }
              leftAddon={
                isMultiSelectFilter ? (
                  <>
                    <FilterOptionMenu
                      header={header}
                      label={localization.changeFilterMode}
                      icon={<FilterListIcon aria-label={localization.changeFilterMode} />}
                      setFilterValue={setFilterValue}
                      table={table}
                    />

                    {/*{filterChipLabel && (*/}
                    {/*  <Chip label={filterChipLabel} onDelete={handleClearEmptyFilterChip} />*/}
                    {/*)}*/}
                  </>
                ) : undefined
              }
            />
          )}
        </Field>
        // <TextField
        //   // SelectProps={{
        //   //   displayEmpty: true,
        //   //   multiple: isMultiSelectFilter,
        //   //   renderValue: isMultiSelectFilter
        //   //     ? (selected: any) =>
        //   //         !selected?.length ? (
        //   //           <Box sx={{ opacity: 0.5 }}>{filterPlaceholder}</Box>
        //   //         ) : (
        //   //           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2px' }}>
        //   //             {(selected as string[])?.map((value) => {
        //   //               const selectedValue = dropdownOptions?.find(
        //   //                 (option) => getValueAndLabel(option).value === value
        //   //               );
        //   //               return <Chip key={value} label={getValueAndLabel(selectedValue).label} />;
        //   //             })}
        //   //           </Box>
        //   //         )
        //   //     : undefined,
        //   // }}
        //   onChange={handleTextFieldChange}
        //   // select={isSelectFilter || isMultiSelectFilter}
        //   {...commonTextFieldProps}
        //   value={filterValue ?? ''}
        // >
        //   {(isSelectFilter || isMultiSelectFilter) && [
        //     <MenuItem disabled divider hidden key="p" value="">
        //       <Box sx={{ opacity: 0.5 }}>{filterPlaceholder}</Box>
        //     </MenuItem>,
        //     ...[
        //       textFieldProps.children ??
        //         dropdownOptions?.map((option, index) => {
        //           const { label, value } = getValueAndLabel(option);
        //           return (
        //             <MenuItem
        //               key={`${index}-${value}`}
        //               sx={{
        //                 alignItems: 'center',
        //                 display: 'flex',
        //                 gap: '8px',
        //                 m: 0,
        //               }}
        //               value={value}
        //             >
        //               {isMultiSelectFilter && (
        //                 <Checkbox
        //                   checked={((column.getFilterValue() ?? []) as string[]).includes(value)}
        //                   sx={{ mr: '8px' }}
        //                 />
        //               )}
        //               {label}{' '}
        //               {!columnDef.filterSelectOptions && `(${facetedUniqueValues.get(value)})`}
        //             </MenuItem>
        //           );
        //         }),
        //     ],
        //   ]}
        // </TextField>
      )}
    </>
  );
};
