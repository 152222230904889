import { type DataTableLocalization } from '..';

// App
import Locale from '@/locale/en.json';

const locale = Locale.components.table;

export const DataTableLocalizationEN: DataTableLocalization = {
  actions: locale['Actions'],
  and: locale['and'],
  cancel: locale['Cancel'],
  changeFilterMode: locale['Change filter mode'],
  changeSearchMode: locale['Change search mode'],
  clearFilter: locale['Clear filter'],
  clearSearch: locale['Clear search'],
  clearSort: locale['Clear sort'],
  clickToCopy: locale['Click to copy'],
  collapse: locale['Collapse'],
  collapseAll: locale['Collapse all'],
  columnActions: locale['Column Actions'],
  copiedToClipboard: locale['Copied to clipboard'],
  dropToGroupBy: locale['Drop to group by {column}'],
  edit: locale['Edit'],
  expand: locale['Expand'],
  expandAll: locale['Expand all'],
  filterArrIncludes: locale['Includes'],
  filterArrIncludesAll: locale['Includes all'],
  filterArrIncludesSome: locale['Includes'],
  filterBetween: locale['Between'],
  filterBetweenInclusive: locale['Between Inclusive'],
  filterByColumn: locale['Filter by {column}'],
  filterContains: locale['Contains'],
  filterEmpty: locale['Empty'],
  filterEndsWith: locale['Ends With'],
  filterEquals: locale['Equals'],
  filterEqualsString: locale['Equals'],
  filterFuzzy: locale['Fuzzy'],
  filterGreaterThan: locale['Greater Than'],
  filterGreaterThanOrEqualTo: locale['Greater Than Or Equal To'],
  filterInNumberRange: locale['Between'],
  filterIncludesString: locale['Contains'],
  filterIncludesStringSensitive: locale['Contains'],
  filterLessThan: locale['Less Than'],
  filterLessThanOrEqualTo: locale['Less Than Or Equal To'],
  filterMode: locale['Filter Mode: {filterType}'],
  filterNotEmpty: locale['Not Empty'],
  filterNotEquals: locale['Not Equals'],
  filters: locale['Filters'],
  filterStartsWith: locale['Starts With'],
  filterWeakEquals: locale['Equals'],
  filteringByColumn: locale['Filtering by {column} - {filterType} {filterValue}'],
  goToFirstPage: locale['Go to first page'],
  goToLastPage: locale['Go to last page'],
  goToNextPage: locale['Go to next page'],
  goToPreviousPage: locale['Go to previous page'],
  grab: locale['Grab'],
  groupByColumn: locale['Group by {column}'],
  groupedBy: locale['Grouped by '],
  hideAll: locale['Hide all'],
  hideColumn: locale['Hide {column} column'],
  max: locale['Max'],
  min: locale['Min'],
  move: locale['Move'],
  noRecordsToDisplay: locale['No records to display'],
  noResultsFound: locale['No results found'],
  of: locale['of'],
  or: locale['or'],
  pin: locale['Pin'],
  pinToLeft: locale['Pin to left'],
  pinToRight: locale['Pin to right'],
  resetColumnSize: locale['Reset column size'],
  resetOrder: locale['Reset order'],
  rowActions: locale['Row Actions'],
  rowNumber: locale['#'],
  rowNumbers: locale['Row Numbers'],
  rowsPerPage: locale['Rows per page'],
  save: locale['Save'],
  search: locale['Search'],
  selectedCountOfRowCountRowsSelected: locale['{selectedCount} selected rows (out of {rowCount})'],
  select: locale['Select'],
  showAll: locale['Show all'],
  showAllColumns: locale['Show all columns'],
  showHideColumns: locale['Show/Hide columns'],
  showHideFilters: locale['Show/Hide filters'],
  showHideSearch: locale['Show/Hide search'],
  sortByColumnAsc: locale['Sort by {column} ascending'],
  sortByColumnDesc: locale['Sort by {column} descending'],
  sortedByColumnAsc: locale['Sorted by {column} ascending'],
  sortedByColumnDesc: locale['Sorted by {column} descending'],
  thenBy: locale[', then by '],
  toggleSelectAll: locale['Toggle select all'],
  toggleSelectRow: locale['Toggle select row'],
  toggleVisibility: locale['Toggle visibility'],
  ungroupByColumn: locale['Ungroup by {column}'],
  unpin: locale['Unpin'],
  unpinAll: locale['Unpin all'],
};
