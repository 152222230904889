/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableBodyRow
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/body/MRT_TableBodyRow.tsx#L36)
 */

// React & Next
import { memo, useMemo, useRef } from 'react';

// 3rd
import { Tr, useColorMode } from '@chakra-ui/react';
import type { VirtualItem, Virtualizer } from '@tanstack/react-virtual';

// App - Types
import type { MRT_Cell, MRT_Row, MRT_RowData, MRT_TableInstance } from '../../types';

// App - Other
import { parseFromValuesOrFunc } from '../../column.utils';
import { getTableTheme } from '../../style.utils';
import { TableBodyCell, MemoTableBodyCell } from '../body-cell/table-body-cell';
import { TableDetailPanel } from '../table-detail-panel';

interface Props<TData extends MRT_RowData> {
  columnVirtualizer?: Virtualizer<HTMLDivElement, HTMLTableCellElement>;
  measureElement?: (element: HTMLTableRowElement) => void;
  numRows?: number;
  pinnedRowIds?: string[];
  row: MRT_Row<TData>;
  rowIndex: number;
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
  virtualRow?: VirtualItem;
}

export const TableBodyRow = <TData extends MRT_RowData>({
  columnVirtualizer,
  measureElement,
  numRows,
  pinnedRowIds,
  row,
  rowIndex,
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
  virtualRow,
}: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getState,
    options: {
      enableRowOrdering,
      enableRowPinning,
      enableStickyFooter,
      enableStickyHeader,
      layoutMode,
      memoMode,
      tableBodyRowProps,
      renderDetailPanel,
      rowPinningDisplayMode,
    },
    refs: { tableFooterRef, tableHeadRef },
    setHoveredRow,
  } = table;
  const { draggingColumn, draggingRow, editingCell, editingRow, hoveredRow, rowPinning } =
    getState();

  const isPinned = enableRowPinning && row.getIsPinned();

  const tableRowProps = parseFromValuesOrFunc(tableBodyRowProps, {
    row,
    staticRowIndex: rowIndex,
    table,
  });

  const [bottomPinnedIndex, topPinnedIndex] = useMemo(() => {
    if (
      !enableRowPinning ||
      !rowPinningDisplayMode?.includes('sticky') ||
      !pinnedRowIds ||
      !row.getIsPinned()
    )
      return [];
    return [[...pinnedRowIds].reverse().indexOf(row.id), pinnedRowIds.indexOf(row.id)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedRowIds, rowPinning]);

  const tableHeadHeight = (enableStickyHeader && tableHeadRef.current?.clientHeight) || 0;
  const tableFooterHeight = (enableStickyFooter && tableFooterRef.current?.clientHeight) || 0;

  const sx = tableRowProps?.sx || {};

  const rowHeight = parseInt((tableRowProps?.style?.height ?? sx?.height) as string, 10) || 53;

  const handleDragEnter = () => {
    if (enableRowOrdering && draggingRow) {
      setHoveredRow(row);
    }
  };

  const rowRef = useRef<HTMLTableRowElement | null>(null);

  const { pinnedRowBackgroundColor, selectedRowBackgroundColor } = getTableTheme(
    table,
    colorModeContext
  );

  return (
    <>
      <Tr
        data-index={virtualRow?.index}
        data-pinned={!!isPinned || undefined}
        data-selected={row.getIsSelected() || undefined}
        onDragEnter={handleDragEnter}
        ref={(node: HTMLTableRowElement) => {
          if (node) {
            rowRef.current = node;
            measureElement?.(node);
          }
        }}
        selected={row.getIsSelected()}
        {...tableRowProps}
        style={{
          transform: virtualRow ? `translateY(${virtualRow?.start}px)` : undefined,
          ...tableRowProps?.style,
        }}
        sx={{
          _hover: {
            // boxShadow: 'table-row',
            // '& td': {
            //   // backgroundColor: !!tableRowProps?._hover ?
            //   backgroundColor: row.getIsSelected()
            //     ? `${alpha(selectedRowBackgroundColor, 0.3)}`
            //     : colorModeContext.colorMode === 'dark'
            //       ? `${lighten(selectedRowBackgroundColor, 0.05)}`
            //       : `${lighten(selectedRowBackgroundColor, 0.8)}`,
            // },
          },
          bottom:
            !virtualRow && bottomPinnedIndex !== undefined && isPinned
              ? `${
                  bottomPinnedIndex * rowHeight + (enableStickyFooter ? tableFooterHeight - 1 : 0)
                }px`
              : undefined,
          boxSizing: 'border-box',
          display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
          opacity: isPinned
            ? 0.97
            : draggingRow?.id === row.id || hoveredRow?.id === row.id
              ? 0.5
              : 1,
          position: virtualRow
            ? 'absolute'
            : rowPinningDisplayMode?.includes('sticky') && isPinned
              ? 'sticky'
              : undefined,
          td: {
            backgroundColor: row.getIsSelected()
              ? selectedRowBackgroundColor
              : isPinned
                ? pinnedRowBackgroundColor
                : undefined,
          },
          top: virtualRow
            ? 0
            : topPinnedIndex !== undefined && isPinned
              ? `${topPinnedIndex * rowHeight + (enableStickyHeader ? tableHeadHeight - 1 : 0)}px`
              : undefined,
          transition: virtualRow ? 'none' : 'all 150ms ease-in-out',
          width: '100%',
          height: '48px',
          zIndex: rowPinningDisplayMode?.includes('sticky') && isPinned ? 2 : undefined,
          ...sx,
        }}
      >
        {virtualPaddingLeft ? <td style={{ display: 'flex', width: virtualPaddingLeft }} /> : null}

        {(virtualColumns ?? row.getVisibleCells()).map((cellOrVirtualCell) => {
          const cell = columnVirtualizer
            ? row.getVisibleCells()[(cellOrVirtualCell as VirtualItem).index]
            : (cellOrVirtualCell as MRT_Cell<TData>);
          const props = {
            cell,
            measureElement: columnVirtualizer?.measureElement,
            numRows,
            rowIndex,
            rowRef,
            table,
            virtualIndex: columnVirtualizer ? (cellOrVirtualCell as VirtualItem).index : undefined,
          };

          return cell ? (
            memoMode === 'cells' &&
            cell.column.columnDef.columnDefType === 'data' &&
            !draggingColumn &&
            !draggingRow &&
            editingCell?.id !== cell.id &&
            editingRow?.id !== row.id ? (
              <MemoTableBodyCell key={cell.id} {...props} />
            ) : (
              <TableBodyCell key={cell.id} {...props} />
            )
          ) : null;
        })}

        {virtualPaddingRight ? (
          <td style={{ display: 'flex', width: virtualPaddingRight }} />
        ) : null}
      </Tr>

      {renderDetailPanel && !row.getIsGrouped() && (
        <TableDetailPanel
          parentRowRef={rowRef}
          row={row}
          rowIndex={rowIndex}
          table={table}
          virtualRow={virtualRow}
        />
      )}
    </>
  );
};

export const MemoTableBodyRow = memo(
  TableBodyRow,
  (prev, next) => prev.row === next.row && prev.rowIndex === next.rowIndex
) as typeof TableBodyRow;
