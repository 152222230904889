// 3rd
import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { body, labelSB } from '@/config/theme/components/text';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const primaryVariant = definePartsStyle({
  root: {},

  tab: {
    display: 'flex',
    py: 'sm',
    px: 'lg',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'sm',
    ...body,

    '& svg.chakra-icon': {
      w: '16px',
      h: '16px',
    },

    _selected: {
      ...labelSB,
      // borderBottomColor: 'border.brand.primary',
      color: 'text.brand.primary-high-tone',

      '& svg.chakra-icon': {
        w: '16px',
        h: '16px',
      },
    },
  },

  tablist: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: 'none',
    w: '100%',
    borderBottom: '1px',
    borderBottomColor: 'border.primary',
  },

  tabpanels: {},

  tabpanel: {
    py: 'md',
    px: 'md',
  },
});

const spacedPrimaryVariant = definePartsStyle({
  root: {
    ...primaryVariant.root,
  },

  tab: {
    ...primaryVariant.tab,
  },

  tablist: {
    ...primaryVariant.tablist,
    w: '100%',
    justifyContent: 'space-between',
  },

  tabpanels: {
    ...primaryVariant.tabpanels,
  },

  tabpanel: {
    ...primaryVariant.tabpanel,
  },
});

const roundedVariant = definePartsStyle({
  root: {},

  tab: {
    display: 'flex',
    transition: 'all 120ms ease-in-out',
    py: 'xs',
    px: 'md',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'sm',
    borderRadius: 'full',
    ...body,

    '& svg.chakra-icon': {
      w: '16px',
      h: '16px',
    },

    _selected: {
      ...body,
      bg: 'surface.brand.primary',
      color: 'text.invert-primary',

      '& svg.chakra-icon': {
        w: '16px',
        h: '16px',
      },
    },
  },

  tablist: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: 'sm',
    bg: 'surface.high-tone',
    borderRadius: 'full',
  },

  tabpanels: {},

  tabpanel: {
    py: '3xl',
    px: 'xs',
  },
});

const attachedVariant = definePartsStyle({
  root: {},

  tab: {
    display: 'flex',
    transition: 'all 120ms ease-in-out',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'sm',
    border: '1px',
    borderEnd: 'none',
    borderColor: 'border.primary',
    py: '11px',
    px: '14px',
    borderRadius: 'none',

    color: '#8794A6',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',

    _first: {
      borderRadius: '4px 0px 0px 4px',
    },

    _last: {
      borderRadius: '0px 4px 4px 0px',
      borderEnd: '1px',
      borderColor: 'border.primary',
    },

    _selected: {
      bg: '#F5F7F9',

      color: '#0F172A',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',

      '& svg.chakra-icon': {
        w: '16px',
        h: '16px',
      },
    },

    '& svg.chakra-icon': {
      w: '16px',
      h: '16px',
    },
  },

  tablist: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: 'none',
    bg: 'surface.primary',
    borderRadius: 'sm',
  },

  tabpanels: {},

  tabpanel: {
    py: '3xl',
    px: 'xs',
  },
});

const tabsTheme = defineMultiStyleConfig({
  variants: {
    primary: primaryVariant,
    'spaced.primary': spacedPrimaryVariant,
    rounded: roundedVariant,
    attached: attachedVariant,
  },
});

export const Tabs = tabsTheme;
