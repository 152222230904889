/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableBodyRowGrabHandle
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/body/MRT_TableBodyRowGrabHandle.tsx)
 */

// React & Next
import type { DragEvent, RefObject } from 'react';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_Row, MRT_RowData, MRT_TableInstance } from '../../types';

// App - Other
import { GrabHandleButton } from '../../buttons/grab-handle-button';
import { parseFromValuesOrFunc } from '../../column.utils';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  row: MRT_Row<TData>;
  rowRef: RefObject<HTMLTableRowElement>;
  table: MRT_TableInstance<TData>;
}

export const TableBodyRowGrabHandle = <TData extends MRT_RowData>({
  row,
  rowRef,
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    options: { rowDragHandleProps },
  } = table;

  const _iconButtonProps = {
    ...parseFromValuesOrFunc(rowDragHandleProps, {
      row,
      table,
    }),
    ...iconButtonProps,
  };

  const handleDragStart = (event: DragEvent<HTMLButtonElement>) => {
    _iconButtonProps?.onDragStart?.(event);
    event.dataTransfer.setDragImage(rowRef.current as HTMLElement, 0, 0);
    table.setDraggingRow(row);
  };

  const handleDragEnd = (event: DragEvent<HTMLButtonElement>) => {
    _iconButtonProps?.onDragEnd?.(event);
    table.setDraggingRow(null);
    table.setHoveredRow(null);
  };

  return (
    <GrabHandleButton
      location="row"
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      table={table}
      {...iconButtonProps}
    />
  );
};
