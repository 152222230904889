export const colors = {
  transparent: 'transparent',
  current: 'currentColor',

  green: {
    50: '#F1FDF6',
    100: '#DCF5E7',
    200: '#B3E6C8',
    300: '#75D09B',
    400: '#37B96D',
    500: '#1AAA55',
    600: '#168F48',
    700: '#12753A',
    800: '#0E5A2D',
    900: '#0A4020',
    950: '#072B15',
  },

  gray: {
    50: '#FFFFFF',
    100: '#F8FAFC',
    150: '#F1F5F9',
    200: '#E2E8F0',
    300: '#CBD5E1',
    350: '#B6C1D3',
    400: '#8596AD',
    500: '#64748B',
    600: '#475569',
    700: '#334155',
    800: '#1E293B',
    900: '#0F172A',
    950: '#020617',
  },

  orange: {
    50: '#FFFAF4',
    100: '#FFF1DE',
    200: '#FED69F',
    300: '#FDBC60',
    400: '#FCA121',
    500: '#FC9403',
    600: '#DE7E00',
    700: '#C26700',
    800: '#A35200',
    900: '#853C00',
    950: '#592800',
  },

  red: {
    50: '#FEF6F5',
    100: '#FBE5E1',
    200: '#F2B4A9',
    300: '#EA8271',
    400: '#E15139',
    500: '#DB3B21',
    600: '#C0341D',
    700: '#A62D19',
    800: '#8B2615',
    900: '#711E11',
    950: '#4B140B',
  },

  primary: {
    50: '#F2FCFA',
    100: '#CFF7F4',
    200: '#9FEFE9',
    300: '#68DFDB',
    400: '#36BFBF',
    500: '#21A9AB',
    600: '#18888C',
    700: '#17696D',
    800: '#175559',
    900: '#184649',
    950: '#07282C',
  },

  secondary: {
    50: '#F3F7FC',
    100: '#E6EEF8',
    200: '#C7DCF0',
    300: '#95C0E4',
    400: '#5C9ED4',
    500: '#3782C0',
    600: '#2A6FAF',
    700: '#215283',
    800: '#1F476D',
    900: '#1E3C5C',
    950: '#14273D',
  },

  // TODO below are not included in Adam's tokens

  // blackAlpha: {
  //   50: 'rgba(0, 0, 0, 0.04)',
  //   100: 'rgba(0, 0, 0, 0.06)',
  //   200: 'rgba(0, 0, 0, 0.08)',
  //   300: 'rgba(0, 0, 0, 0.16)',
  //   400: 'rgba(0, 0, 0, 0.24)',
  //   500: 'rgba(0, 0, 0, 0.36)',
  //   600: 'rgba(0, 0, 0, 0.48)',
  //   700: 'rgba(0, 0, 0, 0.64)',
  //   800: 'rgba(0, 0, 0, 0.80)',
  //   900: 'rgba(0, 0, 0, 0.92)',
  // },
  //
  // whiteAlpha: {
  //   50: 'rgba(255, 255, 255, 0.04)',
  //   100: 'rgba(255, 255, 255, 0.06)',
  //   200: 'rgba(255, 255, 255, 0.08)',
  //   300: 'rgba(255, 255, 255, 0.16)',
  //   400: 'rgba(255, 255, 255, 0.24)',
  //   500: 'rgba(255, 255, 255, 0.36)',
  //   600: 'rgba(255, 255, 255, 0.48)',
  //   700: 'rgba(255, 255, 255, 0.64)',
  //   800: 'rgba(255, 255, 255, 0.80)',
  //   900: 'rgba(255, 255, 255, 0.92)',
  // },
  //
  // yellow: {
  //   50: '#fefce8',
  //   100: '#fef9c3',
  //   200: '#fef08a',
  //   300: '#fde047',
  //   400: '#facc15',
  //   500: '#eab308',
  //   600: '#ca8a04',
  //   700: '#a16207',
  //   800: '#854d0e',
  //   900: '#713f12',
  // },
  //
  // teal: {
  //   50: '#f0fdfa',
  //   100: '#ccfbf1',
  //   200: '#99f6e4',
  //   300: '#5eead4',
  //   400: '#2dd4bf',
  //   500: '#14b8a6',
  //   600: '#0d9488',
  //   700: '#0f766e',
  //   800: '#115e59',
  //   900: '#134e4a',
  // },
  //
  // blue: {
  //   50: '#eff6ff',
  //   100: '#dbeafe',
  //   200: '#bfdbfe',
  //   300: '#93c5fd',
  //   400: '#60a5fa',
  //   500: '#3b82f6',
  //   600: '#2563eb',
  //   700: '#1d4ed8',
  //   800: '#1e40af',
  //   900: '#1e3a8a',
  // },
  //
  // cyan: {
  //   50: '#ecfeff',
  //   100: '#cffafe',
  //   200: '#a5f3fc',
  //   300: '#67e8f9',
  //   400: '#22d3ee',
  //   500: '#06b6d4',
  //   600: '#0891b2',
  //   700: '#0e7490',
  //   800: '#155e75',
  //   900: '#164e63',
  // },
  //
  // purple: {
  //   50: '#faf5ff',
  //   100: '#e9d8fd',
  //   200: '#d6bcfa',
  //   300: '#b794f4',
  //   400: '#9f7aea',
  //   500: '#805ad5',
  //   600: '#6b46c1',
  //   700: '#553c9a',
  //   800: '#44337a',
  //   900: '#322659',
  // },
  //
  // pink: {
  //   50: '#fdf2f8',
  //   100: '#fce7f3',
  //   200: '#fbcfe8',
  //   300: '#f9a8d4',
  //   400: '#f472b6',
  //   500: '#ec4899',
  //   600: '#db2777',
  //   700: '#be185d',
  //   800: '#9d174d',
  //   900: '#831843',
  // },
  //
  // brand: {
  //   50: '#f2f2ff',
  //   100: '#cbcbff',
  //   200: '#a3a3ff',
  //   300: '#7d7dff',
  //   400: '#5c5cff',
  //   500: '#4141fa',
  //   600: '#2e2ed7',
  //   700: '#20209d',
  //   800: '#131358',
  //   900: '#060616',
  // },
};

export const colorTokens = {
  border: {
    brand: {
      primary: {
        default: colors.primary['500'],
        _light: colors.primary['500'],
        _dark: colors.primary['500'],
      },

      'primary-mid-contrast': {
        default: colors.primary['700'],
        _light: colors.primary['700'],
        _dark: colors.primary['400'],
      },

      'invert-primary-mid-contrast': {
        default: colors.primary['400'],
        _light: colors.primary['400'],
        _dark: colors.primary['700'],
      },

      'primary-low-contrast': {
        default: colors.primary['600'],
        _light: colors.primary['600'],
        _dark: colors.primary['300'],
      },

      'invert-primary-low-contrast': {
        default: colors.primary['300'],
        _light: colors.primary['300'],
        _dark: colors.primary['600'],
      },

      secondary: {
        default: colors.secondary['700'],
        _light: colors.secondary['700'],
        _dark: colors.secondary['700'],
      },

      'secondary-high-tone': {
        default: colors.secondary['600'],
        _light: colors.secondary['600'],
        _dark: colors.secondary['600'],
      },

      'secondary-mid-tone': {
        default: colors.secondary['500'],
        _light: colors.secondary['500'],
        _dark: colors.secondary['500'],
      },
    },

    primary: {
      default: colors.gray['200'],
      _light: colors.gray['200'],
      _dark: colors.gray['950'],
    },

    'invert-primary': {
      default: colors.gray['950'],
      _light: colors.gray['950'],
      _dark: colors.gray['200'],
    },

    secondary: {
      default: colors.gray['100'],
      _light: colors.gray['100'],
      _dark: colors.gray['100'],
    },

    'high-tone': {
      default: colors.gray['400'],
      _light: colors.gray['400'],
      _dark: colors.gray['900'],
    },

    'invert-high-tone': {
      default: colors.gray['900'],
      _light: colors.gray['900'],
      _dark: colors.gray['400'],
    },

    'mid-tone': {
      default: colors.gray['500'],
      _light: colors.gray['500'],
      _dark: colors.gray['800'],
    },

    'invert-mid-tone': {
      default: colors.gray['800'],
      _light: colors.gray['800'],
      _dark: colors.gray['500'],
    },

    'low-tone': {
      default: colors.gray['600'],
      _light: colors.gray['600'],
      _dark: colors.gray['700'],
    },

    'invert-low-tone': {
      default: colors.gray['700'],
      _light: colors.gray['700'],
      _dark: colors.gray['600'],
    },
  },

  surface: {
    brand: {
      primary: {
        default: colors.primary['500'],
        _light: colors.primary['500'],
        _dark: colors.primary['500'],
      },

      'invert-primary-high-tone': {
        default: colors.primary['200'],
        _light: colors.primary['200'],
        _dark: colors.primary['200'],
      },

      'primary-high-contrast': {
        default: colors.primary['800'],
        _light: colors.primary['800'],
        _dark: colors.primary['800'],
      },

      'primary-mid-tone': {
        default: colors.primary['950'],
        _light: colors.primary['950'],
        _dark: colors.primary['100'],
      },

      'invert-primary-mid-tone': {
        default: colors.primary['100'],
        _light: colors.primary['100'],
        _dark: colors.primary['950'],
      },

      'primary-mid-contrast': {
        default: colors.primary['700'],
        _light: colors.primary['700'],
        _dark: colors.primary['400'],
      },

      'invert-primary-mid-contrast': {
        default: colors.primary['400'],
        _light: colors.primary['400'],
        _dark: colors.primary['700'],
      },

      'primary-low-tone': {
        default: colors.primary['900'],
        _light: colors.primary['900'],
        _dark: colors.primary['50'],
      },

      'invert-primary-low-tone': {
        default: colors.primary['50'],
        _light: colors.primary['50'],
        _dark: colors.primary['900'],
      },

      'primary-low-contrast': {
        default: colors.primary['600'],
        _light: colors.primary['600'],
        _dark: colors.primary['300'],
      },

      'invert-primary-low-contrast': {
        default: colors.primary['300'],
        _light: colors.primary['300'],
        _dark: colors.primary['600'],
      },

      secondary: {
        default: colors.secondary['500'],
        _light: colors.secondary['500'],
        _dark: colors.secondary['500'],
      },

      'secondary-mid-tone': {
        default: colors.secondary['100'],
        _light: colors.secondary['100'],
        _dark: colors.secondary['100'],
      },

      'secondary-mid-contrast': {
        default: colors.secondary['700'],
        _light: colors.secondary['700'],
        _dark: colors.secondary['700'],
      },

      'secondary-low-tone': {
        default: colors.secondary['50'],
        _light: colors.secondary['50'],
        _dark: colors.secondary['50'],
      },

      'secondary-low-contrast': {
        default: colors.secondary['600'],
        _light: colors.secondary['600'],
        _dark: colors.secondary['600'],
      },
    },

    error: {
      default: colors.red['500'],
      _light: colors.red['500'],
      _dark: colors.red['500'],
    },

    'error-secondary': {
      default: colors.red['400'],
      _light: colors.red['400'],
      _dark: colors.red['400'],
    },

    'error-mid-tone': {
      default: colors.red['100'],
      _light: colors.red['100'],
      _dark: colors.red['100'],
    },

    'error-low-tone': {
      default: colors.red['50'],
      _light: colors.red['50'],
      _dark: colors.red['50'],
    },

    'error-low-contrast': {
      default: colors.red['600'],
      _light: colors.red['600'],
      _dark: colors.red['600'],
    },

    'high-tone': {
      default: colors.gray['150'],
      _light: colors.gray['150'],
      _dark: colors.gray['700'],
    },

    'invert-high-tone': {
      default: colors.gray['700'],
      _light: colors.gray['700'],
      _dark: colors.gray['150'],
    },

    'mid-tone': {
      default: colors.gray['200'],
      _light: colors.gray['200'],
      _dark: colors.gray['600'],
    },

    'invert-mid-tone': {
      default: colors.gray['600'],
      _light: colors.gray['600'],
      _dark: colors.gray['200'],
    },

    'mid-contrast': {
      default: colors.gray['400'],
      _light: colors.gray['400'],
      _dark: colors.gray['400'],
    },

    'low-contrast': {
      default: colors.gray['350'],
      _light: colors.gray['350'],
      _dark: colors.gray['350'],
    },

    'low-tone': {
      default: colors.gray['300'],
      _light: colors.gray['300'],
      _dark: colors.gray['500'],
    },

    'invert-low-tone': {
      default: colors.gray['500'],
      _light: colors.gray['500'],
      _dark: colors.gray['300'],
    },

    primary: {
      default: colors.gray['50'],
      _light: colors.gray['50'],
      _dark: colors.gray['900'],
    },

    'invert-primary': {
      default: colors.gray['900'],
      _light: colors.gray['900'],
      _dark: colors.gray['50'],
    },

    secondary: {
      default: colors.gray['100'],
      _light: colors.gray['100'],
      _dark: colors.gray['800'],
    },

    'invert-secondary': {
      default: colors.gray['800'],
      _light: colors.gray['800'],
      _dark: colors.gray['100'],
    },

    success: {
      default: colors.green['500'],
      _light: colors.green['500'],
      _dark: colors.green['500'],
    },

    warning: {
      default: colors.orange['500'],
      _light: colors.orange['500'],
      _dark: colors.orange['500'],
    },

    'gradient-1': {
      default: `linear-gradient(269deg, ${colors.primary['300']} 1.81%, ${colors.primary['400']} 98.19%)`,
      _light: `linear-gradient(269deg, ${colors.primary['300']} 1.81%, ${colors.primary['400']} 98.19%)`,
      _dark: `linear-gradient(269deg, ${colors.primary['300']} 1.81%, ${colors.primary['400']} 98.19%)`,
    },

    'gradient-2': {
      // background: var(--surface-gradient-1, linear-gradient(269deg, #18888C 1.81%, #36BFBF 98.19%));
      default: `linear-gradient(269deg, ${colors.primary['600']} 1.81%, ${colors.primary['400']} 98.19%)`,
      _light: `linear-gradient(269deg, ${colors.primary['600']} 1.81%, ${colors.primary['400']} 98.19%)`,
      _dark: `linear-gradient(269deg, ${colors.primary['600']} 1.81%, ${colors.primary['400']} 98.19%)`,
    },
  },

  text: {
    brand: {
      primary: {
        default: colors.primary['700'],
        _light: colors.primary['700'],
        _dark: colors.primary['700'],
      },

      'primary-high-tone': {
        default: colors.primary['500'],
        _light: colors.primary['500'],
        _dark: colors.primary['500'],
      },

      'primary-mid-tone': {
        default: colors.primary['600'],
        _light: colors.primary['600'],
        _dark: colors.primary['600'],
      },

      secondary: {
        default: colors.secondary['700'],
        _light: colors.secondary['700'],
        _dark: colors.secondary['700'],
      },

      'secondary-high-tone': {
        default: colors.secondary['500'],
        _light: colors.secondary['500'],
        _dark: colors.secondary['500'],
      },

      'secondary-mid-tone': {
        default: colors.secondary['600'],
        _light: colors.secondary['600'],
        _dark: colors.secondary['600'],
      },
    },

    'high-tone': {
      default: colors.gray['500'],
      _light: colors.gray['500'],
      _dark: colors.gray['500'],
    },

    'mid-tone': {
      default: colors.gray['400'],
      _light: colors.gray['400'],
      _dark: colors.gray['400'],
    },

    primary: {
      default: colors.gray['900'],
      _light: colors.gray['900'],
      _dark: colors.gray['50'],
    },

    'invert-primary': {
      default: colors.gray['50'],
      _light: colors.gray['50'],
      _dark: colors.gray['900'],
    },

    secondary: {
      default: colors.gray['600'],
      _light: colors.gray['600'],
      _dark: colors.gray['200'],
    },

    'invert-secondary': {
      default: colors.gray['200'],
      _light: colors.gray['200'],
      _dark: colors.gray['600'],
    },

    success: {
      default: colors.green['500'],
      _light: colors.green['500'],
      _dark: colors.green['500'],
    },

    caution: {
      default: colors.orange['500'],
      _light: colors.orange['500'],
      _dark: colors.orange['500'],
    },

    error: {
      default: colors.red['500'],
      _light: colors.red['500'],
      _dark: colors.red['500'],
    },

    'error-low-contrast': {
      default: colors.red['600'],
      _light: colors.red['600'],
      _dark: colors.red['600'],
    },
  },
};
