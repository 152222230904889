// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// App - Other
import { detail, detailSB, labelSB, small } from '@/config/theme/components/text';

// ###########
// BUTTON BASE
// ###########

const baseStyle = defineStyle({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 120ms ease-in-out',

  _active: {
    transform: 'scale(0.97)',

    _disabled: {
      transform: 'scale(1)',
    },
  },

  _selected: {
    transform: 'scale(1)',
  },
});

// #################
// ICON BUTTON SIZES
// #################

const smSizeIconButton = defineStyle({
  py: 'xs !important',
  px: 'xs !important',
  gap: 'none',

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto',
  w: 'auto',
  minW: 'auto',

  '& svg.chakra-icon': {
    w: '16px',
    h: '16px',
  },
});

const mdSizeIconButton = defineStyle({
  py: 'sm !important',
  px: 'sm !important',
  gap: 'none',

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto',
  w: 'auto',
  minW: 'auto',

  '& svg.chakra-icon': {
    w: '20px',
    h: '20px',
  },
});

const lgSizeIconButton = defineStyle({
  py: 'sm !important',
  px: 'sm !important',
  gap: 'none',

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto',
  w: 'auto',
  minW: 'auto',

  '& svg.chakra-icon': {
    w: '24px',
    h: '24px',
  },
});

const oldSmSizeIconButton = defineStyle({
  borderRadius: 'sm',
  py: 'xs !important',
  px: 'xs !important',
  gap: 'none',

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto',
  w: 'auto',
  minW: '26px',
  minH: '26px',

  '& svg.chakra-icon': {
    w: '14px',
    h: '14px',
  },
});

const oldMdSizeIconButton = defineStyle({
  borderRadius: 'sm',
  py: 'sm !important',
  px: 'sm !important',
  gap: 'none',

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto',
  w: 'auto',
  minW: 'auto',

  '& svg.chakra-icon': {
    w: '20px',
    h: '20px',
  },
});

// ############
// BUTTON SIZES
// ############

const smSizeButton = defineStyle({
  borderRadius: 'sm',
  py: 'xs',
  px: 'sm',
  gap: 'none',
  ...small,

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto',
  w: 'auto',
  minW: 'auto',

  '&.with-left-icon': {
    '.chakra-button__icon': {
      me: 'xxs',
    },
  },

  '&.with-right-icon': {
    '.chakra-button__icon': {
      ms: 'xxs',
    },
  },

  '&.with-left-icon, &.with-right-icon': {
    '& svg.chakra-icon': {
      w: '10px',
      h: '10px',
    },
  },
});

const mdSizeButton = defineStyle({
  borderRadius: 'md',
  py: '6px',
  px: 'md',
  gap: 'none',
  ...detailSB,
  fontWeight: 500,

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto',
  w: 'auto',
  minW: 'auto',

  '&.with-left-icon': {
    '.chakra-button__icon': {
      me: 'xs',
    },
  },

  '&.with-right-icon': {
    '.chakra-button__icon': {
      ms: 'xs',
    },
  },

  '&.with-left-icon, &.with-right-icon': {
    '& svg.chakra-icon': {
      w: '14px',
      h: '14px',
    },
  },
});

const lgSizeButton = defineStyle({
  borderRadius: 'md',
  py: 'sm',
  px: 'lg',
  gap: 'sm',
  ...labelSB,

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto',
  w: 'auto',
  minW: 'auto',

  '&.with-left-icon': {
    '.chakra-button__icon': {
      me: 'none',
    },
  },

  '&.with-right-icon': {
    '.chakra-button__icon': {
      ms: 'none',
    },
  },

  '&.with-left-icon, &.with-right-icon': {
    '& svg.chakra-icon': {
      w: '18px',
      h: '18px',
    },
  },
});

const oldSmSizeButton = defineStyle({
  borderRadius: 'sm',
  py: '3px',
  px: '8px',
  gap: 'none',
  ...detail,

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto', // 25px
  w: 'auto',
  minW: 'auto',

  '&.with-left-icon': {
    '.chakra-button__icon': {
      me: 'xs',
    },
  },

  '&.with-right-icon': {
    '.chakra-button__icon': {
      ms: 'xs',
    },
  },

  '&.with-left-icon, &.with-right-icon': {
    '& svg.chakra-icon': {
      w: '14px',
      h: '14px',
    },
  },
});

const oldMdSizeButton = defineStyle({
  borderRadius: 'sm',
  py: '8px',
  px: '12px',
  gap: 'none',
  ...detail,

  // For some reason when this below is on baseStyle, it doesn't work
  h: 'auto', // 32px
  w: 'auto',
  minW: 'auto',

  '&.with-left-icon': {
    '.chakra-button__icon': {
      me: 'xs',
    },
  },

  '&.with-right-icon': {
    '.chakra-button__icon': {
      ms: 'xs',
    },
  },

  '&.with-left-icon, &.with-right-icon': {
    '& svg.chakra-icon': {
      w: '18px',
      h: '18px',
    },
  },
});

// ###############
// BUTTON VARIANTS
// ###############

const solidButtonVariant = defineStyle({
  border: 'none',
  bg: 'surface.brand.primary',
  color: 'text.invert-primary',

  _hover: {
    bg: 'surface.brand.primary-low-contrast',

    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },

  _disabled: {
    color: 'text.mid-tone',
    bg: 'surface.high-tone',
  },

  _active: {
    bg: 'surface.brand.primary-low-contrast',

    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },
});

const dangerButtonVariant = defineStyle({
  border: 'none',
  bg: 'surface.error',
  color: 'text.invert-primary',

  _hover: {
    bg: 'surface.error-secondary',

    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },

  _disabled: {
    color: 'text.mid-tone',
    bg: 'surface.high-tone',
  },

  _active: {
    bg: 'surface.error-secondary',

    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },
});

const outlineButtonVariant = defineStyle({
  border: 'none',
  boxShadow: '0 0 0 1px var(--chakra-colors-border-primary) inset',
  bg: 'transparent',
  color: 'text.high-tone',

  _hover: {
    bg: 'transparent',

    _disabled: {
      bg: 'transparent',
    },
  },

  _disabled: {
    bg: 'transparent',
  },

  _active: {
    bg: 'transparent',

    _disabled: {
      bg: 'transparent',
    },
  },
});

const ghostButtonVariant = defineStyle({
  border: 'none',
  bg: 'transparent',
  color: 'text.high-tone',

  _hover: {
    bg: 'surface.brand.invert-primary-low-tone',

    _disabled: {
      bg: 'transparent',
    },
  },

  _disabled: {
    border: 'none',
    bg: 'transparent',
    color: 'text.high-tone',
  },

  _active: {
    bg: 'var(--chakra-colors-surface-gradient-1), var(--chakra-colors-surface-brand-invert-primary-low-tone)',
    bgClip: 'text, border-box',
    color: 'transparent',

    '&.with-right-icon svg': {
      color: 'surface.brand.invert-primary-low-contrast',
    },

    '&.with-left-icon svg': {
      color: 'surface.brand.invert-primary-mid-contrast',
    },

    '& .chakra-icon': {
      color: 'surface.brand.invert-primary-mid-contrast',
    },

    _disabled: {
      border: 'none',
      bg: 'transparent',
      color: 'text.high-tone',
    },
  },
});

const subtleButtonVariant = defineStyle({
  border: 'none',
  bg: 'surface.brand.invert-primary-low-tone',
  color: 'text.brand.primary-high-tone',

  _hover: {
    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },

  _disabled: {
    border: 'none',
    color: 'text.mid-tone',
    bg: 'surface.high-tone',
  },

  _active: {
    bg: 'var(--chakra-colors-surface-gradient-1), var(--chakra-colors-surface-brand-invert-primary-low-tone)',
    bgClip: 'text, border-box',
    color: 'transparent',

    '&.with-right-icon svg': {
      color: 'surface.brand.invert-primary-low-contrast',
    },

    '&.with-left-icon svg': {
      color: 'surface.brand.invert-primary-mid-contrast',
    },

    '& .chakra-icon': {
      color: 'surface.brand.invert-primary-mid-contrast',
    },

    _disabled: {
      border: 'none',
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },
});

const subtleDangerButtonVariant = defineStyle({
  border: 'none',
  bg: 'surface.error-low-tone',
  color: 'text.error',

  _hover: {
    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },

  _disabled: {
    border: 'none',
    color: 'text.mid-tone',
    bg: 'surface.high-tone',
  },

  _active: {
    bg: 'surface.error-low-tone',
    color: 'text.error',

    '&.with-right-icon svg': {
      color: 'surface.error-secondary',
    },

    '&.with-left-icon svg': {
      color: 'surface.error-secondary',
    },

    '& .chakra-icon': {
      color: 'surface.error-secondary',
    },

    _disabled: {
      border: 'none',
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },
});

const iconButtonCircularVariant = defineStyle({
  border: 'none',
  borderRadius: 'full',
  bg: 'transparent',
  color: 'surface.invert-mid-tone',

  _hover: {
    bg: 'surface.high-tone',

    _disabled: {
      bg: 'transparent',
    },
  },

  _disabled: {
    bg: 'transparent',
  },

  _active: {
    bg: 'surface.high-tone',

    _disabled: {
      bg: 'transparent',
    },
  },
});

const oldSolidButtonVariant = defineStyle({
  border: 'none',
  bg: 'surface.brand.primary',
  color: 'text.invert-primary',

  _hover: {
    bg: 'surface.brand.primary-low-contrast',

    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },

  _disabled: {
    color: 'text.mid-tone',
    bg: 'surface.high-tone',
  },

  _active: {
    bg: 'surface.brand.primary-low-contrast',

    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },
});

const oldDangerButtonVariant = defineStyle({
  border: 'none',
  bg: 'surface.error-secondary',
  color: 'text.invert-primary',

  _hover: {
    bg: 'surface.error',

    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },

  _disabled: {
    color: 'text.mid-tone',
    bg: 'surface.high-tone',
  },

  _active: {
    bg: 'surface.error',

    _disabled: {
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },
});

const oldOutlineButtonVariant = defineStyle({
  border: 'none',
  boxShadow: '0 0 0 1px var(--chakra-colors-border-primary) inset',
  bg: 'transparent',
  color: 'text.high-tone',

  _hover: {
    bg: 'surface.brand.invert-primary-low-tone',

    _disabled: {
      boxShadow: '0 0 0 1px #E6E9EF inset',
      bg: 'transparent',
    },
  },

  _disabled: {
    bg: 'transparent',
  },

  _active: {
    bg: 'surface.brand.invert-primary-low-tone',

    _disabled: {
      boxShadow: '0 0 0 1px #E6E9EF inset',
      bg: 'transparent',
    },
  },
});

const oldGhostButtonVariant = defineStyle({
  border: 'none',
  bg: 'transparent',
  color: 'text.high-tone',

  _hover: {
    bg: 'surface.brand.invert-primary-low-tone',

    _disabled: {
      border: 'none',
      bg: 'transparent',
      color: 'text.high-tone',
    },
  },

  _disabled: {
    border: 'none',
    bg: 'transparent',
    color: 'text.high-tone',
  },

  _active: {
    bg: 'surface.brand.invert-primary-low-tone',

    _disabled: {
      border: 'none',
      bg: 'transparent',
      color: 'text.high-tone',
    },
  },
});

const oldSubtleButtonVariant = defineStyle({
  border: 'none',
  bg: 'surface.brand.invert-primary-low-tone',
  color: 'text.brand.primary-high-tone',

  _hover: {
    _disabled: {
      border: 'none',
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },

  _disabled: {
    border: 'none',
    color: 'text.mid-tone',
    bg: 'surface.high-tone',
  },

  _active: {
    bg: 'surface.brand.invert-primary-low-tone',

    _disabled: {
      border: 'none',
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },
});

const oldSubtleDangerButtonVariant = defineStyle({
  border: 'none',
  bg: 'surface.error-low-tone',
  color: 'text.error',

  _hover: {
    bg: 'surface.error-low-tone',
    color: 'text.error',

    _disabled: {
      border: 'none',
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },

  _disabled: {
    border: 'none',
    color: 'text.mid-tone',
    bg: 'surface.high-tone',
  },

  _active: {
    bg: 'surface.error-low-tone',
    color: 'text.error',

    _disabled: {
      border: 'none',
      color: 'text.mid-tone',
      bg: 'surface.high-tone',
    },
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes: {
    'button-sm': smSizeButton,
    'button-md': mdSizeButton,
    'button-lg': lgSizeButton,
    'button-old-sm': oldSmSizeButton,
    'button-old-md': oldMdSizeButton,
    'icon-sm': smSizeIconButton,
    'icon-md': mdSizeIconButton,
    'icon-lg': lgSizeIconButton,
    'icon-old-sm': oldSmSizeIconButton,
    'icon-old-md': oldMdSizeIconButton,
  },
  variants: {
    solid: solidButtonVariant,
    danger: dangerButtonVariant,
    outline: outlineButtonVariant,
    ghost: ghostButtonVariant,
    subtle: subtleButtonVariant,
    'subtle.danger': subtleDangerButtonVariant,
    'old.solid': oldSolidButtonVariant,
    'old.danger': oldDangerButtonVariant,
    'old.outline': oldOutlineButtonVariant,
    'old.ghost': oldGhostButtonVariant,
    'old.subtle': oldSubtleButtonVariant,
    'old.subtle.danger': oldSubtleDangerButtonVariant,
    circular: iconButtonCircularVariant,
  },
});

export const Button = buttonTheme;
