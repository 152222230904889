// 3rd
import { extendTheme } from '@chakra-ui/react';

// App - Other
import { foundations } from './foundations';
import { styles } from './styles';
import { components } from './components';

export const theme = extendTheme({
  ...foundations,
  styles,
  components,
  textStyles: {
    ...components.Heading.variants,
    ...components.Text.variants,
  },
});
