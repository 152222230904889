// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { body, detail } from '@/config/theme/components/text';

// #############
// TEXTAREA BASE
// #############

const baseStyle = defineStyle({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'sm',
  color: 'text.primary',
});

// #####
// SIZES
// #####

const smSize = defineStyle({
  py: 'sm',
  px: 'md',
  ...detail,
});

const mdSize = defineStyle({
  py: 'md',
  px: 'lg',
  ...body,
});

// ########
// VARIANTS
// ########

const outlineVariant = defineStyle({
  bg: 'surface.primary',
  border: 'none',
  boxShadow: '0 0 0 1px #D0D7DE inset',

  _placeholder: {
    color: 'text.mid-tone',
  },

  _hover: {
    boxShadow: '0 0 0 1px #57606A inset',
  },

  _focus: {
    boxShadow: '0 0 0 1px #2683FE inset',
  },
});

const textareaTheme = defineStyleConfig({
  baseStyle,
  variants: {
    outline: outlineVariant,
  },
  sizes: {
    sm: smSize,
    md: mdSize,
  },
});

export const Textarea = textareaTheme;
