/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: useMaterialReactTable
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/useMaterialReactTable.ts#L9)
 */

// App
import { useTableInstance } from './hooks/useTableInstance';
import { useTableOptions } from './hooks/useTableOptions';
import { type MRT_RowData, type MRT_TableInstance, type MRT_TableOptions } from './types';

export const useTable = <TData extends MRT_RowData>(
  tableOptions: MRT_TableOptions<TData>
): MRT_TableInstance<TData> => useTableInstance(useTableOptions(tableOptions));
