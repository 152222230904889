// 3rd
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { Collection } from '../types/collection';
import { castCollectionDtoToCollection, ZodCollectionDto } from './dtos/collection';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { COLLECTIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  collectionId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodGetCollectionRequestPayloadDto };
export type { RequestPayloadDto as GetCollectionRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  knowledgeCollection: ZodCollectionDto,
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetCollectionResponseDto };
export type { ResponseDto as GetCollectionResponseDto };

// #######
// Request
// #######

export const getCollection = async (id: string): Promise<Collection> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({ collectionId: id });
    const res = await apiClient.post(`/Collection/GetCollection`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return castCollectionDtoToCollection(parsedRes.knowledgeCollection);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCollection = {
  id?: string;
  enabled?: boolean;
};

export const useCollection = ({ id, enabled = true }: UseCollection = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: COLLECTIONS_QUERY_KEYS.collection(id!),
    queryFn: async () => await getCollection(id!),
    enabled: enabled && !!id,
  });

  return {
    collection: data,
    isFetchingCollection: isLoading || isFetching,
    didFetchingCollectionErrored: isLoadingError,
  };
};
