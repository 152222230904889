// React & Next
import { useMemo } from 'react';

// 3rd
import { Flex, MenuItem, Select } from '@chakra-ui/react';

// App - Types
import type { FetchRequest } from '@/types/request';
import type { CollectionStat } from '../../types/collection';

// App - Other
import Locale from '@/locale/en.json';
import { EyeIcon, EyeOffIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { Button } from '@/components/molecules/button';
import { DataTable, useTable } from '@/components/molecules/data-table';
import { columnsDefs } from './columns.defs';

const locale = Locale.features.collections['collections-table'];

type CollectionsTableProps = {
  collectionsStatsRequest: FetchRequest<CollectionStat[]>;
  onRowClick: (row: CollectionStat, isCtrlKeyPressed: boolean) => void;
  treatGoogleDriveAsOneDrive?: boolean;
};

export const CollectionsTable = ({
  collectionsStatsRequest,
  onRowClick,
  treatGoogleDriveAsOneDrive,
}: CollectionsTableProps) => {
  const { data: collectionsStats = [], isLoading, isLoadingError } = collectionsStatsRequest;
  // const tableConfig = useTableUrlPersistence({
  //   initialColumnFilters: [{ id: 'docs', value: [1] }],
  // });
  const columns = useMemo(
    () => columnsDefs(treatGoogleDriveAsOneDrive),
    [treatGoogleDriveAsOneDrive]
  );
  const table = useTable({
    columns,
    data: collectionsStats || [],
    initialState: {
      showGlobalFilter: true,
      columnFilters: [{ id: 'docs', value: [1] }],
    },
    // onColumnFiltersChange: tableConfig.setColumnFilters,
    // onGlobalFilterChange: tableConfig.setGlobalFilter,
    // onPaginationChange: tableConfig.setPagination,
    // onSortingChange: tableConfig.setSorting,
    state: {
      columnVisibility: { kind: false },
      showSkeletons: isLoading,
      showAlertBanner: isLoadingError,
      // columnFilters: tableConfig.columnFilters,
      // globalFilter: tableConfig.globalFilter,
      // sorting: tableConfig.sorting,
      // pagination: tableConfig.pagination,
    },
    enableRowActions: true,
    enableHiding: false,
    enableColumnActions: false,
    positionActionsColumn: 'last' as 'first' | 'last',
    renderRowActionMenuItems: () => [
      <MenuItem key="delete" isDisabled={true}>
        <Text variant="detail" color="text.error">
          {locale['Ignore - (coming soon)']}
        </Text>
      </MenuItem>,
    ],
    toolbarAlertBannerProps: isLoadingError
      ? {
          status: 'error',
          variant: 'subtle',
          children: (
            <Flex alignItems="center" h="100%" mb="-6px">
              <Text variant="detail">
                {locale['Whoops, something went wrong, contact support']}
              </Text>
            </Flex>
          ),
        }
      : {},
    renderTopToolbarCustomActions: ({ table }) => {
      const docsColFilterValue = (table.getColumn('docs').getFilterValue() || []) as number[];
      const isShowingOnlyCollectionsWithDocs =
        docsColFilterValue && docsColFilterValue.length ? docsColFilterValue[0] >= 1 : false;
      const rowsWithoutDocs = table.getPreFilteredRowModel().rows.filter((row) => {
        const filters = Object.entries(row.columnFilters);

        return (
          row.original.prioritizedItemsCount === 0 &&
          filters.every(([key, value]) => (key === 'docs' && !value) || !!value)
        );
      });

      const typeFilterValue = table.getColumn('kind').getFilterValue() as string;
      const documentTypeRows = table.getPreFilteredRowModel().rows.filter((row) => {
        const filters = Object.entries(row.columnFilters);

        return (
          row.original.kind === 'document' &&
          filters.every(([key, value]) => (key === 'kind' && !value) || !!value)
        );
      });
      const ticketsTypeRows = table.getPreFilteredRowModel().rows.filter((row) => {
        const filters = Object.entries(row.columnFilters);

        return (
          row.original.kind === 'ticket' &&
          filters.every(([key, value]) => (key === 'kind' && !value) || !!value)
        );
      });

      return (
        <Flex alignItems="center" gap="8px">
          <Text variant="detail">{locale['Show']}:</Text>

          <Select
            value={typeFilterValue || 'all'}
            onChange={(e) => {
              table.setColumnFilters((prev) => {
                const noneDocsFilters = prev.filter((f) => f.id !== 'kind');

                return e.target.value === 'all'
                  ? [...noneDocsFilters]
                  : [...noneDocsFilters, { id: 'kind', value: e.target.value }];
              });
            }}
            _focusVisible={
              typeFilterValue
                ? {
                    borderColor: 'button.outline.text',
                    bg: 'button.outline.hover.bg',
                  }
                : {}
            }
            sx={
              typeFilterValue
                ? {
                    borderColor: 'button.outline.text',
                    bg: 'button.outline.hover.bg',
                  }
                : {}
            }
            w="185px"
          >
            <option value="all">{locale['All sources']}</option>

            <option value="document">
              {locale['Documents sources']} ({`${documentTypeRows.length}`})
            </option>

            <option value="ticket">
              {locale['Tickets sources']} ({`${ticketsTypeRows.length}`})
            </option>
          </Select>

          <Text variant="detail">{locale['AND']}</Text>

          <Button
            variant="old.outline"
            aria-label="button"
            size="sm"
            isActive={isShowingOnlyCollectionsWithDocs}
            leftIcon={
              isShowingOnlyCollectionsWithDocs ? (
                <EyeOffIcon aria-label={locale['Only show collections with documents']} />
              ) : (
                <EyeIcon aria-label={locale['Hide collections without documents']} />
              )
            }
            sx={
              isShowingOnlyCollectionsWithDocs
                ? {
                    borderColor: 'button.outline.text',
                    bg: 'button.outline.hover.bg',
                  }
                : {}
            }
            onClick={() =>
              table.setColumnFilters((prev) => {
                const noneDocsFilters = prev.filter((f) => f.id !== 'docs');

                return isShowingOnlyCollectionsWithDocs
                  ? [...noneDocsFilters]
                  : [...noneDocsFilters, { id: 'docs', value: [1] }];
              })
            }
          >
            {locale['Empty workspaces']} ({`${rowsWithoutDocs.length}`})
          </Button>
        </Flex>
      );
    },
    tableBodyCellProps: {
      minH: '56px',
    },
    tableBodyRowProps: ({ row }) => ({
      onClick: (e) =>
        row && row.original ? onRowClick(row.original, e.metaKey || e.ctrlKey) : null,
      sx: {
        cursor: 'pointer',
        height: '56px',
      },
    }),
  });

  return <DataTable table={table} />;
};
