/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ToolbarDropZone
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/toolbar/MRT_ToolbarDropZone.tsx#L13)
 */

// React & Next
import { useEffect } from 'react';

// 3rd
import { Box } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

// App - Types
import { type MRT_RowData, type MRT_TableInstance } from '../../types';

// App - Other
import { alpha } from '@/utils/color';
import { theme } from '@/config/theme';
import { Text } from '@/components/atoms/typography';

interface Props<TData extends MRT_RowData> extends BoxProps {
  table: MRT_TableInstance<TData>;
}

export const ToolbarDropZone = <TData extends MRT_RowData>({ table, ...rest }: Props<TData>) => {
  const {
    getState,
    options: { enableGrouping, localization },
    setHoveredColumn,
    setShowToolbarDropZone,
  } = table;

  const { draggingColumn, grouping, hoveredColumn, showToolbarDropZone } = getState();

  const handleDragEnter = () => {
    setHoveredColumn({ id: 'drop-zone' });
  };

  useEffect(() => {
    if (table.options.state?.showToolbarDropZone !== undefined) {
      setShowToolbarDropZone(
        !!enableGrouping &&
          !!draggingColumn &&
          draggingColumn.columnDef.enableGrouping !== false &&
          !grouping.includes(draggingColumn.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableGrouping, draggingColumn, grouping]);

  return showToolbarDropZone ? (
    <Box
      onDragEnter={handleDragEnter}
      {...rest}
      sx={{
        alignItems: 'center',
        backdropFilter: 'blur(4px)',
        backgroundColor: alpha(
          theme.colors.primary[500],
          hoveredColumn?.id === 'drop-zone' ? 0.2 : 0.1
        ),
        border: `dashed ${theme.colors.primary[500]} 2px`,
        boxSizing: 'border-box',
        display: showToolbarDropZone ? 'flex' : 'none',
        height: '100%',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 4,
        ...(rest?.sx || {}),
      }}
    >
      <Text fontStyle="italic">
        {localization.dropToGroupBy.replace('{column}', draggingColumn?.columnDef?.header ?? '')}
      </Text>
    </Box>
  ) : null;
};
