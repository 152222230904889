// 3rd
import { progressAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { small, tiny } from './text';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// ########
// TAG BASE
// ########

const baseStyle = definePartsStyle({
  label: {
    color: 'text.primary',
  },

  track: {
    bg: 'surface.mid-tone',
    borderRadius: 'md',
  },

  filledTrack: {
    transition: 'all 180ms ease-in-out',
    borderTopEndRadius: 'none !important',
    borderBottomEndRadius: 'none !important',
  },
});

// #####
// SIZES
// #####

const smSize = definePartsStyle({
  label: {
    ...tiny,
  },

  track: {
    h: '6px',
  },

  filledTrack: {},
});

const mdSize = definePartsStyle({
  label: {
    ...small,
  },

  track: {
    h: '12px',
  },

  filledTrack: {},
});

// ########
// VARIANTS
// ########

const primaryVariant = definePartsStyle({
  label: {},

  track: {},

  filledTrack: {
    bgColor: 'surface.brand.primary-mid-contrast', // INSIGHT <=== this must be bgColor as otherwise it overrides background image if using hasStripe prop
  },
});

const secondaryVariant = definePartsStyle({
  label: {},

  track: {},

  filledTrack: {
    bgColor: 'surface.brand.secondary-mid-contrast', // INSIGHT <=== this must be bgColor as otherwise it overrides background image if using hasStripe prop
  },
});

export const progressVariants = { primary: primaryVariant, secondary: secondaryVariant } as const;
export const progressSizes = { sm: smSize, md: mdSize } as const;
export const progressDefaultProps = {
  variant: 'primary',
  size: 'md',
} as const;

const progressTheme = defineMultiStyleConfig({
  baseStyle,
  variants: progressVariants,
  sizes: progressSizes,
  defaultProps: progressDefaultProps,
} as const);

export const Progress = progressTheme;
