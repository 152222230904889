// 3rd
import { tagAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { small, label, detail } from './text';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// ########
// TAG BASE
// ########

const baseStyle = definePartsStyle({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'all 120ms ease-in-out',
    gap: 'xs',
  },

  closeButton: {
    m: 'none',
  },
});

// #####
// SIZES
// #####

const smSize = definePartsStyle({
  container: {
    py: 'xxs',
    px: 'xs',
    borderRadius: 'xs',

    '& .chakra-icon': {
      m: 'none',
      w: 'md',
      h: 'md',
    },
  },

  label: {
    m: 'auto',
    ...small,
  },

  closeButton: {
    w: 'md',
    h: 'md',
  },
});

const mdSize = definePartsStyle({
  container: {
    py: 'xxs',
    px: 'sm',
    borderRadius: 'sm',

    '& .chakra-icon': {
      m: 'none',
      w: 'lg',
      h: 'lg',
    },
  },

  label: {
    ...label,
  },

  closeButton: {
    w: 'lg',
    h: 'lg',
  },
});

const oldSmSize = definePartsStyle({
  container: {
    py: 'xs',
    px: 'xs',
    borderRadius: 'sm',

    '& .chakra-icon': {
      m: 'none',
      w: 'md',
      h: 'md',
    },
  },

  label: {
    ...small,
  },

  closeButton: {
    w: 'md',
    h: 'md',
  },
});

const oldMdSize = definePartsStyle({
  container: {
    py: 'xxs',
    px: 'sm',
    borderRadius: 'sm',

    '& .chakra-icon': {
      m: 'none',
      w: 'lg',
      h: 'lg',
    },
  },

  label: {
    ...detail,
  },

  closeButton: {
    w: 'lg',
    h: 'lg',
  },
});

// ########
// VARIANTS
// ########

const primaryVariant = definePartsStyle({
  container: {
    bg: 'surface.brand.invert-primary-low-tone',
    color: 'surface.brand.primary',
  },

  label: {
    color: 'text.brand.primary-high-tone',
  },

  closeButton: {
    color: 'surface.brand.primary',
  },
});

const secondaryVariant = definePartsStyle({
  container: {
    bg: 'surface.brand.secondary-low-tone',
    color: 'surface.brand.secondary',
  },

  label: {
    color: 'text.brand.secondary-high-tone',
  },

  closeButton: {
    color: 'surface.brand.secondary',
  },
});

const errorVariant = definePartsStyle({
  container: {
    bg: 'surface.error-low-tone',
    color: 'surface.error',
  },

  label: {
    color: 'text.error',
  },

  closeButton: {
    color: 'surface.error',
  },
});

const tagTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { primary: primaryVariant, secondary: secondaryVariant, error: errorVariant },
  sizes: {
    sm: smSize,
    md: mdSize,
    'old-sm': oldSmSize,
    'old-md': oldMdSize,
  },
});

export const Tag = tagTheme;
