/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHeadCellSortLabel
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHeadCellSortLabel.tsx#L18)
 */

// 3rd
import type { ComponentWithAs } from '@chakra-ui/react';

// App - Types
import type { MRT_Header, MRT_RowData, MRT_TableInstance } from '../../../types';
import type { TableSortLabelProps } from './table-sort-label';

// App - Other
import { Badge } from '@/components/molecules/badge';
import { TableSortLabel } from './table-sort-label';

interface Props<TData extends MRT_RowData> extends Partial<TableSortLabelProps> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableHeadCellSortLabel = <TData extends MRT_RowData>({
  header,
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { ArrowDownwardIcon, SortDefaultIcon },
      localization,
    },
  } = table;
  const { column } = header;
  const { columnDef } = column;
  const { isLoading, showSkeletons, sorting } = getState();

  const isSorted = !!column.getIsSorted();

  const sortTooltip =
    isLoading || showSkeletons
      ? ''
      : column.getIsSorted()
        ? column.getIsSorted() === 'desc'
          ? localization.sortedByColumnDesc.replace('{column}', columnDef.header)
          : localization.sortedByColumnAsc.replace('{column}', columnDef.header)
        : column.getNextSortingOrder() === 'desc'
          ? localization.sortByColumnDesc.replace('{column}', columnDef.header)
          : localization.sortByColumnAsc.replace('{column}', columnDef.header);

  return (
    <Badge
      badgeContent={sorting.length > 1 ? column.getSortIndex() + 1 : 0}
      overlap="circular"
      top="10%"
      left="32%"
    >
      <TableSortLabel
        icon={(!isSorted ? SortDefaultIcon : ArrowDownwardIcon) as ComponentWithAs<'svg'>}
        active
        aria-label={sortTooltip}
        direction={isSorted ? (column.getIsSorted() as 'asc' | 'desc') : undefined}
        onClick={(e) => {
          e.stopPropagation();
          header.column.getToggleSortingHandler()?.(e);
        }}
        {...rest}
        sx={{
          '& svg': {
            color: 'text.primary',
          },
          ...(rest?.sx || {}),
        }}
      />
    </Badge>
  );
};
