// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// App - Other
import { label } from '../text';

const baseStyle = defineStyle({
  ...label,
  color: 'text.secondary',
  m: 'none',
});

const formLabelTheme = defineStyleConfig({
  baseStyle,
});

export const FormLabel = formLabelTheme;
