/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHeadCellGrabHandle
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHeadCellGrabHandle.tsx)
 */

// React & Next
import type { DragEvent, RefObject } from 'react';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_Column, MRT_RowData, MRT_TableInstance } from '../../../types';

// App - Other
import { GrabHandleButton } from '../../../buttons/grab-handle-button';
import { parseFromValuesOrFunc, reorderColumn } from '../../../column.utils';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  column: MRT_Column<TData>;
  table: MRT_TableInstance<TData>;
  tableHeadCellRef: RefObject<HTMLTableCellElement>;
}

export const TableHeadCellGrabHandle = <TData extends MRT_RowData>({
  column,
  table,
  tableHeadCellRef,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: { enableColumnOrdering, columnDragHandleProps },
    setColumnOrder,
    setDraggingColumn,
    setHoveredColumn,
  } = table;
  const { columnDef } = column;
  const { columnOrder, draggingColumn, hoveredColumn } = getState();

  const iconButtonProps = {
    ...parseFromValuesOrFunc(columnDragHandleProps, { column, table }),
    ...parseFromValuesOrFunc(columnDef.columnDragHandleProps, {
      column,
      table,
    }),
    ...rest,
  };

  const handleDragStart = (event: DragEvent<HTMLButtonElement>) => {
    iconButtonProps?.onDragStart?.(event);
    setDraggingColumn(column);
    event.dataTransfer.setDragImage(tableHeadCellRef.current as HTMLElement, 0, 0);
  };

  const handleDragEnd = (event: DragEvent<HTMLButtonElement>) => {
    iconButtonProps?.onDragEnd?.(event);

    if (hoveredColumn?.id === 'drop-zone') {
      column.toggleGrouping();
    } else if (enableColumnOrdering && hoveredColumn && hoveredColumn?.id !== draggingColumn?.id) {
      setColumnOrder(reorderColumn(column, hoveredColumn as MRT_Column<TData>, columnOrder));
    }

    setDraggingColumn(null);
    setHoveredColumn(null);
  };

  return (
    <GrabHandleButton
      location="column"
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      table={table}
      {...iconButtonProps}
    />
  );
};
