/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableHeadCellFilterLabel
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/head/MRT_TableHeadCellFilterLabel.tsx#L20)
 */

// React & Next
import type { MouseEvent } from 'react';

// 3rd
import {
  Box,
  Popover,
  SlideFade,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_Header, MRT_RowData, MRT_TableInstance } from '../../../types';

// App - Other
import { IconButton } from '@/components/molecules/button';
import { TableHeadCellFilterContainer } from './table-head-cell-filter-container';

interface Props<TData extends MRT_RowData> extends Omit<Partial<IconButtonProps>, 'aria-label'> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableHeadCellFilterLabel = <TData extends MRT_RowData = object>({
  header,
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    options: {
      columnFilterDisplayMode,
      icons: { FilterAltIcon },
      localization,
    },
    refs: { filterInputRefs },
    setShowColumnFilters,
  } = table;
  const { column } = header;
  const { columnDef } = column;

  const filterValue = column.getFilterValue();

  const isFilterActive =
    (Array.isArray(filterValue) && filterValue.some(Boolean)) ||
    (!!filterValue && !Array.isArray(filterValue));

  const isRangeFilter =
    columnDef.filterVariant?.includes('range') ||
    ['between', 'betweenInclusive', 'inNumberRange'].includes(columnDef._filterFn);
  const currentFilterOption = columnDef._filterFn;
  const filterTooltip =
    columnFilterDisplayMode === 'popover' && !isFilterActive
      ? localization.filterByColumn?.replace('{column}', String(columnDef.header))
      : localization.filteringByColumn
          .replace('{column}', String(columnDef.header))
          .replace(
            '{filterType}',
            currentFilterOption
              ? (localization as unknown as { [key: string]: string })[
                  `filter${
                    currentFilterOption?.charAt(0)?.toUpperCase() + currentFilterOption?.slice(1)
                  }`
                ]
              : ''
          )
          .replace(
            '{filterValue}',
            `"${
              Array.isArray(filterValue)
                ? (filterValue as [string, string]).join(
                    `" ${isRangeFilter ? localization.and : localization.or} "`
                  )
                : (filterValue as string)
            }"`
          )
          .replace('" "', '');

  // TODO CHECK THIS ONE AS IT IS QUITE DIFFERENT THAN SOURCE
  return (
    <Popover closeOnBlur={true}>
      <SlideFade
        in={
          columnFilterDisplayMode === 'popover' ||
          (!!filterValue && !isRangeFilter) ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (isRangeFilter && (!!filterValue?.[0] || !!filterValue?.[1]))
        }
        unmountOnExit={true}
      >
        <Box as="span" sx={{ flex: '0 0' }}>
          <PopoverTrigger>
            <IconButton
              position="relative"
              boxSizing="border-box"
              backgroundColor="transparent"
              outline="0px"
              border="0px"
              margin="0px"
              px="0px"
              py="0px"
              borderRadius="0px"
              userSelect="none"
              verticalAlign="middle"
              appearance="none"
              textDecoration="none"
              color="inherit"
              cursor="pointer"
              display="inline-flex"
              justifyContent="flex-start"
              flexDirection="inherit"
              alignItems="center"
              minWidth="auto"
              flex="0 0 0%"
              opacity={0.3}
              {...iconButtonProps}
              _hover={{
                '& svg': {
                  opacity: 0.3,
                },
              }}
              _active={{
                '& svg': {
                  opacity: 1,
                },
              }}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                if (columnFilterDisplayMode !== 'popover') {
                  setShowColumnFilters(true);
                }

                queueMicrotask(() => {
                  filterInputRefs.current[`${column.id}-0`]?.focus?.();
                  (filterInputRefs.current[`${column.id}-0`] as HTMLInputElement)?.select?.();
                });

                event.stopPropagation();
              }}
              size="sm"
              icon={
                <FilterAltIcon
                  aria-label={filterTooltip}
                  style={{
                    width: '14px',
                    height: '14px',
                  }}
                />
              }
              sx={{
                // height: '18px',
                // width: '18px',
                opacity: isFilterActive ? 1 : 0.3,
                // transform: 'scale(0.75)',
                // transition: 'all 150ms ease-in-out',
                ...(iconButtonProps?.sx || {}),
              }}
              aria-label={filterTooltip}
            />
          </PopoverTrigger>
        </Box>
      </SlideFade>

      {columnFilterDisplayMode === 'popover' ? (
        <PopoverContent>
          <PopoverArrow />

          <PopoverBody>
            <Box sx={{ p: '16px' }}>
              <TableHeadCellFilterContainer header={header} table={table} />
            </Box>
          </PopoverBody>
        </PopoverContent>
      ) : null}
    </Popover>
  );
};
