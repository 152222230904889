// 3rd
import { selectAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    borderRadius: '4px',

    _focus: {
      borderColor: 'inherit',
      boxShadow: 'none',
    },

    _focusVisible: {
      borderColor: 'inherit',
      boxShadow: 'none',
    },

    _hover: {
      borderColor: 'inherit',
      boxShadow: 'none',
    },

    '&:focus-visible': {
      borderColor: 'inherit',
      boxShadow: 'none',
    },
  },
});

const size = {
  sm: defineStyle({
    fontSize: '12px',
    h: '24px',
    ps: '8px',
    pe: '24px',
  }),
  md: defineStyle({
    fontSize: '12px',
    h: '25px',
    ps: '8px',
    pe: '24px',
  }),
};

const sizes = {
  sm: definePartsStyle({
    field: size.sm,
    icon: {
      mt: '1px',
      right: '8px',
      w: '16px',
    },
  }),
  md: definePartsStyle({
    field: size.md,
    icon: {
      mt: '1px',
      right: '8px',
      w: '16px',
    },
  }),
};

const selectTheme = defineMultiStyleConfig({ baseStyle, sizes });

export const Select = selectTheme;
