// 3rd
import { useToken } from '@chakra-ui/react';
import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// App - Other
import { h4 } from '@/config/theme/components/heading';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// ###########
// DRAWER BASE
// ###########

const baseStyle = definePartsStyle(() => {
  const [borderColor] = useToken('colors', ['border.primary']);

  return {
    overlay: {},

    dialogContainer: {},

    dialog: {
      bg: 'surface.primary',
    },

    closeButton: {
      borderRadius: 'sm',
      top: '24px',
      right: '24px',
    },

    header: {
      gap: 'sm',
      ps: '32px',
      pe: '56px',
      pt: '32px',
      pb: '12px',

      ...h4,
    },

    body: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2xl',
      px: '32px',
      pb: '96px',
    },

    footer: {
      display: 'flex',
      gap: 'sm',
      px: '40px',
      py: '8px',
      border: `.5px solid ${borderColor}`,
    },
  };
});

// #####
// SIZES
// #####

const smSize = definePartsStyle({
  dialog: { maxW: '512px' },
});

const mdSize = definePartsStyle({
  dialog: { maxW: '672px' },
});

const lgSize = definePartsStyle({
  dialog: { maxW: '896px' },
});

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { sm: smSize, md: mdSize, lg: lgSize },
});

export const Drawer = drawerTheme;
