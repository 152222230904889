// React & Next
import { useMemo } from 'react';

// 3rd
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

// App - Types
import type { Priority } from '@/types/knowledge-item/priority';
import type { CollectionStat } from '../../types/collection';

// App - Other
import { PrioritiesBubbleChart } from '@/components/molecules/charts';

type CollectionsPrioritizedKnowledgeItemsPrioritiesBubbleChartProps = {
  collectionsStats: CollectionStat[];
};

export const CollectionsPrioritizedKnowledgeItemsPrioritiesBubbleChart = ({
  collectionsStats,
}: CollectionsPrioritizedKnowledgeItemsPrioritiesBubbleChartProps) => {
  const prioritiesDistribution = useMemo(() => {
    return collectionsStats.reduce(
      (acc, { prioritizedItemsPrioritiesToCount }) => {
        acc.high += prioritizedItemsPrioritiesToCount?.high || 0;
        acc.medium += prioritizedItemsPrioritiesToCount?.medium || 0;
        acc.low += prioritizedItemsPrioritiesToCount?.low || 0;

        return acc;
      },
      { high: 0, medium: 0, low: 0 } as { [key in Priority]: number }
    );
  }, [collectionsStats]);

  return (
    <PrioritiesBubbleChart
      size="medium"
      highCount={prioritiesDistribution.high}
      mediumCount={prioritiesDistribution.medium}
      lowCount={prioritiesDistribution.low}
    />
  );
};

const Loading = () => {
  return (
    <Flex justifyContent="space-between">
      <Flex direction="column" mr="16px" gap="8px" mt="24px">
        <Flex alignItems="center" gap="6px">
          <SkeletonCircle size="12px" />

          <Skeleton height="12px" width="35px" />
        </Flex>

        <Flex alignItems="center" gap="6px">
          <SkeletonCircle size="12px" />

          <Skeleton height="12px" width="35px" />
        </Flex>

        <Flex alignItems="center" gap="6px">
          <SkeletonCircle size="12px" />

          <Skeleton height="12px" width="35px" />
        </Flex>
      </Flex>

      <Flex direction="column" position="relative">
        <SkeletonCircle
          size="62px"
          position="absolute"
          top="10px"
          right="14px"
          startColor="#edf2f7"
          endColor="#a0aec0"
          opacity={1}
          zIndex={1}
        />

        <SkeletonCircle
          size="53px"
          position="absolute"
          top="37px"
          right="45px"
          startColor="#f7fafc"
          endColor="#a0aec0"
          opacity={1}
        />

        <SkeletonCircle
          size="44px"
          position="absolute"
          top="45px"
          right="-4px"
          startColor="#f7fafc"
          endColor="#a0aec0"
          opacity={1}
        />
      </Flex>
    </Flex>
  );
};

CollectionsPrioritizedKnowledgeItemsPrioritiesBubbleChart.Loading = Loading;
