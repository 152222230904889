/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableBodyRowPinButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/body/MRT_TableBodyRowPinButton.tsx#L16)
 */

// 3rd
import { Box } from '@chakra-ui/react';
import type { Row } from '@tanstack/react-table';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_Row, MRT_RowData, MRT_TableInstance } from '../../types';

// App - Other
import { RowPinButton } from '../../buttons/row-pin-button';
import { parseFromValuesOrFunc } from '../../column.utils';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableBodyRowPinButton = <TData extends MRT_RowData>({
  row,
  table,
  ...rest
}: Props<TData>) => {
  const {
    options: { enableRowPinning, rowPinningDisplayMode },
  } = table;
  const canPin = parseFromValuesOrFunc(enableRowPinning, row as Row<TData>);

  if (!canPin) return null;

  const rowPinButtonProps = {
    row,
    table,
    ...rest,
  };

  if (rowPinningDisplayMode === 'top-and-bottom' && !row.getIsPinned()) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <RowPinButton pinningPosition="top" {...rowPinButtonProps} />

        <RowPinButton pinningPosition="bottom" {...rowPinButtonProps} />
      </Box>
    );
  }

  return (
    <RowPinButton
      pinningPosition={rowPinningDisplayMode === 'bottom' ? 'bottom' : 'top'}
      {...rowPinButtonProps}
    />
  );
};
