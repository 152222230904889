/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ToggleGlobalFilterButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_ToggleGlobalFilterButton.tsx#L9)
 */

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { IconButton } from '@/components/molecules/button';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  table: MRT_TableInstance<TData>;
}

export const ToggleGlobalSearchButton = <TData extends MRT_RowData>({
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { SearchIcon, SearchOffIcon },
      localization,
    },
    refs: { searchInputRef },
    setShowGlobalFilter,
  } = table;
  const { globalFilter, showGlobalFilter } = getState();

  const handleToggleSearch = () => {
    setShowGlobalFilter(!showGlobalFilter);
    queueMicrotask(() => searchInputRef.current?.focus());
  };

  return (
    <IconButton
      variant="old.ghost"
      size="sm"
      icon={
        showGlobalFilter ? (
          <SearchOffIcon aria-label={iconButtonProps?.title ?? localization.showHideSearch} />
        ) : (
          <SearchIcon aria-label={iconButtonProps?.title ?? localization.showHideSearch} />
        )
      }
      aria-label={iconButtonProps?.title ?? localization.showHideSearch}
      isDisabled={!!globalFilter}
      onClick={handleToggleSearch}
      {...iconButtonProps}
    />
  );
};
