// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// #########
// LINK BASE
// #########

const baseStyle = defineStyle({
  transition: 'all 120ms ease-in-out',
  color: 'inherit',

  _hover: {
    textDecoration: 'none',
  },
});

export const linkTheme = defineStyleConfig({
  baseStyle,
});

export const Link = linkTheme;
