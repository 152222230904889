export const shadows = {
  // xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
  // sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  // base: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  // md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  // lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  // xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  // '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  // outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  // inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  // none: 'none',
  // 'dark-lg':
  //   'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px',

  none: 'none',
  xs: '0px 1px 3px 0px rgba(1, 0, 16, 0.06)',
  sm: '0px 2px 5px 0px rgba(1, 0, 16, 0.06)',
  md: '0px 4px 8px 0px rgba(1, 0, 16, 0.04), 0px 1px 3px 0px rgba(1, 0, 16, 0.06)',
  lg: '0px 6px 14px 0px rgba(1, 0, 16, 0.04), 0px 2px 4px 0px rgba(1, 0, 16, 0.06)',
  xl: '0px 10px 16px 0px rgba(1, 0, 16, 0.02), 0px 8px 14px 0px rgba(1, 0, 16, 0.04), 0px 2px 4px 0px rgba(1, 0, 16, 0.06)',

  'circular-xs': '0px 0px 3px 3px rgba(1, 0, 16, 0.06)',
  'circular-sm': '0px 0px 5px 5px rgba(1, 0, 16, 0.06)',
  'circular-md': '0px 0px 8px 8px rgba(1, 0, 16, 0.04), 0px 1px 3px 0px rgba(1, 0, 16, 0.06)',
  'circular-lg': '0px 0px 14px 14px rgba(1, 0, 16, 0.04), 0px 2px 4px 0px rgba(1, 0, 16, 0.06)',
  'circular-xl':
    '0px 0px 16px 16px rgba(1, 0, 16, 0.02), 0px 8px 14px 0px rgba(1, 0, 16, 0.04), 0px 2px 4px 0px rgba(1, 0, 16, 0.06)',

  // Below are from Figma
  'table-row': '0px 2px 4px 0px rgba(0, 0, 0, 0.12)',
  'document-drawer-footer': '0px -2px 8px 0px rgba(31, 35, 40, 0.08)',
};

export const shadowTokens = {
  'elevation-0': shadows.none,
  'elevation-1': shadows.xs,
  'elevation-2': shadows.sm,
  'elevation-3': shadows.md,
  'elevation-4': shadows.lg,
  'elevation-5': shadows.xl,

  'circular-elevation-1': shadows['circular-xs'],
  'circular-elevation-2': shadows['circular-sm'],
  'circular-elevation-3': shadows['circular-md'],
  'circular-elevation-4': shadows['circular-lg'],
  'circular-elevation-5': shadows['circular-xl'],
};
