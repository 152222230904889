// React & Next
import { createElement } from 'react';
import type { ReactNode } from 'react';

// 3rd
import type { ComponentWithAs } from '@chakra-ui/react';

// App - Types
import type { IconProps } from '@/components/atoms/icon/icons/icon';
import type { ButtonProps } from '@/components/molecules/button';

// App - Other
import { Button } from '@/components/molecules/button';

export type TableSortLabelProps = Omit<ButtonProps, 'children'> & {
  active?: boolean;
  children?: ReactNode;
  direction?: 'asc' | 'desc';
  hideSortIcon?: boolean;
  icon: ComponentWithAs<'svg', IconProps>;
};

export const TableSortLabel = ({
  active,
  children,
  direction,
  hideSortIcon,
  icon,
  ...buttonProps
}: TableSortLabelProps) => {
  return (
    <Button
      position="relative"
      boxSizing="border-box"
      backgroundColor="transparent"
      outline="0px"
      border="0px"
      margin="0px"
      borderRadius="0px"
      px="0px"
      py="0px"
      h="36px"
      userSelect="none"
      verticalAlign="middle"
      appearance="none"
      textDecoration="none"
      cursor="pointer"
      display="inline-flex"
      justifyContent="flex-start"
      flexDirection="inherit"
      alignItems="center"
      minWidth="auto"
      flex="0 0 0%"
      {...buttonProps}
      _hover={{
        '& svg': {
          opacity: 0.5,
        },
      }}
      _active={{
        '& svg': {
          opacity: 1,
        },
      }}
    >
      {children}

      {hideSortIcon && !active
        ? null
        : createElement(icon, {
            size: '2xs',
            ...(direction === 'desc' ? { transform: 'rotate(0deg)' } : null),
            ...(direction === 'asc' ? { transform: 'rotate(180deg)' } : null),
            'aria-label': active ? 'sorted' : 'unsorted',
          })}
    </Button>
  );
};
