/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_RowActionMenu
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/menus/MRT_RowActionMenu.tsx#L25)
 */

// React & Next
import { type MouseEvent } from 'react';

// 3rd
import { MenuList, MenuItem, Box } from '@chakra-ui/react';

// App
import { commonListItemStyles, commonMenuItemStyles } from './column-action-menu';
import { parseFromValuesOrFunc } from '../column.utils';
import { type MRT_Row, type MRT_RowData, type MRT_TableInstance } from '../types';

interface Props<TData extends MRT_RowData> {
  handleEdit: (event: MouseEvent) => void;
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}

export const RowActionMenu = <TData extends MRT_RowData>({
  handleEdit,
  row,
  table,
}: Props<TData>) => {
  const {
    options: {
      editDisplayMode,
      enableEditing,
      icons: { EditIcon },
      localization,
      renderRowActionMenuItems,
    },
  } = table;

  return (
    <MenuList zIndex={99999}>
      {parseFromValuesOrFunc(enableEditing, row) && ['modal', 'row'].includes(editDisplayMode!) && (
        <MenuItem
          onClick={handleEdit}
          icon={<EditIcon aria-label={localization.edit} />}
          sx={commonMenuItemStyles}
        >
          <Box sx={commonListItemStyles}>{localization.edit}</Box>
        </MenuItem>
      )}

      {renderRowActionMenuItems?.({
        row,
        table,
      })}
    </MenuList>
  );
};
