// 3rd
import { statAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { small } from '@/config/theme/components/text';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

// ########
// TAG BASE
// ########

const baseStyle = definePartsStyle({
  container: {},
  label: {},
  helpText: {},
  number: {},
  icon: {},
});

// #####
// SIZES
// #####

// const smSize = definePartsStyle({
//   container: {},
//   label: {
//     ...tiny,
//   },
//   helpText: {
//     '& > svg.chakra-icon': {
//       w: '10px',
//       h: '10px',
//     },
//   },
//   number: {},
//   icon: {},
// });

const trendMdSize = definePartsStyle({
  container: {},
  label: {},
  helpText: {
    ...small,

    gap: '2px',
    px: '4px',
    py: '4px',
    borderRadius: '4px',

    '& > svg.chakra-icon': {
      w: '12px',
      h: '12px',
    },
  },
  number: {},
  icon: {},
});

// ########
// VARIANTS
// ########

const trendVariant = definePartsStyle({
  container: {
    w: 'fit-content',
    minW: 'fit-content',
    maxW: 'fit-content',
  },
  label: {},
  helpText: {
    bg: '#F5F7F9',
    color: 'text.high-tone',
    mb: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  number: {},
  icon: {},
});

const trendColorfulVariant = definePartsStyle({
  container: {
    w: 'fit-content',
    minW: 'fit-content',
    maxW: 'fit-content',
  },
  label: {},
  helpText: {
    mb: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&[data-trend="static"]': {
      bg: '#F5F7F9',
      color: 'text.high-tone',
    },

    '&[data-trend="positive"]': {
      bg: 'surface.brand.invert-primary-low-tone',
      color: 'text.brand.primary-high-tone',
    },

    '&[data-trend="negative"]': {
      bg: 'surface.error-low-tone',
      color: 'text.error-low-contrast',
    },
  },
  number: {},
  icon: {},
});

const statTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    trend: trendVariant,
    'trend.colorful': trendColorfulVariant,
  },
  sizes: {
    // sm: smSize,
    'trend.md': trendMdSize,
  },
});

export const Stat = statTheme;
