// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// App - Other
import { label } from '../text';

const baseStyle = defineStyle({
  text: {
    ...label,
    color: 'text.error',
    m: 'none',
  },

  icon: {},
});

export const formErrorTheme = defineStyleConfig({
  baseStyle,
});

export const FormError = formErrorTheme;
