// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const h1 = defineStyle({
  fontSize: '5xl',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'summit',

  '& .chakra-icon': {
    w: '38px',
    h: '38px',
  },
});

export const h2 = defineStyle({
  fontSize: '4xl',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'taller',

  '& .chakra-icon': {
    w: '24px',
    h: '24px',
  },
});

export const h3 = defineStyle({
  fontSize: '3xl',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'tall',

  '& .chakra-icon': {
    w: '18px',
    h: '18px',
  },
});

export const h4 = defineStyle({
  fontSize: '2xl',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'elevated',

  '& .chakra-icon': {
    w: '16px',
    h: '16px',
  },
});

export const title = defineStyle({
  fontSize: 'xl',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'base',

  '& .chakra-icon': {
    w: '14px',
    h: '14px',
  },
});

export const headingTheme = defineStyleConfig({
  variants: {
    h1,
    h2,
    h3,
    h4,
    title,
  },
  defaultProps: {
    variant: 'title',
  },
});

export const Heading = headingTheme;
