/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_EditCellTextField
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/inputs/MRT_EditCellTextField.tsx#L22)
 */

// React & Next
import { useState } from 'react';
import type { MouseEvent, ChangeEvent, FocusEvent, KeyboardEvent } from 'react';

// 3rd
import { Select } from '@chakra-ui/react';

// App - Types
import type { InputProps } from '@/components/molecules/form';
import type { MRT_Cell, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { Field, Input } from '@/components/molecules/form';
import { getValueAndLabel, parseFromValuesOrFunc } from '../column.utils';

interface Props<TData extends MRT_RowData> extends InputProps {
  cell: MRT_Cell<TData>;
  table: MRT_TableInstance<TData>;
}

export const EditCellInput = <TData extends MRT_RowData>({
  cell,
  table,
  ...inputProps
}: Props<TData>) => {
  const {
    getState,
    options: { createDisplayMode, editDisplayMode, editInputProps },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table;
  const { column, row } = cell;
  const { columnDef } = column;
  const { creatingRow, editingRow } = getState();

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;
  const isSelectEdit = columnDef.editVariant === 'select';

  const [value, setValue] = useState(() => cell.getValue<string>());

  const _inputProps: InputProps = {
    ...parseFromValuesOrFunc(editInputProps, {
      cell,
      column,
      row,
      table,
    }),
    ...parseFromValuesOrFunc(columnDef.editInputProps, {
      cell,
      column,
      row,
      table,
    }),
    ...inputProps,
  };

  const saveInputValueToRowCache = (newValue: string) => {
    (row._valuesCache as { [key: string]: string })[column.id] = newValue;

    if (isCreating) {
      setCreatingRow(row);
    } else if (isEditing) {
      setEditingRow(row);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    _inputProps.onChange?.(event as ChangeEvent<HTMLInputElement>);
    setValue(event.target.value);

    if (isSelectEdit) {
      saveInputValueToRowCache(event.target.value);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
    _inputProps.onBlur?.(event as FocusEvent<HTMLInputElement>);
    saveInputValueToRowCache(value);
    setEditingCell(null);
  };

  const handleEnterKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
    _inputProps.onKeyDown?.(event as KeyboardEvent<HTMLInputElement>);

    if (event.key === 'Enter' && !event.shiftKey) {
      editInputRefs.current[column.id]?.blur();
    }
  };

  if (columnDef.Edit) {
    return <>{columnDef.Edit?.({ cell, column, row, table })}</>;
  }

  return (
    <Field
      gap={
        ['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
          ? 'xs'
          : 'none'
      }
      label={
        ['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
          ? columnDef.header
          : ''
      }
      htmlFor={column.id}
      isDisabled={parseFromValuesOrFunc(columnDef.enableEditing, row) === false}
    >
      {isSelectEdit ? (
        <Select
          id={column.id}
          name={column.id}
          placeholder={
            !['custom', 'modal'].includes(
              (isCreating ? createDisplayMode : editDisplayMode) as string
            )
              ? columnDef.header
              : undefined
          }
          size="sm"
          value={value}
          ref={(ref) => {
            if (ref) {
              editInputRefs.current[column.id] = ref;

              // if (_inputProps.inputRef) {
              //   _inputProps.inputRef = inputRef;
              // }
            }
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={(e) => {
            e.stopPropagation();
            _inputProps?.onClick?.(e as unknown as MouseEvent<HTMLInputElement>);
          }}
          onKeyDown={handleEnterKeyDown}
        >
          {_inputProps.children ??
            columnDef?.editSelectOptions?.map((option) => {
              const { label, value } = getValueAndLabel(option);

              return (
                <option
                  key={value}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '8px',
                    margin: 0,
                  }}
                  value={value}
                >
                  {label}
                </option>
              );
            })}
        </Select>
      ) : (
        <Input
          ref={(ref: HTMLInputElement) => {
            if (ref) {
              editInputRefs.current[column.id] = ref;

              // if (_inputProps.inputRef) {
              //   _inputProps.inputRef = inputRef;
              // }
            }
          }}
          id={column.id}
          name={column.id}
          placeholder={
            !['custom', 'modal'].includes(
              (isCreating ? createDisplayMode : editDisplayMode) as string
            )
              ? columnDef.header
              : undefined
          }
          variant="old.outline"
          size="sm"
          value={value}
          {..._inputProps}
          // InputProps={{
          //   disableUnderline: editDisplayMode === 'table',
          //   ..._inputProps.InputProps,
          //   sx: (theme) => ({
          //     mb: 0,
          //     ...(parseFromValuesOrFunc(_inputProps?.InputProps?.sx, theme) as any),
          //   }),
          // }}
          autoComplete="new-password" // disable autocomplete and autofill
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={(e) => {
            e.stopPropagation();
            _inputProps?.onClick?.(e);
          }}
          onKeyDown={handleEnterKeyDown}
        >
          {_inputProps.children}
        </Input>
      )}
    </Field>
  );
};
