// React & Next
import { createElement } from 'react';

// 3rd
import { Box, Flex } from '@chakra-ui/react';

// App - Types
import type { Priority } from '@/types/knowledge-item/priority';
import type { MRT_ColumnDef } from '@/components/molecules/data-table';
import type { CollectionStat } from '../../types/collection';

// App - Other
import Locale from '@/locale/en.json';
import { toPascalCase } from '@/utils/string';
import { DocumentIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';
import { IconBox } from '@/components/molecules/icon-box';
import {
  translatePriorityToColor,
  translatePriorityToBgColor,
} from '@/components/translators/knowledge-item/priority';
import { translateKnowledgeSourceToIcon } from '@/components/translators/integration/knowledge-source';

const locale = Locale.features.collections['collections-table'];

export const columnsDefs = (
  treatGoogleDriveAsOneDrive?: boolean
): MRT_ColumnDef<CollectionStat>[] => [
  {
    id: 'kind',
    header: '',
    accessorKey: 'kind',
    size: 0,
  },
  {
    id: 'workspace',
    header: locale['Workspace'],
    accessorKey: 'name',
    size: 250,
    Cell: ({ cell, row }) => {
      const name = cell.getValue<string>();
      const { source } = row.original;
      const sourceIcon = createElement(
        translateKnowledgeSourceToIcon(source, treatGoogleDriveAsOneDrive),
        {
          size: 'xs',
          'aria-label': locale['Item’s source'],
        }
      );

      return (
        <Flex alignItems="center" gap="8px">
          <IconBox
            bg="surface.brand.invert-primary-low-tone"
            w="28px"
            h="28px"
            minW="28px"
            minH="28px"
            borderRadius="md"
          >
            {sourceIcon}
          </IconBox>

          <Text variant="detail">{name}</Text>
        </Flex>
      );
    },
  },
  {
    id: 'docs',
    header: locale['Prioritized documents'],
    accessorKey: 'prioritizedItemsCount',
    filterVariant: 'range',
    size: 185,
    Cell: ({ cell, row }) => {
      const prioritizedDocumentsCount = cell.getValue<number>();
      const totalDocumentsCount = row.original.totalItemsCount;

      return (
        <Flex alignItems="center" gap="2px">
          <DocumentIcon
            size="xs"
            color="text.high-tone"
            aria-label={locale['Prioritized documents']}
          />

          <Text variant="detail">{prioritizedDocumentsCount}</Text>

          <Text variant="small" color="#A1A1A9">
            / {totalDocumentsCount}
          </Text>
        </Flex>
      );
    },
  },
  {
    id: 'priority',
    header: locale['Priority'],
    accessorKey: 'prioritizedItemsPrioritiesToCount',
    size: 185,
    Cell: ({ cell }) => {
      const documentsPrioritiesCount = cell.getValue<{ [key in Priority]?: number }>();
      // const dotStyles: Record<string, { borderColor: string; outlineColor: string }> = {
      //   HIGH: {
      //     borderColor: '#FF5B5B',
      //     outlineColor: '#FDE2E2',
      //   },
      //   MEDIUM: {
      //     borderColor: '#F8B200',
      //     outlineColor: '#FDFAE2',
      //   },
      //   LOW: {
      //     borderColor: '#2683FE',
      //     outlineColor: '#E2EAFD',
      //   },
      // };
      const prioritiesCellValue = Object.entries(documentsPrioritiesCount)
        .sort(([aKey], [bKey]) => {
          const order: { [key in Priority]: number } = { high: 1, medium: 2, low: 3, UNKNOWN: 4 };

          return order[aKey as Priority] - order[bKey as Priority];
        })
        .flatMap(([priority, count]) => {
          const borderColor = translatePriorityToColor(priority as Priority);
          const outlineColor = translatePriorityToBgColor(priority as Priority);

          if (!borderColor || !outlineColor) return [];

          return [
            <Flex key={priority} alignItems="center" gap="4px">
              <Box
                borderWidth="3px"
                borderStyle="solid"
                borderColor={borderColor}
                outline={`2px solid ${outlineColor}`}
                borderRadius="50%"
              />

              <Text variant="detail" color="text.high-tone">
                {toPascalCase(priority)}
              </Text>

              <Text variant="detail" color="rgba(87, 96, 106, 0.50)">
                ({count})
              </Text>
            </Flex>,
          ];
        });

      if (!prioritiesCellValue || !prioritiesCellValue.length) return <></>;

      return (
        <Flex direction="column" alignItems="flex-start" gap="4px">
          {prioritiesCellValue}
        </Flex>
      );
    },
    sortingFn: (rowA, rowB) => {
      function customSort(a: { [key in Priority]?: number }, b: { [key in Priority]?: number }) {
        // Assign a default value of 0 if properties are undefined
        const aHigh = a.high || 0;
        const aMedium = a.medium || 0;
        const aLow = a.low || 0;

        const bHigh = b.high || 0;
        const bMedium = b.medium || 0;
        const bLow = b.low || 0;

        // Compare HIGH values first
        if (aHigh > bHigh) return -1;
        if (aHigh < bHigh) return 1;

        // If HIGH values are equal, compare MEDIUM values
        if (aMedium > bMedium) return -1;
        if (aMedium < bMedium) return 1;

        // If MEDIUM values are equal, compare LOW values
        if (aLow > bLow) return -1;
        if (aLow < bLow) return 1;

        // If all values are equal, maintain original order
        return 0;
      }

      return customSort(
        rowB.original.prioritizedItemsPrioritiesToCount,
        rowA.original.prioritizedItemsPrioritiesToCount
      );
    },
  },
];
