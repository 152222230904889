// React & Next
import { type CSSProperties } from 'react';

// 3rd
import { ColorModeContextType, TableCellProps } from '@chakra-ui/react';

// App
import { theme } from '@/config/theme';
import { lighten, alpha, darken } from '@/utils/color';
import {
  getIsFirstRightPinnedColumn,
  getIsLastLeftPinnedColumn,
  getTotalRight,
  parseFromValuesOrFunc,
} from './column.utils';
import {
  type MRT_Column,
  type MRT_Header,
  type MRT_RowData,
  type MRT_TableInstance,
} from './types';

export const parseCSSVarId = (id: string) => id.replace(/[^a-zA-Z0-9]/g, '_');

export const getTableTheme = <TData extends MRT_RowData>(
  table: MRT_TableInstance<TData>,
  colorModeContext: ColorModeContextType
) => ({
  // baseBackgroundColor: colorModeContext.colorMode === 'dark' ? lighten('#fff', 0.05) : '#fff',
  baseBackgroundColor: 'surface.primary',
  baseHeadRowColor: colorModeContext.colorMode === 'dark' ? '#fff' : '#fff',
  draggingBorderColor: '#FFF',
  matchHighlightColor:
    colorModeContext.colorMode === 'dark' ? darken('#e65100', 0.25) : lighten('#7d7dff', 0.5),
  pinnedRowBackgroundColor: alpha('#FFF', 0.1),
  // selectedRowBackgroundColor: alpha('#36BFBF', 0.2),
  // selectedRowBackgroundColor: '#fff',
  selectedRowBackgroundColor: 'surface.primary',
  ...parseFromValuesOrFunc(table.options.tableTheme, colorModeContext),
});

export const getCommonTableCellStyles = <TData extends MRT_RowData>({
  column,
  header,
  table,
  tableCellProps,
  colorModeContext,
}: {
  column: MRT_Column<TData>;
  header?: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
  tableCellProps: TableCellProps;
  colorModeContext: ColorModeContextType;
}) => {
  const {
    options: { layoutMode },
  } = table;
  const widthStyles: CSSProperties = {
    minWidth: `max(calc(var(--${header ? 'header' : 'col'}-${parseCSSVarId(
      header?.id ?? column.id
    )}-size) * 1px), ${column.columnDef.minSize ?? 20}px)`,
    width: `calc(var(--${header ? 'header' : 'col'}-${parseCSSVarId(
      header?.id ?? column.id
    )}-size) * 1px)`,
  };

  if (layoutMode === 'grid') {
    widthStyles.flex = `var(--${header ? 'header' : 'col'}-${parseCSSVarId(
      header?.id ?? column.id
    )}-size) 0 auto`;
  } else if (layoutMode === 'grid-no-grow') {
    widthStyles.flex = '0 0 auto';
  }

  return {
    backgroundColor:
      column.getIsPinned() && column.columnDef.columnDefType !== 'group'
        ? alpha(
            darken(
              getTableTheme(table, colorModeContext).baseBackgroundColor,
              colorModeContext.colorMode === 'dark' ? 0.05 : 0.01
            ),
            0.97
          )
        : getTableTheme(table, colorModeContext).baseBackgroundColor,
    backgroundImage: 'inherit',
    boxShadow: getIsLastLeftPinnedColumn(table, column)
      ? `-4px 0 8px -6px ${alpha(theme.colors.gray[700], 0.5)} inset`
      : getIsFirstRightPinnedColumn(column)
        ? `4px 0 8px -6px ${alpha(theme.colors.gray[700], 0.5)} inset`
        : undefined,
    display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
    left: column.getIsPinned() === 'left' ? `${column.getStart('left')}px` : undefined,
    ml:
      table.options.enableColumnVirtualization &&
      column.getIsPinned() === 'left' &&
      column.getPinnedIndex() === 0
        ? `-${column.getSize() * (table.getState().columnPinning.left?.length ?? 1)}px`
        : undefined,
    mr:
      table.options.enableColumnVirtualization &&
      column.getIsPinned() === 'right' &&
      column.getPinnedIndex() === table.getVisibleLeafColumns().length - 1
        ? `-${column.getSize() * (table.getState().columnPinning.right?.length ?? 1) * 1.2}px`
        : undefined,
    opacity:
      table.getState().draggingColumn?.id === column.id ||
      table.getState().hoveredColumn?.id === column.id
        ? 0.5
        : 1,
    position:
      column.getIsPinned() && column.columnDef.columnDefType !== 'group' ? 'sticky' : undefined,
    right: column.getIsPinned() === 'right' ? `${getTotalRight(table, column)}px` : undefined,
    transition: 'all 120ms ease-in-out',
    ...widthStyles,
    ...(tableCellProps?.sx || {}),
  };
};

export const getCommonToolbarStyles = <TData extends MRT_RowData>({
  table,
  colorModeContext,
}: {
  table: MRT_TableInstance<TData>;
  colorModeContext: ColorModeContextType;
}) => ({
  alignItems: 'flex-start',
  backgroundColor: getTableTheme(table, colorModeContext).baseBackgroundColor,
  display: 'grid',
  flexWrap: 'wrap-reverse',
  minHeight: '40px',
  // TODO ORIGINALLY OVERFLOW WAS SET BUT IT CAUSES PROBLEMS WITH THE TOOLBAR
  // overflow: 'hidden',
  position: 'relative',
  transition: 'all 150ms ease-in-out',
  zIndex: 1,
});
