/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_CopyButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_CopyButton.tsx#L16)
 */

// React & Next
import { useState } from 'react';
import type { MouseEvent } from 'react';

// 3rd
import { Button } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Cell, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { Tooltip } from '@/components/molecules/tooltips';
import { parseFromValuesOrFunc } from '../column.utils';

interface Props<TData extends MRT_RowData> extends ButtonProps {
  cell: MRT_Cell<TData>;
  table: MRT_TableInstance<TData>;
}

export const CopyButton = <TData extends MRT_RowData>({
  cell,
  table,
  ...buttonProps
}: Props<TData>) => {
  const {
    options: { localization, copyButtonProps },
  } = table;
  const { column, row } = cell;
  const { columnDef } = column;

  const [copied, setCopied] = useState(false);

  const handleCopy = (event: MouseEvent, text: unknown) => {
    event.stopPropagation();

    navigator.clipboard.writeText(text as string);
    setCopied(true);
    setTimeout(() => setCopied(false), 4000);
  };

  const _buttonProps = {
    ...parseFromValuesOrFunc(copyButtonProps, {
      cell,
      column,
      row,
      table,
    }),
    ...parseFromValuesOrFunc(columnDef.copyButtonProps, {
      cell,
      column,
      row,
      table,
    }),
    ...buttonProps,
  };

  return (
    <Tooltip
      openDelay={300}
      placement="top"
      content={
        _buttonProps?.title ?? (copied ? localization.copiedToClipboard : localization.clickToCopy)
      }
    >
      <Button
        onClick={(e) => handleCopy(e, cell.getValue())}
        size="sm"
        {..._buttonProps}
        sx={{
          backgroundColor: 'transparent',
          border: 'none',
          color: 'inherit',
          cursor: 'copy',
          fontFamily: 'inherit',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          letterSpacing: 'inherit',
          pl: '6px',
          pr: '6px',
          ml: '-6px', // Needed due to button's padding
          minWidth: 'unset',
          textAlign: 'inherit',
          textTransform: 'inherit',
          ...(_buttonProps?.sx || {}),
        }}
      />
    </Tooltip>
  );
};
