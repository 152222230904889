/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ShowHideColumnsButton
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_ShowHideColumnsButton.tsx#L11)
 */

// 3rd
import { Menu, MenuButton } from '@chakra-ui/react';

// App - Types
import type { IconButtonProps } from '@/components/molecules/button';
import type { MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { Button } from '@/components/molecules/button';
import { ShowHideColumnsMenu } from '../menus/show-hide-columns-menu';

interface Props<TData extends MRT_RowData> extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  table: MRT_TableInstance<TData>;
}

export const ShowHideColumnsButton = <TData extends MRT_RowData>({
  table,
  ...iconButtonProps
}: Props<TData>) => {
  const {
    options: {
      icons: { ViewColumnIcon },
      localization,
    },
  } = table;

  // TODO this component might be redundant as it only passes props down -- check this later.
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        variant="old.ghost"
        size="sm"
        leftIcon={<ViewColumnIcon size="xs" aria-label={localization.showHideColumns} />}
        aria-label={localization.showHideColumns}
        {...iconButtonProps}
      >
        Columns
      </MenuButton>

      <ShowHideColumnsMenu table={table} />
    </Menu>
  );
};
