/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TablePaper
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/table/MRT_TablePaper.tsx#L13)
 */

// 3rd
import { Box, type BoxProps } from '@chakra-ui/react';

// App
import { TableContainer } from './table-container';
import { parseFromValuesOrFunc } from '../column.utils';
import { BottomToolbar } from '../toolbar/bottom-toolbar/bottom-toolbar';
import { TopToolbar } from '../toolbar/top-toolbar/top-toolbar';
import { type MRT_RowData, type MRT_TableInstance } from '../types';

interface Props<TData extends MRT_RowData> extends BoxProps {
  table: MRT_TableInstance<TData>;
}

export const TableBox = <TData extends MRT_RowData>({ table, ...rest }: Props<TData>) => {
  const {
    options: {
      enableBottomToolbar,
      enableTopToolbar,
      tableBoxProps,
      renderBottomToolbar,
      renderTopToolbar,
    },
    refs: { tableBoxRef },
  } = table;
  const boxProps = {
    ...parseFromValuesOrFunc(tableBoxProps, { table }),
    ...rest,
  } as BoxProps;

  return (
    <Box
      {...boxProps}
      ref={(ref: HTMLDivElement) => {
        tableBoxRef.current = ref;

        // if (boxProps?.ref) {
        //   boxProps.ref.current = ref;
        // }
      }}
      style={{
        ...boxProps?.style,
      }}
      sx={{
        // backgroundColor: getTableTheme(table, colorModeContext).baseBackgroundColor,
        backgroundImage: 'unset',
        // overflow: 'hidden',
        transition: 'all 100ms ease-in-out',
        ...(boxProps?.sx || {}),
      }}
    >
      {enableTopToolbar &&
        (parseFromValuesOrFunc(renderTopToolbar, { table }) ?? <TopToolbar table={table} />)}

      <TableContainer table={table} />

      {enableBottomToolbar &&
        (parseFromValuesOrFunc(renderBottomToolbar, { table }) ?? <BottomToolbar table={table} />)}
    </Box>
  );
};
