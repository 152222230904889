/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_Table
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/table/MRT_Table.tsx#L22)
 */

// React & Next
import { useCallback, useMemo } from 'react';

// 3rd
import { type Range, type Virtualizer, useVirtualizer } from '@tanstack/react-virtual';
import { Table as ChakraTable, type TableProps } from '@chakra-ui/react';

// App
import { TableBody, MemoTableBody } from '../body/table-body';
import { TableFooter } from '../footer/table-footer';
import { TableHead } from '../head/table-head';
import { type MRT_RowData, type MRT_TableInstance } from '../types';
import { extraIndexRangeExtractor, parseFromValuesOrFunc } from '../column.utils';
import { parseCSSVarId } from '../style.utils';

interface Props<TData extends MRT_RowData> extends TableProps {
  table: MRT_TableInstance<TData>;
}

export const Table = <TData extends MRT_RowData>({ table, ...tableProps }: Props<TData>) => {
  const {
    getFlatHeaders,
    getState,
    options: {
      columnVirtualizerInstanceRef,
      columnVirtualizerOptions,
      columns,
      enableColumnPinning,
      enableColumnVirtualization,
      // enableStickyHeader,
      enableTableFooter,
      enableTableHead,
      layoutMode,
      memoMode,
      tableProps: tableTableProps,
    },
    refs: { tableContainerRef },
  } = table;
  const { columnPinning, columnSizing, columnSizingInfo, columnVisibility, draggingColumn } =
    getState();

  const _tableProps = {
    ...parseFromValuesOrFunc(tableTableProps, { table }),
    ...tableProps,
  };

  const columnVirtualizerProps = parseFromValuesOrFunc(columnVirtualizerOptions, { table });

  const columnSizeVars = useMemo(() => {
    const headers = getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      let colSize = header.getSize();
      if (header.subHeaders?.length) colSize = colSize * 1.05 + header.subHeaders.length * 2;
      colSizes[`--header-${parseCSSVarId(header.id)}-size`] = colSize;
      colSizes[`--col-${parseCSSVarId(header.column.id)}-size`] = colSize;
    }
    return colSizes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, columnSizing, columnSizingInfo, columnVisibility]);

  // get first 16 column widths and average them
  const averageColumnWidth = useMemo(() => {
    if (!enableColumnVirtualization) return 0;
    const columnsWidths =
      table
        .getRowModel()
        .rows[0]?.getCenterVisibleCells()
        ?.slice(0, 16)
        ?.map((cell) => cell.column.getSize() * 1.2) ?? [];
    return columnsWidths.reduce((a, b) => a + b, 0) / columnsWidths.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getRowModel().rows, columnPinning, columnVisibility]);

  const [leftPinnedIndexes, rightPinnedIndexes] = useMemo(
    () =>
      enableColumnVirtualization && enableColumnPinning
        ? [
            table.getLeftLeafColumns().map((c) => c.getPinnedIndex()),
            table
              .getRightLeafColumns()
              .map((c) => table.getVisibleLeafColumns().length - c.getPinnedIndex() - 1),
          ]
        : [[], []],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnPinning, enableColumnVirtualization, enableColumnPinning]
  );

  const draggingColumnIndex = table
    .getVisibleLeafColumns()
    .findIndex((c) => c.id === draggingColumn?.id);

  const columnVirtualizer: Virtualizer<HTMLDivElement, HTMLTableCellElement> | undefined =
    enableColumnVirtualization
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useVirtualizer({
          count: table.getVisibleLeafColumns().length,
          estimateSize: () => averageColumnWidth,
          getScrollElement: () => tableContainerRef.current,
          horizontal: true,
          overscan: 3,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          rangeExtractor: useCallback(
            (range: Range) => {
              const newIndexs = extraIndexRangeExtractor(range, draggingColumnIndex);
              return [
                ...Array.from(new Set([...leftPinnedIndexes, ...newIndexs, ...rightPinnedIndexes])),
              ];
            },
            [leftPinnedIndexes, rightPinnedIndexes, draggingColumnIndex]
          ),
          ...columnVirtualizerProps,
        })
      : undefined;

  if (columnVirtualizerInstanceRef && columnVirtualizer) {
    columnVirtualizerInstanceRef.current = columnVirtualizer;
  }

  const virtualColumns = columnVirtualizer ? columnVirtualizer.getVirtualItems() : undefined;

  let virtualPaddingLeft: number | undefined;
  let virtualPaddingRight: number | undefined;

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[leftPinnedIndexes.length]?.start ?? 0;
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1 - rightPinnedIndexes.length]?.end ?? 0);
  }

  const props = {
    table,
    virtualColumns,
    virtualPaddingLeft,
    virtualPaddingRight,
  };

  return (
    <ChakraTable
      // stickyHeader={enableStickyHeader}
      {..._tableProps}
      style={{ ...columnSizeVars, ..._tableProps?.style }}
      variant="unstyled"
      sx={{
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
        display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
        ...(_tableProps?.sx || {}),
      }}
    >
      {enableTableHead && <TableHead {...props} />}

      {memoMode === 'table-body' || columnSizingInfo.isResizingColumn ? (
        <MemoTableBody columnVirtualizer={columnVirtualizer} {...props} />
      ) : (
        <TableBody columnVirtualizer={columnVirtualizer} {...props} />
      )}

      {enableTableFooter && <TableFooter {...props} />}
    </ChakraTable>
  );
};
