/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_ColumnPinningButtons
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/buttons/MRT_ColumnPinningButtons.tsx#L16)
 */

// 3rd
import { Box } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

// App - Types
import type { MRT_Column, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { ButtonGroup, IconButton } from '@/components/molecules/button';

interface Props<TData extends MRT_RowData> extends BoxProps {
  column: MRT_Column<TData>;
  table: MRT_TableInstance<TData>;
}

export const ColumnPinningButtons = <TData extends MRT_RowData>({
  column,
  table,
  ...boxProps
}: Props<TData>) => {
  const {
    options: {
      icons: { PushPinIcon },
      localization,
    },
  } = table;

  const handlePinColumn = (pinDirection: 'left' | 'right' | false) => {
    column.pin(pinDirection);
  };

  return (
    <Box
      {...boxProps}
      sx={{
        minWidth: '70px',
        textAlign: 'center',
        ...(boxProps?.sx || {}),
      }}
    >
      {column.getIsPinned() ? (
        <IconButton
          aria-label={localization.unpin}
          onClick={() => handlePinColumn(false)}
          size="sm"
          variant="old.danger"
          icon={<PushPinIcon aria-label={localization.unpin} />}
        />
      ) : (
        <ButtonGroup size="xs" variant="outline">
          <IconButton
            aria-label={localization.pinToLeft}
            onClick={() => handlePinColumn('left')}
            size="sm"
            variant="old.outline"
            icon={
              <PushPinIcon
                aria-label={localization.pinToLeft}
                style={{
                  transform: 'rotate(90deg)',
                }}
              />
            }
          />

          <IconButton
            aria-label={localization.pinToRight}
            onClick={() => handlePinColumn('right')}
            size="sm"
            variant="old.outline"
            icon={
              <PushPinIcon
                aria-label={localization.pinToRight}
                style={{
                  transform: 'rotate(-90deg)',
                }}
              />
            }
          />
        </ButtonGroup>
      )}
    </Box>
  );
};
