/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_FilterCheckbox
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/inputs/MRT_FilterCheckbox.tsx#L16)
 */

// App - Types
import type { CheckboxProps } from '@/components/molecules/form';
import type { MRT_Column, MRT_RowData, MRT_TableInstance } from '../types';

// App - Other
import { Checkbox, Field } from '@/components/molecules/form';
import { parseFromValuesOrFunc } from '../column.utils';

interface Props<TData extends MRT_RowData> extends CheckboxProps {
  column: MRT_Column<TData>;
  table: MRT_TableInstance<TData>;
}

export const FilterCheckbox = <TData extends MRT_RowData>({
  column,
  table,
  ...checkboxProps
}: Props<TData>) => {
  const {
    options: { localization, filterCheckboxProps },
  } = table;
  const { columnDef } = column;

  const _checkboxProps = {
    ...parseFromValuesOrFunc(filterCheckboxProps, {
      column,
      table,
    }),
    ...parseFromValuesOrFunc(columnDef.filterCheckboxProps, {
      column,
      table,
    }),
    ...checkboxProps,
  };

  const filterLabel = localization.filterByColumn?.replace('{column}', columnDef.header);

  return (
    <Field label="" helpText={_checkboxProps.title ?? filterLabel} htmlFor={filterLabel} py="4px">
      <Checkbox
        id={filterLabel}
        variant="old"
        isChecked={column.getFilterValue() === 'true'}
        // color={column.getFilterValue() === undefined ? 'default' : 'primary'}
        isIndeterminate={column.getFilterValue() === undefined}
        size="md"
        {..._checkboxProps}
        onChange={(e) => {
          column.setFilterValue(
            column.getFilterValue() === undefined
              ? 'true'
              : column.getFilterValue() === 'true'
                ? 'false'
                : undefined
          );
          _checkboxProps?.onChange?.(e);
        }}
        onClick={(e) => {
          e.stopPropagation();
          _checkboxProps?.onClick?.(e);
        }}
        sx={{
          /*height: '40px',
          width: '40px',*/
          ...(_checkboxProps?.sx || {}),
        }}
      />
    </Field>
  );
};
