/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_LinearProgressBar
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/toolbar/MRT_LinearProgressBar.tsx#L13)
 */

// 3rd
import { Box, Collapse, Progress, type ProgressProps } from '@chakra-ui/react';

// App
import { parseFromValuesOrFunc } from '../../column.utils';
import { type MRT_RowData, type MRT_TableInstance } from '../../types';

interface Props<TData extends MRT_RowData> extends ProgressProps {
  isTopToolbar: boolean;
  table: MRT_TableInstance<TData>;
}

export const LinearProgressBar = <TData extends MRT_RowData>({
  isTopToolbar,
  table,
  ...rest
}: Props<TData>) => {
  const {
    getState,
    options: { linearProgressProps },
  } = table;
  const { isSaving, showProgressBars } = getState();

  const _linearProgressProps = {
    ...parseFromValuesOrFunc(linearProgressProps, {
      isTopToolbar,
      table,
    }),
    ...rest,
  };

  return (
    <Collapse in={showProgressBars !== false && (showProgressBars || isSaving)} unmountOnExit>
      {/* INSIGHT THIS BOX BELOW DIDN'T EXISTS IN ORIGINAL CODE */}
      <Box
        sx={{
          bottom: isTopToolbar ? 0 : undefined,
          position: 'absolute',
          top: !isTopToolbar ? 0 : undefined,
          width: '100%',
        }}
      >
        <Progress
          aria-busy="true"
          aria-label="Loading"
          size="xs"
          isIndeterminate
          sx={{ position: 'relative' }}
          {..._linearProgressProps}
        />
      </Box>
    </Collapse>
  );
};
